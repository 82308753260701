import { nanoid } from "@reduxjs/toolkit";
import { globalConstants, myOpportunitiesConstants } from "../constants/globalConstant";
import { addLabWidget } from "../reducers/labsSlice";
import { resetWidgetConfiguration } from "../reducers/widgetTemplateListSlice";

export const getWidgetSignature = (homepageId, widgetTemplateId, config) => {
  return {
    homepageId,
    widgetTemplateId,
    config,
    isActive: true,
  };
};

export const formatTaskTypes = (taskTypes) => {
  let taskTypeNames = {
    appointments: [],
    todos: [],
  };

  taskTypes?.forEach((taskType) => {
    if (taskType.Category === 0 || taskType.category === 0) {
      taskTypeNames.appointments.push(...taskType.subTask);
    }
    if (taskType.Category === 1 || taskType.category === 1) {
      taskTypeNames.todos.push(...taskType.subTask);
    }
  });
  return taskTypeNames;
};

export const generateTileBannerWidgetMetaValue = (config , widgetId) => {
  const widgetMetaValue = {
    widget: {
      widgetId: widgetId,
      config: {
        items: config.items,
      },
    },
  };
  return widgetMetaValue;
};

export const getWidgetTitle = (widgetTemplateId) => {
  switch(widgetTemplateId){
    case 1:
      return "Task Counter"
    case 2:
      return "Task List"
    case 3:
      return "Web View"
    case 4:
      return "Launcher"
    case 5:
      return "Banner"
    case 6:
      return "Lead Shortcuts"
    case 7:
      return "Lead List"
    case 8:
      return "My Opportunities"
    case 9: 
      return "Smart Views Counter"
    case 10:
      return "My Scorecard"
    case 12:
      return "Distance Travelled"
    case 13:
      return "Task Counter 2.0"
    case 14:
       return "Charting"
    case 15:
      return "Lead Metrics"
    case 17:
      return "Near Me"
    case 18:
      return "Task Counter Fuelbar"
    case 20:
      return "Tiles Banner"
    case 21:
      return "Siera Webview"
    case 16:
      return "Custom Fab"
    case 22:
      return "User Check-in Status"
    case 23 : 
      return "Image Gallery"
    default:
      return  null
  }
}

export const getWidgetTypeByWidgetId = (widgetId) => {
  switch (widgetId) {
    case 1:
      return "taskcounter";
    case 2:
      return "tasklist";
    case 3:
      return "webview";
    case 4:
      return "quicklauncher";
    case 5:
      return "banner";
    case 6:
      return "leadshortcut";
    case 7:
      return "leadlist";
    case 8:
      return "myopportunities";
    case 9:
      return "smartviewscounter";
    case 10:
      return "myscorecard";
    case 12:
      return "distancetravelled";
    case 13:
      return "taskcounterv2"
    case 14 : 
      return "leadmetrics"
    case 15: 
      return "keyleadmetrics"
    case 16:
      return "customfab"
    case 17:
      return "nearme"
    case 18:
      return "taskcounterfuelbar"
    case 20:
      return "tilesbanner"
    case 21:
      return "sieraace"
    case 22:
      return "usercheckinstatus"
    case 23:
      return "imagegallery"
    default:
      return widgetId;
  }

}

export const getWidgetIdByWidgetType = (widgetType) => {
  switch (widgetType) {
    case "taskcounter":
      return 1;
    case "tasklist":
      return 2;
    case "webview":
      return 3;
    case "quicklauncher":
      return 4;
    case "banner":
      return 5;
    case "leadshortcut":
      return 6;
    case "leadlist":
      return 7;
    case "myopportunities":
      return 8;
    case "smartviewscounter":
      return 9;
    case "myscorecard":
      return 10;
    case "leadmetrics" : 
      return 14;
    case "keyleadmetrics": 
      return 15;
    case "nearme":
      return 17;
    case "tilesbanner":
      return 20;
    case "sieraace":
      return 21;
    case "customfab":
      return 16;
    case "usercheckinstatus":
      return 22;
    case "imagegallery":
      return 23;
    default:
      return widgetType;
  }

}

export const getItemIndex = (itemName, containerArr) => {
  for (let index = 0; index < containerArr.length; index++) {
    if (containerArr[index].name === itemName) return index;
  }
};

export const getUpdatedName = (optionList, selectedOption) => {
  return optionList.find((obj) => {
    return obj.id == selectedOption.id;
  });
};

export const findNumberOfConfiguredWidgets = (sections) =>{
  let count = 0;
  sections?.forEach(((section) => {
    section.rows?.forEach((row)=>{
      count += row.widgets.length;
    })
  }))
  return count;
}

export const getObjectDefinition = (title, value, colSpan) => {
  return {
    title: title,
    value: value,
    colSpan: colSpan,
  };
}

export const taskToDisplay = (type) => {
  if (type?.category.length > 0) {
    switch (type.category[0]) {
      case "-1":
        return "All Tasks";
      case "0":
        return "All Appointments";
      case "1":
        return "All Todos";
    }
  } else {
    let selectedTask = type?.appointmentId.length + type?.todoId.length;
    return `${selectedTask} Selected`;
  }
}

export const getLauncherItems = (items) => {
  let itemList = [];
  items?.length > 0 &&
    items.forEach((item, index) => {
      itemList.push(
        getObjectDefinition(
          `Icon ${index + 1} navigates to`,
          item.navigateTo.name,
          2
        )
      );
    });
  return itemList;
};

export const getBannerItems = (items) => {
  let itemList = [];
  items?.length > 0 &&
    items.forEach((item, index) => {
      itemList.push(
        getObjectDefinition(
          `Image ${index + 1} navigates to`,
          item.navigateTo.name || "N/A",
          2
        )
      );
    });
  return itemList;
};
export const getOptionsExistInTheList = (options , optionList) => {
  return optionList?.filter(option => {
    return options?.some(_option => option.id === _option.id)
  });
}

export const getImageNameFromImageUrl = (imgUrl) => {
  return imgUrl.substring(imgUrl.lastIndexOf("/") + 1, imgUrl.lastIndexOf("."));
};

export const convertDatetoString = (date) =>{
  if (date){
    let _date = new Date(date)
    _date.setHours(23,59,59)
    return String(_date);
  }
}

export const getExpiryDate = (date) => {
  if (date) {
    const expiryDate = new Date(date).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
    return new Date().getTime() > new Date(date).getTime()
      ? ` (Expired ${expiryDate})`
      : ` (Expires ${expiryDate})`;
  }
};

export const widgetTabs = [
  {
    value: globalConstants.WIDGET_LIBRARY,
    key: globalConstants.ACTIVE_WIDGETS,
    id : "coach-widget-tab",
    "data-testid": "active-widget-tab"
  },
  {
    value: globalConstants.LABS,
    key: globalConstants.EXPIREMENTAL_WIDGETS,
    id : "coach-labs-tab-",
    "data-testid": "experimental-widget-tab"
  },
];

export const hasConfigurationScreen = (widgetIdentifier) => {
  const widgetsWithConfigurationScreen = [
    5,
    "banner",
    15,
    "keyleadmetrics",
    14,
    "leadmetrics",
    7,
    "leadlist",
    6,
    "leadshortcut",
    8,
    "myopportunities",
    10,
    "myscorecard",
    17,
    "nearme",
    4,
    "quicklauncher",
    9,
    "smartviewscounter",
    1,
    "taskcounter",
    2,
    "tasklist",
    13,
    "taskcounterv2",
    3,
    "webview",
    18,
    "taskcounterfuelbar",
    21,
    "sieraace",
    19,
    "popupbanner",
    20,
    "tilesbanner",
    16,
    "customfab",
    22,
    "usercheckinstatus",
    23,
    "imagegallery",
  ];
  return widgetsWithConfigurationScreen.includes(widgetIdentifier);
};

export const getWidgetPreviewOfExperimentalWidget = (imageUrl, dispatch) => {
  const widgetId = nanoid();
  const previewWidgetConfig = {
    id: `row-${nanoid()}`,
    widgets: [
      {
        type: "full",
        widgetId: widgetId,
        widgetType: "experimentalWidgets",
        imgUrl: imageUrl,
      },
    ],
  };
  dispatch(addLabWidget(previewWidgetConfig));
  dispatch(resetWidgetConfiguration());
};

export const opportunityStatus = [
  {id : myOpportunitiesConstants.OPEN, name : myOpportunitiesConstants.OPEN},
  {id : myOpportunitiesConstants.WON, name : myOpportunitiesConstants.WON}, 
  {id : myOpportunitiesConstants.LOST, name : myOpportunitiesConstants.LOST},
];
