export const validateThreshold = (widgetForm, formData) => {
  if (!formData.setThresholds) return true;
  if (formData.min > formData.max) {
    widgetForm.setError("min", {
      type: "manual",
      message: "Min value cannot be greater than Max value.",
    });
    widgetForm.setError("max", {
      type: "manual",
      message: "Max value cannot be less than Min value.",
    });
    return;
  }
  if (formData.min < 0) {
    widgetForm.setError("min", {
      type: "manual",
      message: "Min value must be a positive number.",
    });
    return;
  }
  return true
};

export const countEntities = (usedWidgetsList, id) => {
  return usedWidgetsList.reduce((count, item) => {
    if (item.config?.entity?.id === id) {
      return count + 1;
    }
    return count;
  }, 0);
}


export const getEntityCountMap = (usedWidgetsList, casaWebLimits) => {
  const entitiesLimit = casaWebLimits?.entitiesLimit;
  const result = {};

  for (const entityType in entitiesLimit) {
    if (Object.prototype.hasOwnProperty.call(entitiesLimit, entityType)) {
      const count = countEntities(usedWidgetsList, entityType);
        result[entityType] = count;
    }
  }

  return result;
}