import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";
import { InputSwitch } from "primereact/inputswitch";
import { Controller } from "react-hook-form";
import "./index.css";
import Text from "../Text";

export default function LsqSwitch({
  control,
  switchName,
  label,
  disabled,
  textColor,
  style,
  ...props
}) {
  return (
    <div
      className={styles.wrapper}
      data-testid="lsq-switch"
      style={{ ...style }}
    >
      <Text type={"T4"} color={textColor}>
        {label}
      </Text>
      <Controller
        name={switchName}
        control={control}
        render={({ field }) => {
          return (
            <InputSwitch
              className="lsq-switch"
              checked={field.value === true}
              disabled={disabled}
              onChange={(e) => {
                field.onChange(e.value)
              }}
              {...props}
            />
          );
        }}
      />
    </div>
  );
}

LsqSwitch.propTypes = {
  control: PropTypes.object,
  label: PropTypes.string,
  switchName: PropTypes.string,
};
