import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Text from "../Text";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import styles from "./styles.module.css";

export const LsqTextEditor = ({
  displayText,
  onSaveHandler,
  editIconStyle,
  textClass = "",
  onClick,
  width
}) => {
  const [text, setText] = useState(displayText);
  const [editMode, setEditMode] = useState({ state: false, value: text });

  const handleEdit = (e) => {
    setEditMode({ state: true, value: text });
    e.stopPropagation();
  };

  const handleSave = (event) => {
    if (event.target.value) {
      setText(event.target.value);
      setEditMode({ state: false, value: text });
      onSaveHandler(event.target.value);
    } else {
      setText(editMode.value);
      setEditMode({ state: false, value: text });
    }
    event.stopPropagation();
  };

  useEffect(() => {
    setText(displayText);
  }, [displayText]);

  return (
    <div onClick={onClick}>
      {editMode.state ? (
        <input
          type="text"
          maxLength={30}
          value={text}
          onBlur={handleSave}
          onChange={(e) => setText(e.target.value)}
          className={styles["text-edit"]}
          autoFocus
          data-testid="input-box"
        />
      ) : (
        <div
          className="flex gap-1 cursor-pointer align-items-center overflow-ellipsis"
          onClick={handleEdit}
          data-testid="lsq-text-box"
        >
          <Text color="#314361" type="T1B" className={`${width||'w-6rem'} overflow-ellipsis ${textClass}`} title={text}>
            {text}
          </Text>
          <Edit
            className="lsq-edit"
            data-testid="edit-icon"
            style={{ ...editIconStyle }}
          />
        </div>
      )}
    </div>
  );
};

LsqTextEditor.propTypes = {
  displayText: PropTypes.string.isRequired,
  onSaveHandler: PropTypes.func.isRequired,
  editIconStyle: PropTypes.object,
  textClass: PropTypes.string,
};

LsqTextEditor.defaultProps = {
  editIconStyle: {},
  textClass: "",
};
