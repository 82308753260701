import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as DragIndicator } from "../../assets/svgIcons/drag_indicator.svg";
import { ReactComponent as LsqEdit } from "../../assets/svgIcons/banner_edit.svg";
import { ReactComponent as LsqClose } from "../../assets/svgIcons/banner_close.svg";
import { ReactComponent as LsqRetry } from "../../assets/svgIcons/banner_retry.svg";
import styles from "./style.module.css";
import PropTypes from "prop-types";
import Text from "../Text";
import { bannerSettingsConstants } from "../../constants/globalConstant";
import LsqProgressSpinner from "../LsqProgressSpinner";
import { getExpiryDate } from "../../utils/widgetUtils";
import { getClassName } from "../../utils/stringUtils";

export default function BannerElementDragHandler({
  elementList = [],
  dragAndDrop = true,
  onElementEdit,
  onElementDeleted,
  move,
  updateBannerPreviewPane,
  aspectedRatio,
  uploadImageInToBucket,
  setEdit,
  showInFullView = true,
}) {
  

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    move(result.source.index, result.destination.index);
    updateBannerPreviewPane();
  };

  const getCardLabel = (uploadStatus,imgName, endDate) => {
    let cardLabel = ""
    switch(uploadStatus){
      case bannerSettingsConstants.FAILED : {
        cardLabel = bannerSettingsConstants.UPLOAD_FAILED
        break;
      }
      case bannerSettingsConstants.PENDING : {
        cardLabel = bannerSettingsConstants.UPLOADING
        break;
      }
      case bannerSettingsConstants.SUCCESS: {
        cardLabel = (imgName || "") + (getExpiryDate(endDate) || "")
      }
    }
    return cardLabel
  };

  return (
    <div className="w-12" 
     data-testid = "banner-element-dragger"
    >
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Droppable
          direction="vertical"
          type="elements"
          droppableId="element-handler"
        >
          {(provided) => {
            return (
              <div
                data-testid="elementDragHandlerBox"
                className="flex flex-column gap-1"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {elementList.map((element, index) => {
                  return (
                    <Draggable
                      isDragDisabled={!dragAndDrop}
                      index={index}
                      key={element.id}
                      draggableId={element.id}
                    >
                      {(provided) => {
                        return (
                          <div
                            className="flex"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className={getClassName(
                                "flex flex-row justify-content-center align-items-center",
                                styles["container"],
                                showInFullView && "w-full"
                              )}
                            >
                              {dragAndDrop && (
                                <div
                                  className={`flex flex-column justify-content-center align-content-center ${styles["draghandle-position"]}`}
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicator />
                                </div>
                              )}
                              <div
                                className={`relative ${styles["img-container"]} ${styles[aspectedRatio]}`}
                              >
                                <div
                                  className={`flex flex-row justify-content-between align-items-center w-full absolute ${styles["edit-container"]}`}
                                >
                                  <div
                                    className={`flex flex-row justify-content-start gap-2 ${styles["banner-img-name"]}`}
                                  >
                                    {element.uploadStatus ===
                                      bannerSettingsConstants.PENDING && (
                                      <LsqProgressSpinner
                                        strokeWidth={6}
                                        strokeColor="#43536F"
                                      />
                                    )}
                                    <Text
                                      type="heading"
                                      color="#0A1F43"
                                      className={`overflow-wrap-anywhere`}
                                    >
                                      {getCardLabel(
                                        element.uploadStatus,
                                        element.imageName,
                                        element.endDate
                                      )}
                                    </Text>
                                  </div>
                                  <div
                                    className={`flex flex-row justify-content-center align-items-center gap-1`}
                                  >
                                    {element.uploadStatus ===
                                      bannerSettingsConstants.SUCCESS && (
                                      <LsqEdit
                                        data-testid="banner-edit-icon"
                                        style={{
                                          cursor: "pointer",
                                          width: "1.3rem",
                                        }}
                                        onClick={() => {
                                          onElementEdit(index);
                                          setEdit(true);
                                        }}
                                      />
                                    )}
                                    {element.uploadStatus ===
                                      bannerSettingsConstants.FAILED && (
                                      <LsqRetry
                                        style={{
                                          cursor: "pointer",
                                          width: "1.3rem",
                                        }}
                                        onClick={() =>
                                          uploadImageInToBucket(element, index)
                                        }
                                      />
                                    )}
                                    {element.uploadStatus !==
                                      bannerSettingsConstants.PENDING && (
                                      <LsqClose
                                        data-testid="banner-delete-icon"
                                        style={{
                                          cursor: "pointer",
                                          width: "1.3rem",
                                        }}
                                        onClick={() => onElementDeleted(index)}
                                      />
                                    )}
                                  </div>
                                </div>
                                <img
                                  src={`${element.croppedImageURL}`}
                                  alt="img"
                                  onError={(e) =>
                                    (e.target.src = `${
                                      aspectedRatio ===
                                      bannerSettingsConstants.SQUARE
                                        ? bannerSettingsConstants.DEMO_SQUARE_IMAGE
                                        : bannerSettingsConstants.DEMO_WIDE_IMAGE
                                    }`)
                                  }
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "inherit",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

BannerElementDragHandler.propTypes = {
  elementList: PropTypes.array,
};
