import { Controller } from "react-hook-form";
import "./index.css"
import { Checkbox } from "primereact/checkbox";

export const CustomCheckbox = ({
  fieldName,
  control,
  label,
  id,
  ...props
}) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <span className={"custom-checkbox w-full flex align-items-center"}>
        <Checkbox
            id={id}
            inputId={fieldName}
            style={{
                height: "10px",
                width: "10px",
              }}
            {...field}
            {...props} />
        <label htmlFor={fieldName} className="label">{label}</label>
        </span>
      )}
    />
  );
};
