import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LsqSwitch from "../../components/LsqSwitch";
import PublishView from "../../components/PublishView";
import { ReactComponent as MenuBuilderFilled } from "../../assets/svgIcons/lsq_menu_builder_filled.svg";
import useToast from "../../hooks/useToast";
import { generateTeamAssignmentMenuBuilderPostData } from "../../utils/casaUtils";
import TeamAssignmentMultiselectTreeDropdown from "../../components/TeamAssignmentMultiselectTreeDropdown";
import { useForm } from "react-hook-form";
import { hierarchyToLinear } from "../../utils/globalUtils";
import {
  teamIdsToTreeList,
  teamListBuilderForMenuBuilder,
} from "../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import {
  globalConstants,
  menuBuilderConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import {
  apiAddMenu,
  addToastMessage,
  apiUpdateMenu,
  changeSelectedMenuId,
} from "../../reducers/menuBuilderSlice";
import CustomInput from "../../components/CustomInput";
import { apiGetAllTeamsWebLaunchBars, apiGetAllWebLaunchBars, publishWebLaunchBar } from "../../reducers/webLaunchbarSlice";

export default function PublishMenuBuilder({ publishState, setPublishState ,view}) {
  const toast = useToast(addToastMessage);
  const [published, setPublished] = useState(false);
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const { orgCode } = useSelector((state) => state.homepage.user);
  const dispatch = useDispatch();
  const { menus, publish } = useSelector((state) => state.menuBuilder);
  const { teamLaunchBars } = useSelector(
    (state) => state.webLaunchBar
  );
  const { isPublishLoading } = publish;
  const { t } = useTranslation();

  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);

  const teamIds = publishState.data?.teamIds;
  const menuId = publishState.data?.selectedMenuId;
  const isDefault = publishState.data?.isDefault;
  const sideNavigation = publishState.data?.sideNavigation;
  const bottomNavigation = publishState.data?.bottomNavigation;
  const menuName = publishState.data?.menuName;
  const cico = publishState.data?.cico;

  const defaultValues = {
    displayName: menuName,
    isDefault: false,
    teamAssigned: {},
  };
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    getValues,
    setValue,
  } = useForm({ defaultValues });

  const isDefaultEnabled = watch("isDefault");
  const watchTeamAssigned = watch("teamAssigned");

  const isPublishDisabled = () => {
    if (isPublishLoading) return true;
   return (isDefaultEnabled === false &&
      Object.keys(watchTeamAssigned).length === 0)
  };

  const isPublishClicked = (data) => {
    const currentTeamAssigned = {
      ...data,
    };
    const existingTeamAssigned = {
      isDefault: isDefault,
      teamAssigned: teamIds,
    };
    if (isDefaultEnabled) {
      currentTeamAssigned.teamAssigned = {};
    }
    const menuUpdatedName = getValues("displayName");
    if (!menuId) {
      dispatch(
        apiAddMenu({
          postBody: {
            sideNavigation: sideNavigation,
            bottomNavigation: bottomNavigation,
            addTeams: isDefaultEnabled
              ? [orgCode]
              : Object.keys(currentTeamAssigned.teamAssigned),
            name: menuUpdatedName,
            config: {
              cico: cico,
            },
          },
        })
      ).then(({ type, payload }) => {
        if (type.includes(globalConstants.REJECTED)) {
          const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
          toast.error(message);
        } else {
          dispatch(changeSelectedMenuId(payload.menu.menuId));
          setPublished(true);
          setTimeout(() => {
            setPublishState({ state: false, data: null });
          }, 2000);
        }
      });
    } else {
      const teamAssigmentPostData = generateTeamAssignmentMenuBuilderPostData(
        orgCode,
        existingTeamAssigned,
        currentTeamAssigned,
        sideNavigation,
        bottomNavigation,
        menuUpdatedName,
        cico
      );
      dispatch(
        (view ? publishWebLaunchBar : apiUpdateMenu)({
          menuId: menuId,
          postBody: teamAssigmentPostData,
        })
      ).then(({ type, payload }) => {
        if (type.includes(globalConstants.REJECTED)) {
          const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
          toast.error(message);
        } else {
          view && dispatch(apiGetAllWebLaunchBars());
          view && dispatch(apiGetAllTeamsWebLaunchBars());
          setPublished(true);
          setTimeout(() => {
            setPublishState({ state: false, data: null });
          }, 2000);
        }
      });
    }
  };

  const onDismissPublishView = () => {
    setPublishState({ state: false, data: null });
  };

  useEffect(() => {
    if (menuId) {
      setValue("teamAssigned", teamIdsToTreeList(teamIds, teamLinear));
      setValue("isDefault", isDefault);
    }
  }, [menuId]);

  const teamTreeList = teamListBuilderForMenuBuilder(
    teamHierarchy,
    view ? teamLaunchBars : menus.teamMenu,
    menuId,
    view
  );
  const isDefaultSwitchDisabled =view? teamLaunchBars[orgCode]:
    menus?.defaultMenu?.menuId && menus?.defaultMenu?.menuId != menuId;

  return (
    <PublishView
      published={published}
      publishViewIcon={<MenuBuilderFilled />}
      publishViewTitle={t(menuBuilderConstants.PUBLISH_MENU)}
      publishSuccessBoxIcon={<MenuBuilderFilled />}
      publishSuccessBoxTitle={t("Menu Builder")}
      publishSuccessBoxMessage={t(
        menuBuilderConstants.MENU_PUBLISHED_SUCCESSFULLY
      )}
      onPublishClicked={handleSubmit(isPublishClicked)}
      isPublishDisabled={isPublishDisabled}
      onDismissPublishView={onDismissPublishView}
    >
      <div className="flex flex-column flex-start gap-4 w-full">
        <CustomInput
          fieldName="displayName"
          errors={errors}
          control={control}
          label={t(menuBuilderConstants.ENTER_MENU_TITLE)}
          placeholder="Type Here"
          maxLength={30}
          data-testid="display-name"
          isMandatory
        />
        <LsqSwitch
          control={control}
          label={t(menuBuilderConstants.SET_AS_DEFAULT_MENU)}
          switchName="isDefault"
          disabled={isDefaultSwitchDisabled}
        />
        {!isDefaultEnabled && (
          <TeamAssignmentMultiselectTreeDropdown
            control={control}
            errors={errors}
            fieldName="teamAssigned"
            teamHierarchy={teamHierarchy}
            teamTreeList={teamTreeList}
          />
        )}
      </div>
    </PublishView>
  );
}
