import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { TreeSelect } from "primereact/treeselect";
import { Chip } from "primereact/chip";
import { Controller } from "react-hook-form";
import Text from "../Text";
import "./index.css";
import styles from "./styles.module.css";
import { FormErrorMessage } from "../FormErrorMessage";
import {
  chipGenerator,
  onCheckboxSelected,
  onCheckboxUnselected,
  onChipRemoval,
  chipTemplate,
} from "./TeamAssignmentMultiselectTreeDropdownUtils";
import { treeValueTemplate } from "../../utils/dropdownUtils";
import { hierarchyToLinear } from "../../utils/globalUtils";
import CustomButton from "../CustomButton";
import { placeholderConstants } from "../../constants/globalConstant";

export default function TeamAssignmentMultiselectTreeDropdown({
  fieldName,
  control,
  isMandatory = false,
  errors,
  teamHierarchy,
  teamTreeList,
  showSelections = true,
  filterBy='label'
}) {
  const linearTeamList = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        required: true,
        validate: (value) => {
          if (Object.keys(value).length === 0) {
            return "Please select a team";
          }
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const valueLength = Object.keys(field.value).length;
        return (
          <div className="flex w-full flex-column gap-2">
            <TreeSelect
              className="lsq-inputv2 tree-select-v2 w-full"
              valueTemplate={treeValueTemplate(
                valueLength,
                placeholderConstants.SELECT_TEAMS
              )}
              style={error && { borderColor: "#E02020" }}
              value={field.value}
              onNodeSelect={(e) =>
                field.onChange(onCheckboxSelected(e, field.value))
              }
              onNodeUnselect={(e) =>
                field.onChange(onCheckboxUnselected(e, field.value))
              }
              placeholder={placeholderConstants.SELECT_TEAMS}
              selectionMode="checkbox"
              options={teamTreeList.root}
              panelClassName="teamTreePanel"
              filter={true}
              filterPlaceholder={placeholderConstants.SEARCH_TEAMS}
              filterBy={filterBy}
            ></TreeSelect>
            {errors[fieldName] && isMandatory && (
              <FormErrorMessage message={errors[fieldName].message} />
            )}
            {valueLength > 0 && showSelections &&  (
              <>
                <div className="flex flex-row w-full justify-content-between align-items-center">
                  <Text type="T4B" className="w-6">Currently Assigned to ({valueLength})</Text>
                  <CustomButton
                    onClick={() => {
                      field.onChange({});
                    }}
                    style={{ height: "1.7rem" }}
                    label="Clear All"
                    varient="outline"
                  />
                </div>
                <div className={`flex flex-row mt-1 flex-wrap gap-1`}>
                  {chipGenerator(field.value, linearTeamList)?.map((obj) => {
                    return (
                      <Chip
                        onRemove={() =>
                          field.onChange(onChipRemoval(field.value, obj.teamId))
                        }
                        label={obj?.label}
                        className={styles["teams-chip"]}
                        key={obj?.teamId}
                        template={chipTemplate}
                        removable
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      }}
    />
  );
}

TeamAssignmentMultiselectTreeDropdown.propTypes = {
  fieldName: PropTypes.string,
  isMandatory: PropTypes.bool,
  control: PropTypes.object,
  taskTypes: PropTypes.object,
};
