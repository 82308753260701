import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  globalConstants,
  leadControlsConstants,
  ROUTES_PATH,
  toastMessageConstants,
} from "../../constants/globalConstant.js";
import styles from "./styles.module.css";
import { leadControlSideMenuOptions } from "../../utils/leadControlsUtils.js";
import LeadCard from "./LeadCard/index.js";
import { LeadTitleEditor } from "./LeadTitleEditor.js";
import Text from "../../components/Text/index.js";
import CustomButton from "../../components/CustomButton/index.js";
import { ReactComponent as PublishIcon } from "../../assets/svgIcons/publish.svg";
import { ReactComponent as Hamburger } from "../../assets/svgIcons/Menu.svg";
import { ReactComponent as Back } from "../../assets/svgIcons/arrow.svg";
import { ReactComponent as SavedIcon } from "../../assets/svgIcons/lsq_ok.svg";
import LeadActions from "./LeadActions/index.js";
import ToastMessage from "../../components/ToastMessage";
import {
  getTeams,
  setShowSidePanel,
  setSidePanelExpanded,
} from "../../reducers/homePageSlice.js";
import LeadPage from "./LeadPage/index.js";
import LsqSwitchV2 from "../../components/LsqSwitchV2/index.js";
import MobilePreview from "./MobilePreview/index.js";
import LeadTypeSelector from "./LeadTypeSelector.js";
import {
  addToastMessageFromLeadControls,
  getLeadTypes,
  saveLeadActions,
  saveLeadCard,
  saveLeadPage,
  updateLeadControls,
} from "../../reducers/leadControlsSlice.js";
import Modal from "../../components/Modal/index.js";
import PublishLeadControl from "./PublishLeadControl.js";
import useToast from "../../hooks/useToast.js";
import {
  hierarchyToLinear,
  teamsToLeadControlMapping,
} from "../../utils/globalUtils.js";
import LsqProgressSpinner from "../../components/LsqProgressSpinner/index.js";
import SidePanel from "../../components/SidePanel/index.js";
import TeamsAssignedLeadControls from "./TeamAssignmentLeadControls.js";
import {
  getAllLeadControls,
  getAllTeamLeadControls,
} from "../../reducers/leadControlsAssignmentSlice.js";
import { useNavigate } from "react-router-dom";

export default function LeadControls() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidePanelState, setSidePanelState] = useState({
    state: false,
    data: null,
  });
  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });
  const toast = useToast(addToastMessageFromLeadControls);
  const [showLeadTypeError, setShowLeadTypeError] = useState(false);
  const [leadControlName, setLeadControlName] = useState(
    leadControlsConstants.DEFAULT_LEAD_CONTROLS_NAME
  );
  const [currentTab, setCurrentTab] = useState(leadControlsConstants.CARD);
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const { leadControls } = useSelector((state) => state.leadControlsAssignment);
  const {
    toastMessages,
    selectedLeadControl,
    isLeadConfigurationDirty,
    isLeadConfigSaveLoading,
    leadTypes,
    publish,
    selectedLeadType,
  } = useSelector((state) => state.leadControls);
  const [showLeadType, setShowLeadType] = useState(!selectedLeadType);
  const selectedLeadControlId = selectedLeadControl?.id;
  const defaultLeadControl = leadControls?.defaultLeadControls;
  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);
  const teamsToLeadControlCountMapping = useMemo(() => {
    return teamsToLeadControlMapping(
      leadControls?.teamLeadControls,
      teamLinear
    );
  }, [leadControls, teamLinear]);

  const onTabChange = (label) => {
    setCurrentTab(label);
  };

  useEffect(() => {
    selectedLeadControl && setLeadControlName(selectedLeadControl.name);
  }, [selectedLeadControl]);

  const getSaveButtonLabel = () => {
    if (isLeadConfigSaveLoading) return "Saving..";
    if (isLeadConfigurationDirty) return "Save";
    return "Saved";
  };

  const getSettingCards = () => {
    switch (currentTab) {
      case leadControlsConstants.CARD:
        return <LeadCard></LeadCard>;
      case leadControlsConstants.PAGE:
        return <LeadPage></LeadPage>;
      case leadControlsConstants.ACTIONS:
        return <LeadActions />;
      default:
        return <LeadCard></LeadCard>;
    }
  };

  const onSaveClicked = () => {
    switch (currentTab) {
      case leadControlsConstants.CARD:
        return dispatch(saveLeadCard());
      case leadControlsConstants.PAGE:
        return dispatch(saveLeadPage());
      case leadControlsConstants.ACTIONS:
        return dispatch(saveLeadActions());
      default:
        return;
    }
  };

  useEffect(() => {
    if (leadTypes.length === 0) {
      dispatch(getLeadTypes()).then(({ type }) => {
        if (type.includes(globalConstants.REJECTED)) setShowLeadTypeError(true);
      });
    }
    dispatch(setShowSidePanel(false));
    if (teamHierarchy.length === 0) {
      dispatch(getTeams());
    }
    if (leadControls.length === 0) {
      dispatch(getAllLeadControls());
      dispatch(getAllTeamLeadControls());
    }
  }, []);

  useEffect(() => {
    return () => {
      onSaveClicked();
    };
  }, [currentTab]);

  const defaultValues = {
    mobilePreview: false,
  };
  const { watch, control } = useForm({ defaultValues: defaultValues });
  const isPreviewModeEnabled = watch("mobilePreview");

  const toggleModal = (selectedLeadControlId) => {
    setPublishState({
      state: true,
      data: {
        isDefault: defaultLeadControl?.some(
          (obj) => obj.leadControlId === selectedLeadControlId
        ),
        teamIds: teamsToLeadControlCountMapping[selectedLeadControlId],
        leadControlName: leadControlName,
        leadControlId: selectedLeadControlId,
        leadTypeId: selectedLeadControl?.leadTypeId,
      },
    });
  };

  const getPublishButtonLabel = () => {
    if (publish.isPublishLoading || !publish.isPublished)
      return leadControlsConstants.PUBLISH;
    return leadControlsConstants.PUBLISHED;
  };

  const onPublishClicked = () => {
    if (publish.isPublishLoading || publish.isPublished) {
      return;
    }
    if (isLeadConfigurationDirty) {
      toast.warn(toastMessageConstants.SAVE_UNSAVED_CHANGES_BEFORE_PUBLISH);
      return;
    }
    toggleModal(selectedLeadControlId);
  };

  const getPublishButtonIcon = () => {
    if (publish.isPublishLoading)
      return <LsqProgressSpinner strokeWidth={3} strokeColor="white" />;
    if (!publish.isPublished) return <PublishIcon />;
    return <SavedIcon fill="white" />;
  };

  const getTeamsButtonLabel = () => {
    return defaultLeadControl?.some(
      (obj) => obj.leadControlId === selectedLeadControlId
    )
      ? "Default"
      : `${teamsToLeadControlCountMapping[selectedLeadControlId]?.length} Teams`;
  };

  const onTeamsButtonClicked = () => {
    setSidePanelState({
      state: true,
      data: {
        isDefault: defaultLeadControl?.some(
          (obj) => obj.leadControlId === selectedLeadControlId
        ),
        teamIds: teamsToLeadControlCountMapping[selectedLeadControlId],
        leadControlName: leadControlName,
        leadControlId: selectedLeadControlId,
        leadTypeId: selectedLeadControl?.leadTypeId,
      },
    });
  };
  const onBackClicked = () => {
    navigate(ROUTES_PATH.leadControls);
  };
  return (
    <div
      className="flex h-screen w-screen align-items-center"
      data-testid="lead-controls-view"
    >
      <div className={`toast_container`} style={{ top: "2rem" }}>
        <ToastMessage toastMessages={toastMessages} />
      </div>
      <div
        className={`flex h-full w-full align-items-center flex-column overflow-hidden `}
      >
        <div
          className={`flex w-full align-items-center ${styles["lead-controls-top-bar"]}`}
        >
          <div
            className={`flex align-items-center  justify-content-center h-full `}
          >
            <div
              className={`flex cursor-pointer p-1 ${styles["hamburger-icon"]}`}
              onClick={() => {
                dispatch(setSidePanelExpanded(true));
              }}
            >
              <Hamburger />
            </div>
            <div
              className={`flex cursor-pointer  ml-3 ${styles["back-icon"]}`}
              onClick={onBackClicked}
            >
              <Back />
            </div>
          </div>
          <div
            className={`flex relative justify-content-center align-items-center w-full`}
          >
            <div
              className={`flex justify-content-center gap-1 absolute w-full`}
            >
              <LeadTitleEditor
                displayText={leadControlName}
                onSaveHandler={(text) => {
                  selectedLeadControl &&
                    dispatch(
                      updateLeadControls({
                        leadControlId: selectedLeadControlId,
                        postBody: {
                          name: text.trim(),
                        },
                      })
                    );
                  setLeadControlName(text);
                }}
                setShowLeadType={setShowLeadType}
              />
            </div>
            <div className={`flex justify-content-end gap-2 w-full`}>
              <div className={`flex p-1 px-2 box-border`}>
                <LsqSwitchV2
                  control={control}
                  switchName="mobilePreview"
                  label={leadControlsConstants.PREVIEW_MODE}
                  disabled={showLeadType ? true : false}
                />
              </div>
              <CustomButton
                varient="outline"
                label={getSaveButtonLabel()}
                data-testid="lead-controls-save"
                onClick={onSaveClicked}
                disabled={!isLeadConfigurationDirty || isLeadConfigSaveLoading}
              />
              {selectedLeadControl?.status === "published" && (
                <CustomButton
                  varient="outline"
                  label={getTeamsButtonLabel()}
                  onClick={onTeamsButtonClicked}
                  data-testid="lead-controls-teams"
                />
              )}
              <CustomButton
                varient="filled"
                icon={getPublishButtonIcon()}
                label={getPublishButtonLabel()}
                onClick={onPublishClicked}
                data-testid="lead-controls-publish"
                disabled={showLeadType ? true : false}
              />
            </div>
          </div>
        </div>
        <div
          className={`flex h-full w-full align-items-center overflow-hidden relative`}
        >
          <div
            className={`flex flex-column gap-1 p-3 z-5 absolute ${styles["lead-control-side-panel"]}`}
          >
            {leadControlSideMenuOptions?.map((item) => {
              return (
                <div
                  key={item.tab}
                  className={`flex flex-column cursor-pointer gap-1 ${
                    styles["lead-controls-options"]
                  } ${
                    currentTab === item.tab &&
                    styles["active-lead-controls-option"]
                  }`}
                  onClick={() => onTabChange(item.tab)}
                  data-testid={item["data-testid"]}
                >
                  {item.displayIcon}
                  <Text type="T5B" color="var(--text-tertiary)">
                    {item.displayName}
                  </Text>
                </div>
              );
            })}
          </div>
          <div
            className={`flex h-full w-full ${styles["lead-controls-container"]}`}
          >
            {getSettingCards()}
          </div>
          <LeadTypeSelector
            state={showLeadType}
            setShowLeadType={setShowLeadType}
            leadControlName={leadControlName}
            setShowLeadTypeError={setShowLeadTypeError}
            showLeadTypeError={showLeadTypeError}
          />
          <MobilePreview state={isPreviewModeEnabled}></MobilePreview>
        </div>
      </div>
      <Modal
        state={publishState.state}
        backgroundStyle={{
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(22px)",
        }}
      >
        <PublishLeadControl
          setPublishState={setPublishState}
          publishState={publishState}
        ></PublishLeadControl>
      </Modal>
      <Modal
        state={sidePanelState.state}
        backgroundStyle={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SidePanel
          state={sidePanelState.state}
          setSidePanelState={setSidePanelState}
          style={{ top: "0" }}
        >
          <TeamsAssignedLeadControls
            sidePanelState={sidePanelState}
            setSidePanelState={setSidePanelState}
          />
        </SidePanel>
      </Modal>
    </div>
  );
}
