import axiosInstanceWeb from "./web.axios.instance";
import axiosInstance from "./axios.instance";
//"8040296d-f520-4de3-bdc0-6281196c12da"
//const launchbarIddummy= "22029a7c-0c74-47ae-ace2-1ad103022eb7"
export const launchbarId = () => {
  const launchbarInfo = localStorage.getItem('currentLaunchBarInfo');
  if (launchbarInfo) {
    try {
      const parsedInfo = JSON.parse(launchbarInfo);
      return parsedInfo.id || "default-launchbar-id"; // Provide a default ID if none is found
    } catch (error) {
      console.error('Failed to parse launchbar info from local storage:', error);
      return "default-launchbar-id"; // Provide a default ID if parsing fails
    }
  }
  return "71f78a97-0755-42b9-84bc-bc82f050907c";//'a46dbf95-af2a-4b78-848f-f97a408a59b7' // Provide a default ID if local storage is empty
};

// launch bar
export const API_ADD_WEB_LAUNCHBAR= async (postBody) => {
  return await axiosInstanceWeb.post("/launchers", postBody)
    .catch((err) => err.response);
};

export const API_GET_WEB_LAUNCHBAR = async (defaultLaunchbarId) => {
  const currentLaunchbarId = defaultLaunchbarId || launchbarId();
  return await axiosInstanceWeb
    .get(`/launchers/${currentLaunchbarId}`)
    .catch((err) => err.response);
};

export const API_GET_ALL_WEB_LAUNCHBAR = async () => {
  return await axiosInstanceWeb.get(`/launchers`).catch((err) => err.response);
};

export const API_UPDATE_WEB_LAUNCHBAR= async ( postBody,defaultLaunchbarId) => {
  const currentLaunchbarId = defaultLaunchbarId || launchbarId();
  return await axiosInstanceWeb
    .put(`/launchers/${currentLaunchbarId}`, postBody)
    .catch((err) => err.response);
};

export const API_DELETE_WEB_LAUNCHBAR = async (defaultLaunchbarId) => {
  const currentLaunchbarId = defaultLaunchbarId || launchbarId();
  return await axiosInstanceWeb
    .delete(`/launchers/${currentLaunchbarId}`)
    .catch((err) => err.response);
};

export const API_PUBLISH_WEB_LAUNCHBAR = async (launchbarId1, postBody) => {
  return await axiosInstanceWeb
    .post(`/launchers/${ launchbarId1||launchbarId()}/publish`, postBody)
    .catch((err) => err.response);
};

export const API_UNPUBLISH_WEB_LAUNCHBAR = async (defaultLaunchbarId) => {
  const currentLaunchbarId = defaultLaunchbarId || launchbarId();
  return await axiosInstanceWeb
    .post(`/launchers/${currentLaunchbarId}/unpublish`)
    .catch((err) => err.response);
};

export const API_UPDATE_LAUNCHBAR_TEAM_ASSIGNMENT = async (
  launcherID,
  postBody
) => {
  return await axiosInstanceWeb
    .put(`/launchers/${launcherID||launchbarId()}/teams`, postBody)
    .catch((err) => err.response);
};

export const API_GET_WEB_TEAMS_LAUNCHBAR = async () => {
  return await axiosInstanceWeb.get(`/launcher-teams`).catch((err) => err.response);
};

export const API_CREATE_WEB_LAUNCHBAR = async () => {
  return await axiosInstanceWeb.post(`/launchers`).catch((err) => err.response);
};
////widget/quicklauncher/metadata
export const API_GET_WEB_LAUNCHBAR_METADATA = async () => {
  return await axiosInstance
    .get(`/widget/quicklauncher/metadata`)
    .catch((err) => err.response);
};

export const API_CLONE_LAUNCHBAR = async () => {
  return await axiosInstanceWeb.post(`/launchers`).catch((err) => err.response);
};

export const API_WEB_UPLOAD_URL = async (data) => {
  return await axiosInstanceWeb.get(`/widgets/uploadUrl?${data}`).catch((err) => err.response);
};