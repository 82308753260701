import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../PreviewPane/styles.module.css";
import Wifi from "../../assets/svgIcons/wifi.svg";
import Cellular from "../../assets/svgIcons/cellular.svg";
import Battery from "../../assets/svgIcons/battery.svg";
import Hamburger from "../../assets/svgIcons/Menu.svg";
import Notifications from "../../assets/svgIcons/notification.svg";
import LeadsNearMe from "../../assets/svgIcons/nearme.svg";
import { ReactComponent as DragHandleIcon } from "../../assets/svgIcons/drag_handle.svg";
import PropTypes from "prop-types";
import { WidgetFactory } from "../../widgetPreview/WidgetFactory";
import { IndeterminateLoader } from "../../components/IndeterminateLoader";
import Rive, { Layout, Alignment } from "@rive-app/react-canvas";

function MobileScreenTemplate({
  sections,
  className,
  isSectionsLoading = false,
  isCustomFab = false,
  hasPopUpBanner = {state : false, imgUrl : ""},
}) {
  const { t } = useTranslation();
  const getCurrentTime = () => {
    return new Date().toLocaleTimeString("en-US", {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
    });
  };
  return (
    <div className={`${className} mobile-screen`}>
      <div className={styles["status-bar"]}>
        <div className={styles["time"]}>{getCurrentTime()}</div>
        <div className={styles["status-bar-content"]}>
          <img alt="wifi" src={Wifi} />
          <img alt="cellular" src={Cellular} />
          <img alt="battery" src={Battery} />
        </div>
      </div>
      <div className={styles["title-bar"]}>
        <div
          style={{ flex: "1" }}
          className={`flex h-full justify-content-center align-items-center`}
        >
          <img alt="hamburger" src={Hamburger} />
        </div>
        <div
          style={{ flex: "6", padding: "0px 3%" }}
          className={`flex h-full align-items-center justify-content-between`}
        >
          <div className={styles["home-text"]}>{t("Home")}</div>
          <div className="flex align-items-center gap-1">
            <img alt="notifications" src={Notifications} />
            <img alt="lead near me" src={LeadsNearMe} />
          </div>
        </div>
      </div>
      {isSectionsLoading ? (
        <IndeterminateLoader
          color="#0A1F43"
          height="0.25rem"
          backgroundColor="#B0B7C2"
        />
      ) : (
        <div className={styles["sections-container"]}>
          <div className={styles["sections-screen"]}>
            {sections.map((section) => {
              return (
                <div className={styles["section-drag"]} key={section.sectionId}>
                  <div className={styles["section-drag-handle"]}>
                    <DragHandleIcon />
                  </div>
                  <div
                    key={section.sectionId}
                    style={{
                      overflowY: "auto",
                      minHeight: `${
                        section.rows.length > 0 ? "fit-content" : "8rem"
                      }`,
                      overflow: "auto",
                    }}
                    className={styles["section"]}
                  >
                    {section.rows?.length > 0 &&
                      section.rows.map((row, rowIndex) => {
                        return (
                          <div
                            className="flex flex-column w-full align-items-center gap-1 "
                            key={row?.id}
                          >
                            {row.widgets[0].type === "half" ? (
                              <div className={styles["row-drag"]}>
                                <div className={styles["row-drop"]}>
                                  {row.widgets.map((widget, widgetIndex) => {
                                    return (
                                      <WidgetFactory
                                        key={widget.widgetId}
                                        index={widgetIndex}
                                        widgetInformation={widget}
                                        isDummy={true}
                                      />
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <WidgetFactory
                                key={row.widgets[0].widgetId}
                                index={row?.id}
                                widgetInformation={row.widgets[0]}
                                isDummy={true}
                              />
                            )}
                            {section.rows?.length > rowIndex + 1 && (
                              <hr
                                style={{
                                  border: `1px solid #ECEDF0`,
                                  width: "95%",
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
          {isCustomFab && (
            <div className={styles["custom-fab-screen"]}>
              <Rive
                src={`/riv/custom_fab.riv`}
                autoPlay
                layout={new Layout({ alignment: Alignment.BottomRight })}
              ></Rive>
            </div>
          )}
          {hasPopUpBanner.state && (
            <div className={styles["popup-banner-screen"]}>
              <img
                src={`${hasPopUpBanner.imgUrl}`}
                className={styles["popup-banner-img"]}
                alt={"Popup Banner"}
              ></img>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

MobileScreenTemplate.propTypes = {
  sections: PropTypes.array,
};

export default MobileScreenTemplate;
