import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useToast from "../../hooks/useToast";
import analytics from "../../utils/analytics";
import styles from "./styles.module.css";
import Text from "../../components/Text";
import CustomButton from "../../components/CustomButton";
import CustomInputV2 from "../../components/CustomInputV2";
import { ReactComponent as SearchIcon } from "../../assets/svgIcons/search.svg";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import { ReactComponent as Add } from "../../assets/svgIcons/add.svg";
import { ReactComponent as More } from "../../assets/svgIcons/lsq_more.svg";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { ReactComponent as DeleteIllustration } from "../../assets/illustrations/delete_illustration.svg";
import { ReactComponent as UpdateIllustration } from "../../assets/illustrations/update_illustration.svg";
import { InlineDropdown } from "../../components/InlineDropdown";
import ToggleTab from "../../components/ToggleTab";
import SidePanel from "../../components/SidePanel";
import TeamsAssignedLeadControls from "./TeamAssignmentLeadControls";
import Modal from "../../components/Modal";
import ConfirmDialog from "../../components/Modal/ConfirmDialog";
import PublishLeadControl from "./PublishLeadControl";
import ToastMessage from "../../components/ToastMessage";
import { IndeterminateLoader } from "../../components/IndeterminateLoader";
import { getTeams } from "../../reducers/homePageSlice";
import MoreOptionsPopup from "../../components/MoreOptionsPopup";
import {
  getLeadFields,
  getLeadTypes,
  resetLeadControlsConfigurations,
  resetSelectedLeadControls,
  unpublishLeadControl,
} from "../../reducers/leadControlsSlice";
import {
  formatDateTime,
  hierarchyToLinear,
  teamsToLeadControlMapping,
} from "../../utils/globalUtils";
import {
  ROUTES_PATH,
  globalConstants,
  leadControlsConstants,
} from "../../constants/globalConstant";
import {
  filterLeadControls,
  getAllLeadControlsData,
  leadControlsSortItems,
  leadListFilterItems,
  leadListMoreOptions,
  searchLeadControls,
  sortLeadControls,
} from "../../utils/leadControlsUtils";
import {
  addToastMessageFromLeadControlsList,
  cloneLeadControl,
  deleteLeadControl,
  getAllLeadControls,
  getAllTeamLeadControls,
  getLeadControlById,
} from "../../reducers/leadControlsAssignmentSlice";

export default function LeadControlsList() {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessageFromLeadControlsList);
  const navigate = useNavigate();
  const laedListViewSortState = useState(leadControlsSortItems[0].label);
  const leadViewSearchState = useState("");
  const leadViewFilterState = useState(leadListFilterItems[0].value);
  const { leadControls, leadControlById, toastMessages, isLeadControlLoading } =
    useSelector((state) => state.leadControlsAssignment);
  const { allLeadFields, leadTypes } = useSelector(
    (state) => state.leadControls
  );
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const [dialog, setDialog] = useState({ state: false, dialogBox: {} });
  const [activeTab, setActiveTab] = useState(leadListFilterItems[0]);
  const defaultLeadControl = leadControls?.defaultLeadControls;
  const [sidePanelState, setSidePanelState] = useState({
    state: false,
    data: null,
  });
  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });

  const onTabChange = (tab) => {
    setActiveTab(tab);
    leadViewFilterState[1](tab.value);
  };

  useEffect(() => {
    if (teamHierarchy.length === 0) {
      dispatch(getTeams());
    }
    if (!leadControls.teamLeadControls) dispatch(getAllTeamLeadControls());
    dispatch(getAllLeadControls());
    if (allLeadFields.length === 0) dispatch(getLeadFields());
    if (leadTypes.length === 0) dispatch(getLeadTypes());
    dispatch(resetLeadControlsConfigurations());
    dispatch(resetSelectedLeadControls());
  }, []);

  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);
  const teamsToLeadCountMapping = useMemo(() => {
    return teamsToLeadControlMapping(
      leadControls?.teamLeadControls,
      teamLinear
    );
  }, [leadControls, teamLinear]);

  const allleadControls = getAllLeadControlsData(
    Object.values(leadControlById),
    teamsToLeadCountMapping
  );
  const totalLeadControlsCreated = allleadControls.length;

  let filterAndSortedleadControls = searchLeadControls(
    allleadControls,
    leadViewSearchState[0]
  );
  filterAndSortedleadControls = sortLeadControls(
    filterAndSortedleadControls,
    laedListViewSortState[0]
  );
  filterAndSortedleadControls = filterLeadControls(
    leadViewFilterState[0],
    leadControlById,
    filterAndSortedleadControls
  );

  const onTeamCountClicked = (value) => {
    const leadControlId = value.id;
    setSidePanelState({
      state: true,
      data: {
        isDefault: defaultLeadControl?.some(
          (obj) => obj.leadControlId === leadControlId
        ),
        teamIds: teamsToLeadCountMapping[leadControlId],
        leadControlName: value.name,
        leadControlId: leadControlId,
        leadTypeId: value?.leadTypeId,
      },
    });
  };

  const teamsTemplate = (value) => {
    const leadControlId = value.id;
    let teams = "";
    if (value.status !== leadControlsConstants.PUBLISHED) return <></>;
    else
      teams = defaultLeadControl?.some(
        (obj) => obj.leadControlId === leadControlId
      )
        ? "Default"
        : `${value.teamsCount} Teams`;
    return (
      <div
        onClick={() => onTeamCountClicked(value)}
        className="flex cursor-pointer"
      >
        <Tag style={{ background: "#E2ECF9" }}>
          <Text type="T4B" color="#015CCD">
            {teams}
          </Text>
        </Tag>
      </div>
    );
  };

  const statusTemplate = (value) => {
    let backgroundColor = "";
    let textColor = "";
    switch (value.status) {
      case leadControlsConstants.UNPUBLISHED:
        backgroundColor = "#FFE6E6";
        textColor = "#FA0303";
        break;
      case leadControlsConstants.DRAFT:
        backgroundColor = "#F6F6F7";
        textColor = "#2F3237";
        break;
      default:
        backgroundColor = "#E6FEE8";
        textColor = "#04A410";
        break;
    }
    return (
      <Tag style={{ background: backgroundColor }}>
        <Text type="T4B" color={textColor} className="capitalize">
          {value.status}
        </Text>
      </Tag>
    );
  };
  const lastModifiedTemplate = (value) => {
    const date =
      laedListViewSortState[0] === leadControlsConstants.RECENTLY_CREATED
        ? value.createdAt
        : value.updatedAt;
    return formatDateTime(date).format("HH:mm | D MMM YYYY");
  };

  const editTemplate = (value) => {
    const leadControlId = value.id;
    return (
      <div
        className={`flex cursor-pointer`}
        data-testid="lead-edit-btn"
        onClick={() => {
          dispatch(getLeadControlById({ leadControlId })).then(({ type }) => {
            if (type.includes(globalConstants.FULFILLED)) {
              navigate(ROUTES_PATH.leadControlBuilder);
              analytics.sendEvent(
                "Update Lead Controls from leadControls table View",
                {
                  category: "update_lead_controls",
                  type: "click",
                  leadControlId: leadControlId,
                }
              );
            } else toast.error(`Unable to fetch lead Control`);
          });
        }}
      >
        <Edit />
      </div>
    );
  };

  const unpublishLeadControlClicked = (leadControl) => {
    const leadControlId = leadControl.id;
    dispatch(unpublishLeadControl({ leadControlId })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        toast.success(
          `${leadControl.name} was unpublished successfully!`,
          "Lead Control Unpublished"
        );
      }
    });
    setDialog({ state: false, dialogBox: {} });
  };

  const cloneLeadControlClicked = (leadControl) => {
    const leadControlId = leadControl.id;
    dispatch(cloneLeadControl({ leadControlId })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        navigate(ROUTES_PATH.leadControlBuilder);
        analytics.sendEvent(
          "clone Lead Controls from leadControls table View",
          {
            category: "clone_lead_controls",
            type: "click",
            leadControlId: leadControlId,
          }
        );
      } else toast.error(`Unable to clone lead Control`);
    });
  };

  const deleteLeadControlClicked = (leadControl) => {
    const leadControlId = leadControl.id;
    dispatch(deleteLeadControl({ leadControlId })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        toast.success(
          `${leadControl.name} was deleted successfully!`,
          "Lead Control Deleted"
        );
      }
    });
    setDialog({ state: false, dialogBox: {} });
  };

  const publishLeadControlClicked = (leadControl) => {
    const leadControlId = leadControl.id;
    setPublishState({
      state: true,
      data: {
        isDefault: defaultLeadControl?.some(
          (obj) => obj.leadControlId === leadControlId
        ),
        teamIds: teamsToLeadCountMapping[leadControlId],
        leadControlName: leadControl.name,
        leadControlId: leadControlId,
        leadTypeId: leadControl?.leadTypeId,
      },
    });
  };

  const dialogBox = (option, leadControl) => {
    let displayIcon = <></>;
    let actionLabel = "";
    let clickListener;
    let leadName = leadControl.name;
    switch (option) {
      case leadControlsConstants.UNPUBLISH:
        {
          displayIcon = <UpdateIllustration />;
          actionLabel = leadControlsConstants.UNPUBLISH;
          clickListener = unpublishLeadControlClicked;
        }
        break;
      case leadControlsConstants.DELETE:
        {
          displayIcon = <DeleteIllustration />;
          actionLabel = leadControlsConstants.DELETE;
          clickListener = deleteLeadControlClicked;
        }
        break;
      default:
        break;
    }
    const ConfirmDialogHandler = (e) => {
      clickListener(leadControl);
      e.stopPropagation();
    };
    return (
      <ConfirmDialog
        setState={setDialog}
        illustration={displayIcon}
        buttonLabel={actionLabel}
        displayText={
          <>
            <Text type="heading" className={`capitalize`} color="#0A1F43">
              {`${actionLabel} ${leadName}`}
            </Text>
            <Text type="sub-heading" color="#0A1F43">
              {`Are you sure you want to ${actionLabel}?`}
            </Text>
          </>
        }
        clickListener={ConfirmDialogHandler}
      />
    );
  };

  const leadItemClickHandler = (label, leadControl) => {
    switch (label) {
      case leadControlsConstants.CLONE:
        cloneLeadControlClicked(leadControl);
        break;
      case leadControlsConstants.DELETE:
        setDialog({
          state: true,
          dialogBox: dialogBox(leadControlsConstants.DELETE, leadControl),
        });
        break;
      case leadControlsConstants.UNPUBLISH:
        setDialog({
          state: true,
          dialogBox: dialogBox(leadControlsConstants.UNPUBLISH, leadControl),
        });
        break;
      case leadControlsConstants.PUBLISH:
        publishLeadControlClicked(leadControl);
        break;
    }
  };

  const optionTemplate = (value) => {
    return (
      <div className={`relative`}>
        <MoreOptionsPopup
          menu={value}
          options={leadListMoreOptions(value)}
          onClickIcon={<More />}
          menuItemClickHandler={leadItemClickHandler}
          data-testid="lead-more-btn"
        ></MoreOptionsPopup>
      </div>
    );
  };

  const leadTemplate = (value) => {
    return (
      <Text type="T1B" color="var(--text-focused)">
        {value.name}
      </Text>
    );
  };

  const leadTypeTemplate = (value) => {
    const leadType = leadTypes?.find(
      (leadType) => leadType.LeadTypeId === value?.leadTypeId
    );
    return (
      <Text type="T4B" color="var(--text-focused)" className="ellipsis w-8rem">
        {leadType?.Name}
      </Text>
    );
  };

  const createNewLeadControlsClicked = () => {
    dispatch(resetLeadControlsConfigurations());
    dispatch(resetSelectedLeadControls());
    navigate(ROUTES_PATH.leadControlBuilder);
    analytics.sendEvent("Create Lead Control from leadControls table View", {
      category: "create_new_lead_control",
      type: "click",
    });
  };

  return (
    <div
      className="flex h-screen w-screen align-items-center p-3"
      data-testid="lead-controls-list-view"
    >
      <div
        className={`flex h-full w-full align-items-center flex-column overflow-hidden ml-7 ${styles["lead-tabular-view"]}`}
      >
        <div
          className={`flex w-full align-items-center justify-content-between p-3`}
        >
          <div className={`flex align-items-center gap-1`}>
            <Text type="T1B" color="var(--text-primary)">
              {leadControlsConstants.LEAD_CONTROLS}
            </Text>
            <Tag style={{ background: "#E2ECF9" }}>
              <Text
                type="T4B"
                color="#015CCD"
              >{`${totalLeadControlsCreated} created`}</Text>
            </Tag>
          </div>
          <div className={`flex align-items-center gap-1`}>
            <CustomInputV2
              style={{ width: "20rem", height: "2.25rem" }}
              placeholder={leadControlsConstants.SEARCH_LEAD_CONTROLS}
              LeftIcon={SearchIcon}
              value={leadViewSearchState[0]}
              onChange={(e) => leadViewSearchState[1](e.target.value)}
              data-testid="lead-search"
            />
            <CustomButton
              label={leadControlsConstants.CREATE_NEW}
              varient="filled"
              icon={<Add />}
              style={{ width: "100%", height: "2.25rem" }}
              onClick={createNewLeadControlsClicked}
              data-testid="create-lead-controls-btn"
            ></CustomButton>
          </div>
        </div>
        {toastMessages.length > 0 ? (
          <div className={`${styles["toast_container"]}`} style={{ top: "0" }}>
            <ToastMessage toastMessages={toastMessages} />
          </div>
        ) : (
          <></>
        )}
        <div className={`flex w-full h-full flex-column overflow-scroll`}>
          {isLeadControlLoading ? (
            <IndeterminateLoader
              color="#0A1F43"
              height="0.25rem"
              backgroundColor="#B0B7C2"
            />
          ) : (
            <div style={{ height: "0.2rem" }} />
          )}
          <DataTable
            value={filterAndSortedleadControls}
            className="lsq-data-table"
            responsiveLayout="scroll"
            paginator
            rows={10}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <Column
              field="name"
              style={{ width: "40%" }}
              header={
                <ToggleTab
                  tabs={leadListFilterItems}
                  activeTab={activeTab}
                  setActiveTab={onTabChange}
                  style={{ width: "70%" }}
                ></ToggleTab>
              }
              body={leadTemplate}
            ></Column>
            <Column
              field="type"
              header="Lead Type"
              body={leadTypeTemplate}
              style={{ width: "15%" }}
            ></Column>
            <Column
              field="status"
              header="Status"
              body={statusTemplate}
            ></Column>
            <Column
              field={
                laedListViewSortState[0] ===
                leadControlsConstants.RECENTLY_CREATED
                  ? "createdAt"
                  : "updatedAt"
              }
              header={
                <InlineDropdown
                  items={leadControlsSortItems}
                  selectedLabel={laedListViewSortState[0]}
                  setSelectedLabel={laedListViewSortState[1]}
                  style={{ alignItems: "flex-start" }}
                ></InlineDropdown>
              }
              body={lastModifiedTemplate}
            ></Column>
            <Column
              field={"teamsCount"}
              header="Teams"
              body={teamsTemplate}
            ></Column>
            <Column style={{ width: "5%" }} body={editTemplate}></Column>
            <Column style={{ width: "5%" }} body={optionTemplate}></Column>
          </DataTable>
        </div>
      </div>
      <SidePanel
        state={sidePanelState.state}
        setSidePanelState={setSidePanelState}
        style={{ top: "0" }}
      >
        <TeamsAssignedLeadControls
          setSidePanelState={setSidePanelState}
          sidePanelState={sidePanelState}
        />
      </SidePanel>
      <Modal state={publishState.state}>
        <PublishLeadControl
          setPublishState={setPublishState}
          publishState={publishState}
        ></PublishLeadControl>
      </Modal>
      <Modal state={dialog.state}>{dialog.dialogBox}</Modal>
    </div>
  );
}
