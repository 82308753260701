import React from 'react';
import { Controller } from 'react-hook-form';

const ColorPicker = ({ colors, control, name }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <div className="flex space-x-2 py-4 border rounded-lg bg-white">
          {colors.map((color, index) => (
            <div
              key={index}
              data-testid={`color-${index}`}
              className={`rounded-lg flex justify-content-center cursor-pointer m-1 ${value === color ? 'ring-4 ring-purple-700' : ''}`}
              style={{ backgroundColor: color, height: '25px', width: '30px', margin: '5px', borderRadius: '5px' }}
              onClick={() => onChange(color)}
            >
              {value === color && (
                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  ></path>
                </svg>
              )}
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default ColorPicker;
