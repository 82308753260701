import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cloneDeep from "lodash.clonedeep";
import {
  API_ADD_HOMEPAGE,
  API_GET_HOMEPAGE,
  API_GET_HOMEPAGES,
  API_GET_TEAMS,
  API_GET_TEAM_DASHBOARDS,
  API_PUBLISH_HOMEPAGE,
  API_UPDATE_HOMEPAGE,
  API_DELETE_HOMEPAGE,
  API_UPDATE_TEAMS_DASHBOARDS,
  API_CLONE_HOMEPAGE,
  API_UPDATE_TEAM_DASHBOARDS,
  API_REPUBLISH_HOMEPAGE,
  API_GET_USER,
  API_UPDATE_TEAMSDASHBOARDS_WHEN_TEAM_DELETE_FROM_PLATFORM,
  API_DELETE_HOMEPAGES,
  API_GET_USER_PREFERENCES,
  API_PUT_USER_PREFERENCES,
  API_GET_HOMEPAGE_VERSION,
  API_REVERT_HOMEPAGE_VERSION,
  API_GET_TEAMS_MENUS,
} from "../api/configurationScreenServices";
import { resetWidgetConfiguration, resetToastMessageFromWidgetTemplateList } from "./widgetTemplateListSlice";
import { teamsDashboardsMappingWithTeamId } from "../utils/reducersUtils";
import {
  resetPreviewPaneToInitialState,
  updateDraftAndPublishedLayout,
  resetToastMessage
} from "./previewPaneSlice";
import {
  casaConstants,
  errorMessageConstants,
  toastMessageConstants,
} from "../constants/globalConstant";
import { resetToastMessageFromMenuBuilder, setTeamMenus } from "./menuBuilderSlice";
import { resetToastMessagesFromTemplates } from "./templatesSlice";
import {
  hierarchyToLinear,
  deletedTeamsFromPlatform,
} from "../utils/globalUtils";
import analytics from "../utils/analytics";
import { getUpdatedTeamDashboards } from "../utils/homepageUtils";
import { resetToastMessageFromThemeBuilder } from "./themeBuilderSlice";
import { resetToastMessagesFromLeadControls } from "./leadControlsSlice";
import { resetToastMessagesFromWebHomepage } from "./webHomepageSlice";
import { resetToastMessagesFromWebLaunchBar } from "./webLaunchbarSlice";
import { resetToastMessagesFromLeadControlsList } from "./leadControlsAssignmentSlice";

export const resetHomeBuilder = createAsyncThunk(
  "homepage/resetHomebuilder",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(resetWidgetConfiguration());
    thunkAPI.dispatch(resetPreviewPaneToInitialState());
  }
);

export const getHomepages = createAsyncThunk(
  "homepage/getHomepages",
  async (_, thunkAPI) => {
    const response = await API_GET_HOMEPAGES();
    const { data } = response;
    if(response?.status !== 200) {
      const errorMessage = data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);
export const getHomepage = createAsyncThunk(
  "homepage/getHomepage",
  async (homepageId, thunkAPI) => {
    const response = await API_GET_HOMEPAGE(homepageId);
    const { data } = response;
    if(response?.status !== 200) {
      const errorMessage = data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const getHomepageVersions = createAsyncThunk(
  "versions/homepageId",
  async (homepageId, thunkAPI) => {
    const response = await API_GET_HOMEPAGE_VERSION(homepageId);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const revertHomepageVersion =createAsyncThunk(
  "versions/versionId/homepages/homepageId",
  async ({versionId, homepageId}, thunkAPI) => {
    const response = await API_REVERT_HOMEPAGE_VERSION(versionId, homepageId);
    const { data } = response;
    if (response?.status !== 200) {
      const errorMessage =
        data?.message || toastMessageConstants.SOMETHING_WENT_WRONG;
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return data;
  }
);

export const publishHomepage = createAsyncThunk(
  "homepage/publishHomepage",
  async ({ postBody, createVersion }, thunkAPI) => {
    const homepageId = thunkAPI.getState().homepage.selectedHomepageId;
    const previewPane = thunkAPI.getState().previewPane;
    const { hasDraftChanges } = previewPane;
    if (hasDraftChanges) {
      const republishResponse = await API_REPUBLISH_HOMEPAGE(homepageId, createVersion);
      if (republishResponse.status !== 200) {
        return thunkAPI.rejectWithValue(
          republishResponse.data?.message
        );
      }
    }
    const response = await API_PUBLISH_HOMEPAGE(homepageId, postBody);
    const {data , status} = response
    if (status !== 200) {
      return thunkAPI.rejectWithValue(data.message);
    }
    thunkAPI.dispatch(updateDraftAndPublishedLayout(data.dashboard))
    thunkAPI.dispatch(getTeamDashboards());
    return data;
  }
);

export const getTeams = createAsyncThunk(
  "homepage/getTeams",
  async (_, thunkAPI) => {
    const response = await API_GET_TEAMS(true);
    if(response?.status !== 200) {
      const errorMessage = response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const getTeamDashboards = createAsyncThunk(
  "homepage/getTeamDashboards",
  async (_, thunkAPI) => {
    const response = await API_GET_TEAM_DASHBOARDS();
    if(response?.status !== 200) {
      const errorMessage = response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const deleteHomePage = createAsyncThunk(
  "homepage/deleteHomepage",
  async ({ homepageId, postBody }, thunkAPI) => {
    const deletedHomepageResponse = await API_DELETE_HOMEPAGE(
      homepageId,
      postBody
    );
    const deletedResponse = deletedHomepageResponse.data;
    if(deletedHomepageResponse?.status !== 200) {
      const errorMessage = deletedResponse?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const {homepageIds, homepagesById} = thunkAPI.getState().homepage;
    const _homepageIds = homepageIds.filter(
      (_homepageId) => _homepageId !== homepageId
    );
    const _homepagesById = {...homepagesById}
    delete _homepagesById[homepageId]
    const teamDashboards = thunkAPI.getState().homepage.teamDashboards;
    if (deletedResponse.defaultDashboards) {
      return {
        homepageIds: _homepageIds,
        homepageById: _homepagesById,
        teamDashboards: {
          ...teamDashboards,
          defaultDashboards: deletedResponse.defaultDashboards,
        },
      };
    }
    const teamsDashboards = teamDashboards.teamsDashboards;
    const updatedteamsDashboards = deletedResponse.teamsDashboards || [];
    const teamsDashboardsMappingWithId = teamsDashboardsMappingWithTeamId(
      teamsDashboards,
      updatedteamsDashboards
    );
    const defaultDashboards = teamDashboards?.defaultDashboards || {};
    return {
      homepageIds: _homepageIds,
      homepageById: _homepagesById,
      teamDashboards: {
        defaultDashboards: { ...defaultDashboards },
        teamsDashboards: [...Object.values(teamsDashboardsMappingWithId)],
      },
    };
  }
);

export const updateHomePage = createAsyncThunk(
  "homepage/updateHomepage",
  async ({ homepageId, postBody }, thunkAPI) => {
    const updatedHomepageResponse = await API_UPDATE_HOMEPAGE(
      homepageId,
      postBody
    );
    const {data, status} = updatedHomepageResponse
    if (status !== 200)
      return thunkAPI.rejectWithValue(data?.message);
    const updatedHomepage = data;
    const {selectedHomepageId} = thunkAPI.getState().homepage;
    if(homepageId === selectedHomepageId) {
      const publishedStatus = !updatedHomepage?.hasDraftChanges && updatedHomepage?.status === casaConstants.PUBLISHED
      thunkAPI.dispatch(changePublishState(publishedStatus))
    }
    const {homepagesById} = thunkAPI.getState().homepage;
    const newHomepages = {...homepagesById}
    newHomepages[homepageId] = {
      ...newHomepages[homepageId],
      ...updatedHomepage
    }
    return { dashboards: newHomepages };
  }
);

export const updateTeamsDashboards = createAsyncThunk(
  "homepage/updateTeamsDashboards",
  async ({ homepageId, postBody, lastAssignedTeamDashboard }, thunkAPI) => {
    const updatedteamDashboardsResponse = await API_UPDATE_TEAMS_DASHBOARDS(
      homepageId,
      postBody
    );

    const teamsDashboardsResponse = updatedteamDashboardsResponse?.data;
    if (updatedteamDashboardsResponse?.status !== 200){
      const errorMessage = teamsDashboardsResponse?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const {teamDashboards, homepagesById} = thunkAPI.getState().homepage;
    let _homepagesById = {...homepagesById}
    const _homepage = _homepagesById[homepageId]
    if(_homepage){
      _homepagesById[homepageId] = {
        ..._homepage,
        ...lastAssignedTeamDashboard,
      }
    }
    if (teamsDashboardsResponse.defaultDashboards) {
      return {
        homepagesById: _homepagesById,
        teamDashboards: {
          defaultDashboards: { ...teamsDashboardsResponse.defaultDashboards },
          teamsDashboards: [...teamDashboards.teamsDashboards],
        },
      };
    }
    const teamsDashboards = teamDashboards.teamsDashboards;
    const updatedteamsDashboards = teamsDashboardsResponse.teamsDashboards;
    const defaultDashboards = teamDashboards.defaultDashboards;
    const teamsDashboardsMappingWithId = teamsDashboardsMappingWithTeamId(
      teamsDashboards,
      updatedteamsDashboards
    );
    return {
      homepagesById: _homepagesById,
      teamDashboards: {
        defaultDashboards: { ...defaultDashboards },
        teamsDashboards: [...Object.values(teamsDashboardsMappingWithId)],
      },
    };
  }
);

export const cloneHomepage = createAsyncThunk(
  "homepage/cloneHomepages",
  async (homepageId, thunkAPI) => {
    const newHomepageResponse = await API_CLONE_HOMEPAGE(homepageId);
    if (newHomepageResponse?.status !== 200) {
      const errorMessage = newHomepageResponse?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const clonedHomepage = newHomepageResponse.data;
    return clonedHomepage;
  }
);

export const apiAssignTeams = createAsyncThunk(
  "homepage/apiAssignTeams",
  async ({ homepageId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_TEAMS_DASHBOARDS(homepageId, postBody);
    if (response?.status !== 200) {
      const errorMessage = response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiAddHomepage = createAsyncThunk(
  "homepage/apiAddHomepage",
  async (_, thunkAPI) => {
    const response = await API_ADD_HOMEPAGE();
    if (response?.status !== 200) {
      const errorMessage = response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiUpdateTeamDashboardOrder = createAsyncThunk(
  "homepage/apiUpdateTeamDashboardOrder",
  async ({ teamId, postBody }, thunkAPI) => {
    const response = await API_UPDATE_TEAM_DASHBOARDS(teamId, postBody);
    if (response?.status !== 200) {
      const errorMessage = response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);

export const apiGetUser = createAsyncThunk(
  "homepage/apiGetUser",
  async (_, thunkAPI) => {
    const response = await API_GET_USER();
    if (response?.status !== 200) {
      const errorMessage = response?.data?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    return response.data;
  }
);
export const resetToastMessages = createAsyncThunk(
  "homepage/resetToastMessages",
  (_, thunkAPI) => {
    thunkAPI.dispatch(resetToastMessage())
    thunkAPI.dispatch(resetToastMessageFromWidgetTemplateList())
    thunkAPI.dispatch(resetToastMessagesFromTemplates())
    thunkAPI.dispatch(resetToastMessageFromMenuBuilder())
    thunkAPI.dispatch(resetToastMessageFromThemeBuilder())
    thunkAPI.dispatch(resetToastMessagesFromLeadControls())
    thunkAPI.dispatch(resetToastMessagesFromWebHomepage())
    thunkAPI.dispatch(resetToastMessagesFromWebLaunchBar())
    thunkAPI.dispatch(resetToastMessagesFromLeadControlsList())
  }
);

export const updateTeamsDashboardsPlatform = createAsyncThunk(
  "homepage/updateTeamsDashboardsPlatform",
  async (
    { teamId, teamsDashboardsMappingWithId, homePagesMappedWithHomepageId },
    thunkAPI
  ) => {
    const response =
      await API_UPDATE_TEAMSDASHBOARDS_WHEN_TEAM_DELETE_FROM_PLATFORM(teamId);
    const homepagesResponse = response?.data.updatedDashboards;
    const menuResponse = response?.data.updatedMenu;
    if (response?.status !== 200) {
      const errorMessage = homepagesResponse?.message || toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    delete teamsDashboardsMappingWithId[teamId];

    for (let homepageResponse of homepagesResponse) {
      homePagesMappedWithHomepageId[homepageResponse?.homepageId] =
      homepageResponse;
    }
    return {
      updatedHomepagesMappedWithHomepageId: homePagesMappedWithHomepageId,
      upatedTeamsDashboardsMappingWithId: teamsDashboardsMappingWithId,
      deletedTeamIdMenu : menuResponse,
    };
  }
);

export const getTeamsAndTeamsAssignments = createAsyncThunk(
  "homepage/getTeamsAndTeamsAssignments",
  async (_, thunkAPI) => {
    const response = await Promise.all([
      API_GET_TEAMS(true),
      API_GET_TEAM_DASHBOARDS(),
      API_GET_TEAMS_MENUS(),
    ]);
    const teams = response[0]?.data;
    const actualTeamDashboards = response[1]?.data;
    const menus = response[2]?.data;
    let teamMenu = menus?.teamMenu;
    if (response?.some((item) => item.status !== 200)) {
      const errorMessage = toastMessageConstants.SOMETHING_WENT_WRONG
      return thunkAPI.rejectWithValue(errorMessage);
    }
    const linearTeams = hierarchyToLinear(teams);
    const deletedTeamIds = deletedTeamsFromPlatform(
      linearTeams,
      actualTeamDashboards?.teamsDashboards,
      teamMenu,
    );
    const { homepagesById } = thunkAPI.getState().homepage
    const teamsDashboards = actualTeamDashboards?.teamsDashboards;
    let teamsDashboardsMappingWithId = {};
    for (let teamsDashboard of teamsDashboards) {
      if (!teamsDashboardsMappingWithId[teamsDashboard.teamId]) {
        teamsDashboardsMappingWithId[teamsDashboard.teamId] =
        teamsDashboard;
      }
    }
    let _homepagesById = {...homepagesById}
    if (deletedTeamIds.length !== 0) {
      for (const teamId of deletedTeamIds) {
        const response = await thunkAPI.dispatch(
          updateTeamsDashboardsPlatform({
            teamId,
            teamsDashboardsMappingWithId,
            homepagesById,
          })
        );
        const {
          updatedHomepagesMappedWithHomepageId,
          upatedTeamsDashboardsMappingWithId,
          deletedTeamIdMenu,
        } = response.payload;
        teamsDashboardsMappingWithId = upatedTeamsDashboardsMappingWithId;
        _homepagesById = updatedHomepagesMappedWithHomepageId;
        teamMenu = teamMenu.filter(
          (entry) => entry.menuId != deletedTeamIdMenu.menuId
        );
      }
    }
    thunkAPI.dispatch(setTeamMenus({...menus, teamMenu : teamMenu}));
    return {
      teams,
      teamDashboards: {
        ...actualTeamDashboards,
        teamsDashboards: Object.values(teamsDashboardsMappingWithId),
      },
      homepagesById: _homepagesById,
    };
  }
);

export const apiDeleteHomepagesBulk = createAsyncThunk(
  "homepage/apiDeleteHomepagesBulk", async (homepages, thunkAPI) => {
    const bulkDeleteResponse = await API_DELETE_HOMEPAGES(homepages);
    const {teamDashboards, homepagesById} = thunkAPI.getState().homepage
    const {status, data} = bulkDeleteResponse;
    if(status !== 200) return thunkAPI.rejectWithValue(errorMessageConstants.API_DELETE_HOMEPAGES)
    const _teamDashboards = getUpdatedTeamDashboards(data, teamDashboards)
    const _homepagesById = {...homepagesById}
    for(let homepageId of homepages) {
      delete _homepagesById[homepageId]
    }
    return {_teamDashboards, _homepagesById}
  })

export const apiGetUserPreferences = createAsyncThunk(
  "homepage/apiGetUserPreferences", async (_, thunkAPI) => {
    const userPreferencesResponse = await API_GET_USER_PREFERENCES();
    const {status, data} = userPreferencesResponse;
    if(status !== 200) return thunkAPI.rejectWithValue(errorMessageConstants.API_GET_USER_PREFERENCE)
    return data
  }
)

export const apiUpdateUserPreferences = createAsyncThunk(
  "homepage/apiUpdateUserPreferences", async (payload, thunkAPI) => {
    const userPreferencesResponse = await API_PUT_USER_PREFERENCES(payload);
    const {status, data} = userPreferencesResponse;
    if(status !== 200) return thunkAPI.rejectWithValue(errorMessageConstants.API_GET_USER_PREFERENCE)
    return data
  }
)

const initialState = {
  user: null,
  userPreferences: {},
  homepageIds: [],
  homepagesById: {},
  teams: [],
  teamDashboards: {},
  selectedHomepageId: null,
  selectedTab: 0,
  selectedSidePanelView : null,
  sidePanelExpanded : false,
  showSidePanel : true,
  isHomepageLoading: false,
  publish: {
    isPublishLoading: false,
    isPublished: false,
  },
  toastMessages: [],
  isVersionEnabled : false,
  isVersionLoading : false,
};

const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    changePublishState(state, action) {
      state.publish.isPublished = action.payload;
    },
    changeSelectedHomepage(state, action) {
      state.selectedHomepageId = action.payload;
    },
    changeSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    changeSidePanelView(state, action){
      state.selectedSidePanelView = action.payload;
    },
    toggleHomepageVersion(state, action) {
      state.isVersionEnabled = action.payload;
    },
    addHomepage(state, action) {
      const _homepage = action.payload;
      state.homepageIds.push(_homepage.homepageId);
      const _homepagesById = {
        ...state.homepagesById,
        [_homepage.homepageId]: _homepage
      }
      state.homepagesById = _homepagesById
    },
    addToastMessage(state, action) {
      state.toastMessages.push(action.payload);
    },
    updateHomepageInHomepages(state, action) {
      const _homepage = action.payload;
      const _homepagesById = {
        ...state.homepagesById,
        [_homepage.homepageId]: _homepage
      }
      state.homepagesById = _homepagesById
    },
    addHomepageLayout(state, action){
      const { homepageId, data } = action.payload;
      let _homepagesById = {...state.homepagesById};
      _homepagesById[homepageId] = {
        ...state.homepagesById[homepageId],
        homepageLayout : {
          ...data
        },
      }
      state.homepagesById = _homepagesById;
    },
    setSidePanelExpanded(state, action) {
      state.sidePanelExpanded = action.payload;
    },
    setShowSidePanel(state, action) {
      state.showSidePanel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(apiGetUser.fulfilled, (state, action) => {
      const {payload} = action
      if (process.env.NODE_ENV === "production" && payload) {
        analytics.setUser(payload);
      }
      state.user = payload;
    });
    builder.addCase(getHomepages.pending, (state) => {
      state.isHomepageLoading = true;
    });
    builder.addCase(getHomepages.fulfilled, (state, action) => {
      const {dashboards} = action.payload
      let _homepageIds = [];
      const _homepagesById = Object.fromEntries(
        dashboards?.map((dashboard) => {
          _homepageIds = [..._homepageIds, dashboard.homepageId]
          return [dashboard.homepageId, dashboard]
        })
      );
      state.homepageIds = _homepageIds;
      state.homepagesById = _homepagesById
      state.isHomepageLoading = false;
    });
    builder.addCase(getHomepages.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(publishHomepage.pending, (state) => {
      state.publish.isPublishLoading = true;
      state.isHomepageLoading = true;
    });
    builder.addCase(publishHomepage.fulfilled, (state, action) => {
      const { dashboard } = action.payload;
      let _homepagesById = {...state.homepagesById};
      _homepagesById[dashboard.homepageId] = dashboard
      state.selectedHomepageId = dashboard.homepageId
      state.homepagesById = _homepagesById;
      state.publish.isPublishLoading = false;
      state.publish.isPublished = true;
      state.isHomepageLoading = false;
    });
    builder.addCase(publishHomepage.rejected, (state ) => {
      state.publish.isPublishLoading = false;
      state.isHomepageLoading = false;
    });
    builder.addCase(getTeams.pending, (state) => {
      state.isHomepageLoading = true;
    });
    builder.addCase(getTeams.fulfilled, (state, action) => {
      state.teams = action.payload;
      state.isHomepageLoading = false;
    });
    builder.addCase(getTeams.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(getTeamDashboards.pending, (state) => {
      state.isHomepageLoading = true;
    });
    builder.addCase(getTeamDashboards.fulfilled, (state, action) => {
      state.teamDashboards = action.payload;
      state.isHomepageLoading = false;
    });
    builder.addCase(getTeamDashboards.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(deleteHomePage.pending, (state) => {
      state.isHomepageLoading = true;
    });
    builder.addCase(deleteHomePage.fulfilled, (state, action) => {
      const { homepageIds, homepageById, teamDashboards } = action.payload;
      state.teamDashboards = teamDashboards;
      state.homepageIds = homepageIds;
      state.homepagesById = homepageById
      state.isHomepageLoading = false;
    });
    builder.addCase(deleteHomePage.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(updateHomePage.pending, (state) => {
      state.isHomepageLoading = true;
    });
    builder.addCase(updateHomePage.fulfilled, (state, action) => {
      const { dashboards } = action.payload;
      state.homepagesById = dashboards;
      state.isHomepageLoading = false;
    });
    builder.addCase(updateHomePage.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(updateTeamsDashboards.pending, (state) => {
      state.isHomepageLoading = true;
    });
    builder.addCase(updateTeamsDashboards.fulfilled, (state, action) => {
      const { teamDashboards, homepagesById } = action.payload;
      state.homepagesById = homepagesById;
      state.teamDashboards = teamDashboards;
      state.isHomepageLoading = false;
    });
    builder.addCase(updateTeamsDashboards.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(cloneHomepage.pending, (state) => {
      state.isHomepageLoading = true;
    });
    builder.addCase(cloneHomepage.fulfilled, (state, action) => {
      const clonedHomepage = action.payload;
      const _homepagesById = {
        ...state.homepagesById,
        [clonedHomepage.homepageId]: clonedHomepage
      }
      const _homepageIds = [...state.homepageIds, clonedHomepage.homepageId]
      state.homepagesById = _homepagesById;
      state.homepageIds = _homepageIds
      state.isHomepageLoading = false;
    });
    builder.addCase(cloneHomepage.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
    });
    builder.addCase(apiAddHomepage.fulfilled, (state, action) => {
      const _homepage = action.payload
      const _homepageId = _homepage.homepageId
      const _homepagesById = {
        ...state.homepagesById,
        [_homepageId]: _homepage
      }
      state.homepageIds.push(_homepageId);
      state.homepagesById = _homepagesById
      state.selectedHomepageId = _homepageId;
    });
    builder.addCase(getHomepage.fulfilled, (state, action) => {
      const { hasDraftChanges, status, homepageId } = action.payload;
      state.selectedHomepageId = homepageId;
      const _isPublished =
        !hasDraftChanges && status === casaConstants.PUBLISHED;
      state.publish.isPublished = _isPublished;
    });
    builder.addCase(apiAssignTeams.pending, (state) => {
      state.isHomepageLoading = true;
      state.publish.isPublishLoading = true;
    });
    builder.addCase(apiAssignTeams.fulfilled, (state, action) => {
      const clonedTeamDashboards = cloneDeep(state.teamDashboards);
      let newTeamDashboards = action.payload;
      if (newTeamDashboards.defaultDashboards) {
        clonedTeamDashboards.defaultDashboards =
          newTeamDashboards.defaultDashboards;
      }
      if (newTeamDashboards.teamsDashboards?.length !== 0) {
        let newTeamsDashboards = newTeamDashboards.teamsDashboards;
        let results = clonedTeamDashboards.teamsDashboards.filter(
          ({ teamId: id1 }) =>
            !newTeamsDashboards.some(({ teamId: id2 }) => id2 === id1)
        );
        clonedTeamDashboards.teamsDashboards = [
          ...results,
          ...newTeamsDashboards,
        ];
      }
      state.teamDashboards = clonedTeamDashboards;
      state.isHomepageLoading = false;
      state.publish.isPublishLoading = false;
    });
    builder.addCase(apiAssignTeams.rejected, (state,action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: "error",
        title: "Error",
        message: action.payload,
      });
      state.publish.isPublishLoading = false;
    });
    builder.addCase(resetHomeBuilder.fulfilled, (state) => {
      state.selectedHomepageId = null;
      state.publish = {
        isPublishLoading: false,
        isPublished: false,
      };
    });
    builder.addCase(apiUpdateTeamDashboardOrder.fulfilled, (state, action) => {
      let clonedTeamDashboards = {
        ...state.teamDashboards,
      };
      const updatedTeam = action.payload;
      if (
        clonedTeamDashboards?.defaultDashboards?.teamId === updatedTeam.teamId
      ) {
        clonedTeamDashboards = {
          ...clonedTeamDashboards,
          defaultDashboards: updatedTeam,
        };
      } else {
        clonedTeamDashboards = {
          ...clonedTeamDashboards,
          teamsDashboards: clonedTeamDashboards?.teamsDashboards?.map(
            (team) => {
              if (team.teamId === updatedTeam.teamId) {
                return updatedTeam;
              }
              return team;
            }
          ),
        };
      }
      state.teamDashboards = clonedTeamDashboards;
    });

    builder.addCase(resetToastMessages.fulfilled, (state) => {
      state.toastMessages.shift();
    });
    builder.addCase(updateTeamsDashboardsPlatform.rejected, (state,action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload,
      });
      state.isHomepageLoading = false;
    });
    builder.addCase(getTeamsAndTeamsAssignments.fulfilled, (state, action) => {
      const { teams, teamDashboards, homepagesById } = action.payload;
      state.teams = teams;
      state.teamDashboards = teamDashboards;
      state.homepagesById = homepagesById;
      state.homepageIds = Object.keys(homepagesById)
      state.isHomepageLoading = false;
    });
    builder.addCase(getTeamsAndTeamsAssignments.rejected, (state, action) => {
      state.isHomepageLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    });
    builder.addCase(getTeamsAndTeamsAssignments.pending, (state) => {
      state.isHomepageLoading = true;
    });

    builder.addCase(apiDeleteHomepagesBulk.fulfilled, (state, action) => {
      const {_teamDashboards, _homepagesById} = action.payload;
      state.teamDashboards = _teamDashboards
      state.homepagesById = _homepagesById
      state.homepageIds = Object.keys(_homepagesById)
    })
    builder.addCase(apiDeleteHomepagesBulk.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    })
    builder.addCase(apiGetUserPreferences.fulfilled , (state, action) => {
      state.userPreferences = action.payload
    })

    builder.addCase(apiUpdateUserPreferences.fulfilled , (state, action) => {
      state.userPreferences = action.payload
    })
    builder.addCase(getHomepageVersions.fulfilled, (state) => {
      state.isVersionLoading = false;
    })
    builder.addCase(getHomepageVersions.rejected, (state, action) => {
      state.isVersionLoading = false;
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    })
    builder.addCase(getHomepageVersions.pending, (state) => {
      state.isVersionLoading = true;
    })
    builder.addCase(revertHomepageVersion.rejected, (state, action) => {
      state.toastMessages.push({
        type: toastMessageConstants.ERROR_TYPE,
        title: toastMessageConstants.ERROR,
        message: action.payload || toastMessageConstants.SOMETHING_WENT_WRONG,
      });
    })
  },
});

export const {
  changePublishState,
  changeSelectedHomepage,
  changeSelectedTab,
  changeSidePanelView,
  toggleHomepageVersion,
  addHomepage,
  addToastMessage,
  updateHomepageInHomepages,
  addHomepageLayout,
  setSidePanelExpanded,
  setShowSidePanel,
} = homepageSlice.actions;

export default homepageSlice.reducer;