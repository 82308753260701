import 'primeflex/primeflex.css';
import DynamicSVG from '../DynamicSVGComponent/dynamicSVG';

const ProgressBarPreview = ({ widgetInformation = {},onWidgetEdit,  current = 60, total = 102 }) => {
  const firstHalfWidth = (current / total) * 100;
  const secondHalfWidth = 20;
  const thirdHalfWidth = 30;
  const { config } = widgetInformation;

  return (
    <div className="non-draggable bg-white rounded-[8px] w-full h-full p-3  flex flex-column justify-content-between" onClick={onWidgetEdit}>
      <div className="flex justify-content-end align-items-left mb-1">
        <DynamicSVG  color={'#2F3237'} iconName={config?.icon?.imgName} width = '24px' height = '24px' opacity={.3}/>
      </div>
      <div className="flex justify-content-between align-items-center mb-2 overflow-ellipsis">
        <span style={{color: "var(--N600)", fontSize: "14px"}} className="font-medium overflow-ellipsis mr-1" title={config?.name}>{config?.name || "Progress bar"}</span>
        <span className="text-base  text-gray-900">80<span className="text-xs font-normal text-gray-500">/{total}</span>
        </span>
      </div>
      <div className="relative w-full bg-gray-200 border-round"> 
        <div className="progress-bar border-round h-[5px]">
          <div className="segment  border-round-left" style={{ width: `${firstHalfWidth}%`,  backgroundColor: !config?.setThresholds?'var(--N400)':"#FFB200" }}></div>
          <div className="segment " style={{ width: `${secondHalfWidth}%`,backgroundColor: !config?.setThresholds?'var(--N400)':"#FFB200", opacity:!config?.setThresholds?1: 0.5 }}></div>
          <div className="segment  border-round-right" style={{ width: `${thirdHalfWidth}%`, backgroundColor: !config?.setThresholds?'var(--N400)':"#45BE4E", opacity:!config?.setThresholds?1: 0.5}}></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBarPreview;
