import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import useToast from "../../hooks/useToast";
import { ReactComponent as CrossIcon } from "../../assets/svgIcons/lsq_close.svg";
import CustomButton from "../../components/CustomButton";
import LsqSwitch from "../../components/LsqSwitch";
import TeamAssignmentMultiselectTreeDropdown from "../../components/TeamAssignmentMultiselectTreeDropdown";
import {
  teamIdsToTreeList,
  teamListBuilderForLeadControls,
} from "../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import Text from "../../components/Text";
import {
  globalConstants,
  leadControlsConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import { hierarchyToLinear } from "../../utils/globalUtils";
import { generateTeamAssignmentLeadControlPostData } from "../../utils/casaUtils";
import {
  addToastMessageFromLeadControls,
  updateTeamAssignmentForLeadControl,
} from "../../reducers/leadControlsSlice";

const TeamsAssignedLeadControls = ({ setSidePanelState, sidePanelState }) => {
  const { t } = useTranslation();
  const toast = useToast(addToastMessageFromLeadControls);
  const dispatch = useDispatch();
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const { orgCode } = useSelector((state) => state.homepage.user);
  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);
  const { leadControls } = useSelector((state) => state.leadControlsAssignment);
  const defaultLeadControls = leadControls?.defaultLeadControls;
  const teamIds = sidePanelState.data?.teamIds;
  const leadControlName = sidePanelState.data?.leadControlName;
  const leadControlId = sidePanelState.data?.leadControlId;
  const isDefault = sidePanelState.data?.isDefault;
  const leadTypeId = sidePanelState.data?.leadTypeId;

  const teamsDefaultValues = {
    isDefault: false,
    teamAssigned: {},
  };
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues: teamsDefaultValues });
  const isDefaultEnabled = watch("isDefault");
  const watchTeamAssigned = watch("teamAssigned");

  const isSaveDisabled = () => {
    const isDisabled = !!(isDefaultEnabled === false && Object.keys(watchTeamAssigned).length === 0)
    return isDisabled;
  };

  const onSaveClicked = (data) => {
    const existingTeamAssigned = {
      isDefault: sidePanelState.data?.isDefault,
      teamAssigned: teamIds,
    };
    const currentTeamAssigned = {
      ...data,
    };
    if (isDefaultEnabled) {
      currentTeamAssigned.teamAssigned = {};
    }
    const teamAssigmentPostData = generateTeamAssignmentLeadControlPostData(
      orgCode,
      existingTeamAssigned,
      currentTeamAssigned,
      leadControlName
    );
    dispatch(
      updateTeamAssignmentForLeadControl({
        leadControlId: leadControlId,
        postBody: teamAssigmentPostData,
      })
    ).then(({ type, payload }) => {
      if (type.includes(globalConstants.REJECTED)) {
        const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
        toast.error(message);
      }
    });
    setSidePanelState({ state: false, data: null });
  };

  const isDefaultSwitchDisabled =
    defaultLeadControls?.some((obj) => obj.leadTypeId === leadTypeId) &&
    !defaultLeadControls?.some((obj) => obj.leadControlId === leadControlId);

  useEffect(() => {
    setValue("teamAssigned", teamIdsToTreeList(teamIds, teamLinear));
    setValue("isDefault", isDefault);
  }, [teamIds, isDefault]);

  const teamTreeList = teamListBuilderForLeadControls(
    teamHierarchy,
    leadControls?.teamLeadControls,
    leadControlId,
    leadTypeId
  );

  if (sidePanelState.data === null) return <></>;
  return (
    <div
      className="flex flex-column flex-start h-full"
      data-testid="team-assignment-lead-control-view"
    >
      <div
        className={`flex h-3rem w-full justify-content-between align-items-center`}
        style={{ borderBottom: "1px solid var(--N60)" }}
      >
        <Text color="var(--text-primary)" type="heading" className="ml-5">
          {t(leadControlName)}
        </Text>
        <CrossIcon
          data-testid="cross-icon"
          onClick={() => {
            setSidePanelState({ state: false, data: null });
          }}
          style={{ cursor: "pointer", fill: "var(--icon-primary)" }}
          className="mr-3"
        />
      </div>
      <div className="h-full overflow-y-auto">
        <div
          className={`flex flex-column justify-content-between h-full py-5 lg:mx-4 md:mx-3 sm: mx-2`}
        >
          <div className="flex flex-column flex-start gap-4 w-full">
            <LsqSwitch
              control={control}
              label={t(leadControlsConstants.SET_AS_DEFAULT_LEAD_CONTROL)}
              switchName="isDefault"
              disabled={isDefaultSwitchDisabled}
            />
            {!isDefaultEnabled && (
              <TeamAssignmentMultiselectTreeDropdown
                control={control}
                errors={errors}
                fieldName="teamAssigned"
                teamHierarchy={teamHierarchy}
                teamTreeList={teamTreeList}
              />
            )}
          </div>
          <div
            className={`flex w-full align-items-center justify-content-end gap-2`}
          >
            <CustomButton
              onClick={handleSubmit(onSaveClicked)}
              varient="filled"
              label="Save"
              disabled={isSaveDisabled()}
              data-testid="team-assignment-save-btn"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsAssignedLeadControls;
