import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import useToast from "../../hooks/useToast";
import { useForm } from "react-hook-form";
import LsqSwitch from "../../components/LsqSwitch";
import CustomInput from "../../components/CustomInput";
import PublishView from "../../components/PublishView";
import {
  teamIdsToTreeList,
  teamListBuilderForLeadControls,
} from "../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import { ReactComponent as Dashborad } from "../../assets/svgIcons/dashboard_filled.svg";
import {
  leadControlsConstants,
  placeholderConstants,
  toastMessageConstants,
  globalConstants,
} from "../../constants/globalConstant";
import { hierarchyToLinear } from "../../utils/globalUtils";
import TeamAssignmentMultiselectTreeDropdown from "../../components/TeamAssignmentMultiselectTreeDropdown";
import {
  addToastMessageFromLeadControls,
  publishLeadControl,
} from "../../reducers/leadControlsSlice";

export default function PublishLeadControl({ publishState, setPublishState }) {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessageFromLeadControls);
  const [published, setPublished] = useState(false);
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const { orgCode } = useSelector((state) => state.homepage.user);
  const { publish } = useSelector((state) => state.leadControls);
  const { leadControls } = useSelector((state) => state.leadControlsAssignment);
  const { isPublishLoading } = publish;
  const defaultLeadControls = leadControls?.defaultLeadControls;
  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);
  const teamIds = publishState.data?.teamIds;
  const leadControlName = publishState.data?.leadControlName;
  const leadControlId = publishState.data?.leadControlId;
  const isDefault = publishState.data?.isDefault;
  const leadTypeId = publishState.data?.leadTypeId;

  const defaultValues = {
    projectTitle: leadControlName,
    isDefault: false,
    teamAssigned: {},
  };

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    getValues,
  } = useForm({ defaultValues });

  const isDefaultEnabled = watch("isDefault");
  const watchTeamAssigned = watch("teamAssigned");

  const isPublishDisabled = () => {
    if (isPublishLoading) 
      return true;

    const isDisabled = !!((isDefaultEnabled === false && Object.keys(watchTeamAssigned).length === 0))
    return isDisabled;
  };

  const onDismissPublishView = () => {
    setPublishState({ state: false, data: null });
  };

  const isPublishClicked = (data) => {
    const currentTeamAssigned = {
      ...data,
    };
    if (isDefaultEnabled) {
      currentTeamAssigned.teamAssigned = {};
    }
    const leadControlUpdatedName = getValues("projectTitle");
    dispatch(
      publishLeadControl({
        leadControlId: leadControlId,
        postBody: {
          addTeams: isDefaultEnabled
            ? [orgCode]
            : Object.keys(currentTeamAssigned.teamAssigned),
          name: leadControlUpdatedName,
        },
      })
    ).then(({ type, payload }) => {
      if (type.includes(globalConstants.REJECTED)) {
        const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
        toast.error(message);
      } else {
        setPublished(true);
        setTimeout(() => {
          setPublishState({ state: false, data: null });
        }, 2000);
      }
    });
  };

  useEffect(() => {
    setValue("teamAssigned", teamIdsToTreeList(teamIds, teamLinear));
    setValue("isDefault", isDefault);
  }, [teamIds, isDefault]);

  const teamTreeList = teamListBuilderForLeadControls(
    teamHierarchy,
    leadControls?.teamLeadControls,
    leadControlId,
    leadTypeId
  );

  const isDefaultSwitchDisabled =
    defaultLeadControls?.some((obj) => obj.leadTypeId === leadTypeId) &&
    !defaultLeadControls?.some((obj) => obj.leadControlId === leadControlId);

  return (
    <PublishView
      published={published}
      publishViewIcon={<Dashborad />}
      publishViewTitle={leadControlsConstants.PUBLISH_LEAD_CONTROL}
      publishSuccessBoxIcon={<Dashborad />}
      publishSuccessBoxTitle={getValues("projectTitle")}
      publishSuccessBoxMessage={
        leadControlsConstants.LEAD_CONTROL_PUBLISHED_SUCCESSFULLY
      }
      onPublishClicked={handleSubmit(isPublishClicked)}
      isPublishDisabled={isPublishDisabled}
      onDismissPublishView={onDismissPublishView}
    >
      <CustomInput
        fieldName="projectTitle"
        errors={errors}
        control={control}
        label={leadControlsConstants.ENTER_LEAD_CONTROL_TITLE}
        placeholder={placeholderConstants.TYPE_HERE}
        maxLength={30}
        data-testid="project-title"
        isMandatory
      />
      <div className="flex flex-column flex-start gap-4 w-full">
        <LsqSwitch
          control={control}
          label={leadControlsConstants.SET_AS_DEFAULT_LEAD_CONTROL}
          switchName="isDefault"
          disabled={isDefaultSwitchDisabled}
        />
        {!isDefaultEnabled && (
          <TeamAssignmentMultiselectTreeDropdown
            control={control}
            errors={errors}
            fieldName="teamAssigned"
            teamHierarchy={teamHierarchy}
            teamTreeList={teamTreeList}
          />
        )}
      </div>
    </PublishView>
  );
}
