import React, { useState } from "react";
import { useSelector } from "react-redux";
import { casaWebConstants } from "../../../../constants/globalConstant";
import Text from "../../../../components/Text";
import CustomInputV2 from "../../../../components/CustomInputV2";
import { ReactComponent as SearchIcon } from "../../../../assets/svgIcons/search.svg";
import { ReactComponent as Add } from "../../../../assets/svgIcons/lsq_add.svg";
import LsqItemTile from "../../../../components/LsqItemTile/LsqItemTile";
import { Tooltip } from "primereact/tooltip";
import { Skeleton } from "primereact/skeleton";
import SingleCounterPreview from "../../CasaWebWidgets/CounterWidget/SingleCounterPreview";
import ProgressBarPreview from "../../CasaWebWidgets/ProgressBarWidget/ProgressBarPreview";
import SpeedoMeterPreview from "../../CasaWebWidgets/SpeedoMeterWidget/SpeedoMeterPreview";
import WebViewPreview from "../../CasaWebWidgets/WebViewWidget/WebViewPreview";
import BannerPreview from "../../CasaWebWidgets/BannerWidget/BannerPreview";
import CalendarPreview from "../../CasaWebWidgets/CalanderWidget/calander";
import styles from "./styles.module.css";

// Mapping of widget IDs to components
const widgetComponents = {
  singlecounter: SingleCounterPreview,
  progressbar: ProgressBarPreview,
  speedometer: SpeedoMeterPreview,
  webview: WebViewPreview,
  banner: BannerPreview,
  calendarview: CalendarPreview,
};

const WidgetCategory = ({
  title,
  widgets,
  isWidgetsLoading,
  addWidgetToRightPanel,
}) => {
  const widgetsToRender = widgets?.filter(
    (widget) => widget?.id?.toLowerCase() !== "cover"
  );

  return (
    <div className="mt-3">
      <Text type="T4" color="var(--N400)" className="mb-2">
        {title}
      </Text>
      {isWidgetsLoading ? (
        <Skeleton
          width="100%"
          borderRadius="0.3rem"
          style={{ backgroundColor: "var(--B100)" }}
        />
      ) : (
        <div className="flex flex-column" style={{ gap: 4 }}>
          {widgetsToRender?.map((widget, index) => {
            const WidgetPreviewComponent = widgetComponents[widget.id];
            return (
              <div key={index} id={widget.id} className={styles.widgetNameWrapper}>
                <LsqItemTile
                  key={index}
                  label={widget?.title}
                  hasTileImage
                  tileImageName={`lsq_${widget.id}`}
                  hasTileActionImg
                  tileActionImg={
                    <div
                      className={`flex border-round p-1 ${styles.addIconWrapper}`}
                    >
                      <Add fill="var(--N400)" />
                    </div>
                  }
                  id={widget.id}
                  onTileClick={() => {
                    addWidgetToRightPanel(widget, index);
                  }}
                />
                <Tooltip
                  position="right"
                  left="40px"
                  target={`#${widget?.id}`}
                  className="custom-tooltip"
                >
                  <div
                    className="widget-preview"
                    style={{
                      position: "relative",
                      maxHeight: "400px",
                      padding: "12px",
                      borderRadius: "5px",
                    }}
                  >
                    <Text type="T3" color="var(--N200)">
                      Sample Preview
                    </Text>
                    <div
                      className="flex mt-1"
                      style={{
                        minWidth: "20rem",
                        height: "auto",
                        maxHeight: "300px",
                        overflow: "scroll",
                        border: "1px solid var(--N20)",
                        borderRadius: "12px",
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      {WidgetPreviewComponent ? (
                        <WidgetPreviewComponent />
                      ) : (
                        <div>No Preview Available</div>
                      )}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        left: "-15px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        width: "0",
                        height: "0",
                        borderTop: "15px solid transparent",
                        borderBottom: "15px solid transparent",
                        borderRight: "15px solid #fff",
                      }}
                    ></div>
                  </div>
                </Tooltip>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default function WidgetLeftPanelComponent({ addWidgetToRightPanel }) {
  const [searchTerm, setSearchTerm] = useState("");
  const { widgets, isWidgetsLoading } = useSelector(
    (state) => state.webHomepage
  );

  const filteredWidgets = widgets.filter((widget) =>
    widget.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const categories = [
    {
      title: casaWebConstants.COUNTERS,
      widgets: filteredWidgets.filter(
        (widget) => widget.category === casaWebConstants.COUNTERS
      ),
    },
    {
      title: casaWebConstants.LISTS,
      widgets: filteredWidgets.filter(
        (widget) => widget.category === casaWebConstants.LISTS
      ),
    },
    {
      title: casaWebConstants.CUSTOM,
      widgets: filteredWidgets.filter(
        (widget) => widget.category === casaWebConstants.CUSTOM
      ),
    },
  ];

  return (
    <div className="flex flex-column flex-start h-full p-1">
      <Text type="T2B" color="var(--N400)" className="mb-2">
        {casaWebConstants.WIDGETLIBRARY}
      </Text>
      <CustomInputV2
        style={{ height: "2rem", width: "100%" }}
        placeholder={casaWebConstants.TYPE_TO_SEARCH}
        LeftIcon={SearchIcon}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="flex flex-column" style={{ gap: 8 }}>
        {categories.map(({ title, widgets }) => (
          <WidgetCategory
            key={title}
            title={title}
            widgets={widgets}
            isWidgetsLoading={isWidgetsLoading}
            addWidgetToRightPanel={addWidgetToRightPanel}
          />
        ))}
      </div>
    </div>
  );
}
