import React from 'react';
import DragSvgIcon from "../../../../assets/svgIcons/drag_filled.svg";

const DragHandler = ({ onMouseEnter, onMouseLeave }) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className='draggable-handle'
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)  scaleX(-1)', // Center the handle vertically
        left: '-14px',
        backgroundColor: 'transparent',
        cursor: 'grab',
        zIndex: 101,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1px', // Adjust padding if needed
      }}
    >
      <img src={DragSvgIcon} alt="Drag Icon" style={{width: 24, transform: "scale(0.8)"}}/>
    </div>
  );
};

export default DragHandler;
