import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowSidePanel,
} from "../../../../reducers/homePageSlice.js";
import DynamicSVG from '../../CasaWebWidgets/DynamicSVGComponent/dynamicSVG.js';
import Text from "../../../../components/Text";
import { ReactComponent as Back } from "../../../../assets/svgIcons/arrow.svg";
import CustomButton from "../../../../components/CustomButton/index.js";
import { ReactComponent as TeamsActive } from "../../../../assets/svgIcons/lsq_teams_active.svg";
import { ReactComponent as PublishIcon } from "../../../../assets/svgIcons/publish.svg";
import { ReactComponent as SavedIcon } from "../../../../assets/svgIcons/lsq_ok.svg";
import { ReactComponent as Hamburger } from "../../../../assets/svgIcons/Menu.svg";
import { ReactComponent as DropdownIcon } from "../../../../assets/svgIcons/lsq_chevron_down_filled.svg";
import { LsqTextEditor } from "../../../../components/LsqTextEditor/index.js";
import CustomSplitButton from "../../../../components/CustomSplitButton/index.js";
import LsqSwitchV2 from "../../../../components/LsqSwitchV2/index.js";
import { unpublishWebHomePage,setVisiblePanel,apiGetWebDashboards,changeSelectedWidgetLanguage,apiGetAllTeamsWebDashboards,apiGetAllWebDashboards, apiGetCloneWebDashboards, publishCloneWebHomePage, updateRenderLayoutConfig, apiDeleteCloneWebHomepage, apiUpdateWebHomepage, resetWebHomepage} from "../../../../reducers/webHomepageSlice.js";
import {
  hierarchyToLinear,
  webDashboardsToTeamMapping,
} from "../../../../utils/globalUtils.js";
import { casaConstants, casaWebConstants,leadControlsConstants,ROUTES_PATH } from "../../../../constants/globalConstant.js";
import cloneDeep from "lodash.clonedeep";
import LsqProgressSpinner from "../../../../components/LsqProgressSpinner/index.js";
import deleteIcon from "../../../../assets/svgIcons/delete.svg"
import infoIcon from "../../../../assets/svgIcons/info_icon_warning.svg"
import './WebHomepageTopBar.css'
import alertIcon from "../../../../assets/svgIcons/alert.svg"
import Modal from "../../../../components/Modal/index.js";
import ConfirmDialog from "../../../../components/Modal/ConfirmDialog.js";
import { tabItemsForPublishedTemplate } from "../../../../utils/casaWebUtils.js";
import ToggleTab from "../../../../components/ToggleTab/index.js";
import { uploadThumbnail } from "../../TeamAssignment/PublishWebHomepage.js";


export default function WebHomepageTopBar({ control, setPublishState, setLayoutUnsavedChnages, webPreviewMode, onWebPreviewModeChange,layoutUnsavedChnages,toggleSidePanel }) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    selectedWidgetLanguage,
    selectedWebHomepage,
    teamWebDashboards,
    publish,
    coverPageData,
    layout
  } = useSelector((state) => state.webHomepage);
  const { orgCode } = useSelector((state) => state.homepage.user);
  const teamsHierarchy = useSelector((state) => state.homepage.teams);
  const { renderLayoutConfig } = useSelector(state => state.webHomepage);
  const { cloneDashboardId, id: publishedDashboardId } = useSelector(state => state.webHomepage.selectedWebHomepage);
  const cloneDashboardData = useSelector(state => state.webHomepage.cloneDashboardData);
  
  const [modalType, setModalType] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const tabItems = {
    CURRENTLY_PUBLISHED: tabItemsForPublishedTemplate[0],
    UNPUBLISHED_CHANGES: tabItemsForPublishedTemplate[1]
  }

  const currentTab = renderLayoutConfig === casaWebConstants.INITIAL ? tabItems.CURRENTLY_PUBLISHED : tabItems.UNPUBLISHED_CHANGES;

  const [activeTab, setActiveTab] = useState(currentTab);

  const onTabChange = (tab) => {
    const layout = tab.key === casaWebConstants.PUBLISHED ? casaWebConstants.INITIAL : casaWebConstants.CLONE;

    dispatch(updateRenderLayoutConfig(layout))
    setActiveTab(tab);
    setLayoutUnsavedChnages(false);
  };

  const onDiscardChanges = () => {
    setModalType(casaWebConstants.DISCARD_CHANGES);
    setModalVisible(true);
  }

  const onConfirmDiscardChanges = async () => {
    await dispatch(apiDeleteCloneWebHomepage(publishedDashboardId));
    const currentDashboardInfo = JSON.parse(localStorage.getItem("currentDashboardInfo"));
    currentDashboardInfo.cloneDashboardId = null;
    localStorage.setItem("currentDashboardInfo", JSON.stringify(currentDashboardInfo));
    setModalType(null);
    setModalVisible(false);
    onWebPreviewModeChange(true);
    dispatch(updateRenderLayoutConfig(casaWebConstants.INITIAL));
  };

  useEffect(() => {
    if(renderLayoutConfig === "clone")
      setActiveTab(tabItems.UNPUBLISHED_CHANGES)
  }, [renderLayoutConfig])

 
  const defaultDashboards = teamWebDashboards[orgCode] || [];
  const cloneTeamWebDashboard = cloneDeep(teamWebDashboards);
  delete cloneTeamWebDashboard[orgCode];
  const teamDashboards = cloneTeamWebDashboard;
  const selectedHomepageId = selectedWebHomepage?.id;
  const teamsLinear = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  const dashboardsToTeamCountMapping = useMemo(() => {
    return webDashboardsToTeamMapping(teamDashboards, teamsLinear);
  }, [teamDashboards, teamsLinear]);

  const unpublishWebpage = async () => {
    dispatch(
      unpublishWebHomePage(selectedWebHomepage?.id)
    ).then(async()=>{
      uploadThumbnail(orgCode, publishedDashboardId);
      setModalVisible(false);
      onWebPreviewModeChange(false);
      dispatch(updateRenderLayoutConfig("initial"));
      await dispatch(apiGetAllTeamsWebDashboards())
      await  dispatch(apiGetAllWebDashboards())
    })
    .catch(() => {
      setModalVisible(false);
    });

  }

  const getPublishAdditionalOptions = () => {
    const options = publish.isPublished === "published"
      ? [
          {
            label: casaWebConstants.UNPUBLISH,
            command: () => {
              setModalVisible(true);
              setModalType(casaWebConstants.UNPUBLISH)
            }
          },
        ]
      : [];
    return options;
  };

  const assignTeamsData = () => {
    dispatch(setVisiblePanel(casaWebConstants.RIGHT));
    setPublishState({
      state: true,
      data: {
        isDefault: defaultDashboards?.includes(selectedHomepageId),
        teamIds: dashboardsToTeamCountMapping[selectedHomepageId] || [],
        selectedHomepageId,
      },
    });
  };

  const onPublishClick = async () => {
    if (publish.isPublished === "published" && cloneDashboardId) {
      const updatedLayout = layout;
      const postBody = {
        layout: {
          widgets: updatedLayout,
          coverPage: {
            heading: coverPageData?.heading,
            description: coverPageData?.description,
            customRadio: coverPageData?.customRadio,
            selectedColor: coverPageData?.selectedColor,
            image: coverPageData?.customRadio === casaWebConstants.IMAGE ? coverPageData?.image : null 
          },
        },
      };

    
      await dispatch(apiUpdateWebHomepage({ dashboardId: cloneDashboardId, postBody }));
      setLayoutUnsavedChnages(false)

      await dispatch(publishCloneWebHomePage({dashboardId: publishedDashboardId, postBody}))

      uploadThumbnail(orgCode, publishedDashboardId);
      dispatch(setShowSidePanel(true));

      // Navigate after 4 seconds, so that user can see success message
      setTimeout(() => {
        navigate(ROUTES_PATH.webDashboards);
        dispatch(setVisiblePanel(casaWebConstants.LEFT));
        dispatch(changeSelectedWidgetLanguage());
      }, 4000)
    }
    if (publish.isPublished === "published") {
      return;
    }
    assignTeamsData();
  };

  const getPublishButtonIcon = () => {
    if (publish.isPublishLoading)
      return <LsqProgressSpinner strokeWidth={3} strokeColor="white" />;
    if (publish.isPublished ==="unpublished") 
      return <PublishIcon />;
    if (cloneDashboardId && !webPreviewMode) 
      return <></>;
    return <SavedIcon fill="white" />;
  };

  const getPublishButtonLabel = () => {
    if (publish.isPublishLoading || publish.isPublished !=="published" || (cloneDashboardId && !webPreviewMode))
      return casaWebConstants.PUBLISH;
    return "Published";
  };

  const homepageNameSaveHandler = (text) => {
    const postBody = {
      name: text?.trim(),
    };

    const dashboardId = renderLayoutConfig === "clone" && cloneDashboardId ? cloneDashboardId : publishedDashboardId;

    dispatch(
      apiUpdateWebHomepage({ dashboardId, postBody })
    );
  };

  const getTeamsButtonLabel = () => {
    return defaultDashboards?.includes(selectedHomepageId)
      ? "Default"
      : `${dashboardsToTeamCountMapping[selectedHomepageId]?.length ?? ""} Teams`;
  };
  const saveWebLayout = async () => {
      const updatedLayout = layout;
        const postBody = {
          layout: {
            widgets: updatedLayout,
            coverPage: {
              heading: coverPageData?.heading,
              description: coverPageData?.description,
              customRadio: coverPageData?.customRadio,
              image: coverPageData?.customRadio === casaWebConstants.IMAGE ? coverPageData?.image : null, 
              selectedColor: coverPageData?.selectedColor,
            },
          },
        };

      
      const dashboardId = renderLayoutConfig === "clone" && cloneDashboardId ? cloneDashboardId : publishedDashboardId;

      await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
      setLayoutUnsavedChnages(false)

      if(renderLayoutConfig === "clone" && cloneDashboardId){
        await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
        dispatch(updateRenderLayoutConfig("clone"));
      }
      else {
        await dispatch(apiGetWebDashboards());
      }

      dispatch(setVisiblePanel(casaWebConstants.LEFT));
      dispatch(changeSelectedWidgetLanguage());
    
  };
  const onBackClicked = () => {
    navigate(ROUTES_PATH.webDashboards);
    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    dispatch(changeSelectedWidgetLanguage());
    dispatch(resetWebHomepage())
  };
  return (
    <div className={`flex w-full align-items-center ${styles["casa-web-top-bar"]}`}>
    <div
      className={`flex align-items-center justify-content-center h-full`}
      style={{ width: "4rem" }}
    >
      <div
        className={`flex cursor-pointer p-2 ${styles["hamburger-icon"]}`}
        onClick={toggleSidePanel}
      >
        <Hamburger />
      </div>
    </div>
    <div className="flex justify-content-between align-items-center w-full">
      <div className={`flex relative align-items-center gap-1 `}>
       <div className={` ${styles["hamburger-icon"]} ${styles.backButton}`}>
        <CustomButton
          varient="text"
          icon={<Back />}
          label=""
          onClick={onBackClicked}
          data-testid="scaffold-back-btn"
        /></div>
        <LsqTextEditor
          displayText={selectedWebHomepage?.name || casaWebConstants.HOMEPAGE}
          onSaveHandler={homepageNameSaveHandler}
          editIconStyle={{ width: "1.3rem" }}
          width='max-w-12rem'
        />
        <div>

        </div>

          {cloneDashboardId && cloneDashboardData?.createdAt !== cloneDashboardData?.updatedAt && !webPreviewMode && <div className={styles.reviewChangeButtonWrapper}>
          
              <img src={infoIcon} alt={"info icon"} />
              <div>
                <div>{casaWebConstants.UNPUBLISHED_CHANGES}</div>
                <div>{casaWebConstants.UNPUBLISHED_CHANGES_DESCRIPTION}</div>
              </div>
           
          </div>}

       
         {layoutUnsavedChnages && !webPreviewMode && <div className="flex align-items-center gap-1 saved-changes">
              <DynamicSVG iconName={'unsynced'}  color={'#757575'} width='25px' height='25px' style={{ marginRight: '5px' }} />
              <Text type="T4B" color="#101010" > <span className="font-light">Unsaved changes</span> </Text>
        </div>}
      </div>
      <div
        className={`flex justify-content-end gap-2 align-items-center ${styles["box-animation"]} ${
          selectedWidgetLanguage ? styles["fade-out"] : styles["fade-in"]
        }  ${styles.buttonWrapper}`}
      >

          {cloneDashboardId && cloneDashboardData?.createdAt !== cloneDashboardData?.updatedAt && webPreviewMode &&
            <div className={styles.actionWrapper}>
              <div className={styles.tabsWrapper}>
                <ToggleTab
                  tabs={tabItemsForPublishedTemplate}
                  activeTab={activeTab}
                  setActiveTab={onTabChange}
                />
              </div>

              <div className={styles.deleteWrapper} onClick={onDiscardChanges}>
                <img src={deleteIcon} alt="Delete Icon" data-testid="delete-edited-version" title="Discard Edited Version" />
              </div>
            </div>
          }

        <div className={`flex p-1 px-2 box-border ${styles.previewButton}`}>
          <LsqSwitchV2
            control={control}
            switchName="webPreview"
            disabled={(publish.isPublished === casaWebConstants.PUBLISHED) && webPreviewMode && cloneDashboardData?.createdAt === cloneDashboardData?.updatedAt ? true : false}
            label={leadControlsConstants.PREVIEW_MODE}
            onChange={(event) => onWebPreviewModeChange(event.target.value, true)}
          />
        </div>
        {layout.length > 0 && publish.isPublished !== casaConstants.DRAFT && (
          <CustomButton
            varient="outline"
            label={getTeamsButtonLabel()}
            onClick={assignTeamsData}
            data-testid="lead-controls-teams"
            icon={<TeamsActive width={16} fill="var(--N400)"/>}
            badge={<i className="pi pi pi-angle-down"/>}
            badgeClassName='team-assignment-badge ml-0'
            disabled={webPreviewMode}
          />
        )}
        {layout.length > 0 && (
          <CustomButton
            varient="outline"
            disabled={!layoutUnsavedChnages || webPreviewMode}
            label={layoutUnsavedChnages?'Save':'Saved'}
            onClick={saveWebLayout}
            data-testid="lead-controls-save"
          />
        )}
        {layout.length > 0 && (
          <div className={styles.publishButton}>
            <CustomSplitButton
            disabled={webPreviewMode && publish.isPublished === casaWebConstants.PUBLISHED}
            varient="filled"
            onClick={onPublishClick}
            icon={getPublishButtonIcon()}
            label={getPublishButtonLabel()}
            id="publish-btn"
            additionalOptions={getPublishAdditionalOptions()}
            dropdownIcon={<DropdownIcon></DropdownIcon>}
          />
          </div>
        )}
      </div>
    </div>

    <Modal state={modalVisible && modalType === casaWebConstants.UNPUBLISH}>
        <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <ConfirmDialog
              setState={(data) => {
                setModalType(null);
                setModalVisible(data.state)
              }}
              clickListener={unpublishWebpage}
              illustration={<img src={alertIcon} alt="alert icon" style={{ transform: "translateY(10px)" }} />}
              buttonLabel="Confirm"
              displayText={
                <>
                  <Text type="heading" className="capitalize" color="#0A1F43">
                    Confirm Unpublish
                  </Text>
                  <Text type="sub-heading" color="#0A1F43">
                    Are you sure you want to unpublish this file? If you proceed, all changes made to the published version will be discarded.
                  </Text>
                </>
              }
            // clickListener={dialogClickListener}
            />
          </div>
        </div>
    </Modal>

    <Modal state={modalVisible && modalType === casaWebConstants.DISCARD_CHANGES}>
        <div className="modal-wrapper" onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <ConfirmDialog
              setState={(data) => setModalVisible(data.state)}
              clickListener={onConfirmDiscardChanges}
              illustration={<img src={alertIcon} alt="alert icon" style={{ transform: "translateY(10px)" }} />}
              buttonLabel="Delete"
              displayText={
                <>
                  <Text type="heading" className="capitalize" color="#0A1F43">
                    {casaWebConstants.DISCARD_CHANGES}
                  </Text>
                  <Text type="sub-heading" color="#0A1F43">
                    {casaWebConstants.DISCARD_CHANGES_MESSAGE}
                  </Text>
                </>
              }
            // clickListener={dialogClickListener}
            />
          </div>
        </div>
      </Modal>
  </div>
  
  );
}
