import React, { useEffect, useMemo } from "react";
import Text from "../../../components/Text";
import {
  ROUTES_PATH,
  casaWebConstants,
  globalConstantValues,
  globalConstants,
  toastMessageConstants,
} from "../../../constants/globalConstant";
import cloneDeep from "lodash.clonedeep";
import useToast from "../../../hooks/useToast";
import CustomButton from "../../../components/CustomButton";
import LsqSwitch from "../../../components/LsqSwitch";
import { useForm } from "react-hook-form";
import TeamAssignmentMultiselectTreeDropdown from "../../../components/TeamAssignmentMultiselectTreeDropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  teamIdsToTreeList,
  teamListBuilderForWebHomepage,
} from "../../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import { hierarchyToLinear } from "../../../utils/globalUtils";
import { generateTeamAssignmentWebHomepagePostData } from "../../../utils/casaUtils";
import {
  addToastMessageFromWebHomepage,
  changeSelectedWidgetLanguage,
  publishWebHomePage,
  setVisiblePanel,
  updateWebDashboardTeamAssignment,
} from "../../../reducers/webHomepageSlice";
import html2canvas from "html2canvas";
import { API_WEB_UPLOAD_URL } from "../../../api/webLaunchBarConfigurationServices";
import { API_IMAGE_UPLOAD } from "../../../api/configurationScreenServices";
import { useNavigate } from "react-router-dom";


export const uploadThumbnail = async (orgCode, dashboardId) => {
  const previewPane = document.getElementById("web-homepage-preview");
  let img;
  await html2canvas(previewPane, { backgroundColor: "rgba(0,0,0,0)" }).then(
    (canvas) => {
      img = canvas.toDataURL("image/webp");
    }
  );
  const uploadURLResponse = await API_WEB_UPLOAD_URL(
    `key=${orgCode}/casa/thumbnail/${dashboardId}&featureName=THUMBNAIL`
  );
  const { url, fields } = uploadURLResponse.data;
  const response = await fetch(img);
  const blob = await response.blob();
  const file = new File([blob], "thumbnail", { type: blob.type });
  API_IMAGE_UPLOAD(url, fields, file);
};

export default function PublishWebHomepage({ publishState, setPublishState }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toast = useToast(addToastMessageFromWebHomepage);
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const { orgCode } = useSelector((state) => state.homepage.user);
  const { teamWebDashboards, publish, selectedWebHomepage } = useSelector(
    (state) => state.webHomepage
  );
  const defaultHomepages = teamWebDashboards[orgCode] || [];
  const cloneTeamWebDashboard = cloneDeep(teamWebDashboards);
  delete cloneTeamWebDashboard[orgCode];
  const teamDashboards = cloneTeamWebDashboard;
  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);

  const teamIds = publishState.data?.teamIds;
  const dashboardId = publishState.data?.selectedHomepageId;
  const isDefault = publishState.data?.isDefault;

  const defaultValues = {
    isDefault: false,
    teamAssigned: {},
  };
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });

  const isDefaultEnabled = watch("isDefault");
  const watchTeamAssigned = watch("teamAssigned");

  const closePublishPanel = () => {
    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    setPublishState({ state: false, data: null });
  };

  const isPublishDisabled = () => {
    const isDisabled = !!(isDefaultEnabled === false && Object.keys(watchTeamAssigned).length === 0)
    return isDisabled;
  };

  const isPublishClicked = (data) => {
    const existingTeamAssigned = {
      isDefault: publishState.data?.isDefault,
      teamAssigned: teamIds,
    };
    const currentTeamAssigned = {
      ...data,
    };
    if (isDefaultEnabled) {
      currentTeamAssigned.teamAssigned = {};
    }
    const teamAssigmentPostData = generateTeamAssignmentWebHomepagePostData(
      existingTeamAssigned,
      currentTeamAssigned,
      orgCode
    );
    if (publish.isPublished === "published") {
      dispatch(
        updateWebDashboardTeamAssignment({
          dashboardId,
          postBody: teamAssigmentPostData,
        })
      ).then(({ type, payload }) => {
        if (type.includes(globalConstants.REJECTED)) {
          const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
          toast.error(message);
        } else uploadThumbnail(orgCode, dashboardId);
      });
    } else {
      dispatch(
        publishWebHomePage({
          postBody: {
            addTeams: teamAssigmentPostData.addTeams,
          },
        })
      ).then(({ type, payload }) => {
        if (type.includes(globalConstants.REJECTED)) {
          const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
          toast.error(message);
        } else {
          uploadThumbnail(orgCode, dashboardId);

          // Navigate after 4 seconds, so that user can see success message
          setTimeout(()=>{
            navigate(ROUTES_PATH.webDashboards);
            dispatch(setVisiblePanel(casaWebConstants.LEFT));
            dispatch(changeSelectedWidgetLanguage());
          }, 4000)
        }
        
      });
    }
    closePublishPanel();
  };

  const teamTreeList = teamListBuilderForWebHomepage(
    teamHierarchy,
    teamDashboards,
    dashboardId
  );

  const isDefaultSwitchDisabled =
    defaultHomepages?.length >=
      globalConstantValues.MAX_NUMBER_OF_WEB_TEAM_DASHBOARD &&
    !defaultHomepages?.includes(dashboardId);

  useEffect(() => {
    setValue("teamAssigned", teamIdsToTreeList(teamIds, teamLinear));
    setValue("isDefault", isDefault);
  }, [teamIds]);
  return (
    <div
      className="flex h-full p-3 gap-2 flex-column"
      style={{ minHeight: "90vh" }}
    >
      <Text type="T1B" color="var(--N400)">
        {selectedWebHomepage.cloneDashboardId
          ? casaWebConstants.UPDATE_TEAMS
          : casaWebConstants.PUBLISH_HOME
        }
      </Text>
      <div className="flex h-full flex-column gap-2">
        <LsqSwitch
          control={control}
          label={casaWebConstants.SET_AS_DEFULT}
          switchName="isDefault"
          disabled={isDefaultSwitchDisabled}
        />
        {!isDefaultEnabled && (
          <TeamAssignmentMultiselectTreeDropdown
            control={control}
            errors={errors}
            fieldName="teamAssigned"
            teamHierarchy={teamHierarchy}
            teamTreeList={teamTreeList}
            filterBy="filterLabel"
          />
        )}
      </div>
      <div className="flex justify-content-end gap-2 w-11 p-2 align-items-center fixed bottom-0">
        <CustomButton
          varient="outline"
          label={casaWebConstants.CANCEL}
          onClick={closePublishPanel}
        />
        <CustomButton
          varient="filled"
          label={selectedWebHomepage.cloneDashboardId
            ? casaWebConstants.UPDATE
            : casaWebConstants.PUBLISH
          }
          disabled={isPublishDisabled()}
          onClick={handleSubmit(isPublishClicked)}
        />
      </div>
    </div>
  );
}
