import React from "react";
import { Tag } from "primereact/tag";
import Text from "../../../../../components/Text";
import "./helper.css";
import { getClassName } from "./../../../../../utils/stringUtils";
import {
  ROUTES_PATH,
  menuBuilderConstants,
  views,
} from "../../../../../constants/globalConstant";
import { ReactComponent as Edit } from "../../../../../assets/svgIcons/lsq_edit.svg";
import {
  formatDateTime,
} from "../../../../../utils/globalUtils";
import {
  apiCloneWebLauncehBar,
  apiDeleteWebLaunchBar,
  unpublishWebLaunchBar,
  apiGetWebLaunchBar
} from "../../../../../reducers/webLaunchbarSlice";
import { changeSelectedHomepage } from "../../../../../reducers/homePageSlice";

export const viewsMap = {
  [views.launchBar]: {
    title: "Launchbars",
    placeHolder: "Search  Launchbars",
    id: "id",
    edit: {
      navigate: ROUTES_PATH.launchBarHomePage,
      dispatchs: [],
    },
    create: {
      navigate: ROUTES_PATH.launchBarHomePage,
    },
    apis: {
      clone: apiCloneWebLauncehBar,
      delete: apiDeleteWebLaunchBar,
      unpublish: unpublishWebLaunchBar,
    },
  },
};

const statusTemplate = (value) => {
  return (
    <Tag className={getClassName("status-template", value.status)}>
      <Text type="T4B" className="capitalize">
        {value.status}
      </Text>
    </Tag>
  );
};

const lastModifiedTemplate = (value, sortState) => {
  const date =
    sortState[0] === menuBuilderConstants.RECENTLY_CREATED
      ? value.createdAt
      : value.updatedAt;
  return formatDateTime(date).format("HH:mm | D MMM YYYY");
};

const teamsTemplate = (
  value,
  defaulId,
  teamsCountMapping,
  onTeamCountClicked
) => {
  const id = value.id;
  let teams = "";
  if ( (id != defaulId && !teamsCountMapping[id])) return <></>;
  else
    teams =
      id == defaulId ? "Default" : `${teamsCountMapping[id].length} Teams`;
  return (
    <div
      role="team-count"
      onClick={() => onTeamCountClicked(value)}
      className="flex cursor-pointer"
    >
      <Tag style={{ background: "#E2ECF9" }}>
        <Text type="T4B" color="#015CCD">
          {teams}
        </Text>
      </Tag>
    </div>
  );
};
const editLaunchbar=(id,navigate,dispatch)=>{
  dispatch(changeSelectedHomepage(id));
  dispatch(apiGetWebLaunchBar(id))
  .then((response) => {
    const dashboardData = response?.payload;
    if (dashboardData) {
      localStorage.setItem('currentLaunchBarInfo', JSON.stringify(dashboardData));
      navigate(ROUTES_PATH.launchBarHomePage);
    }
  })
  .catch((error) => {
    console.error('Error getting dashboard data:', error);
  });
}
const editTemplate = (value, view, dispatch, navigate) => {
  const viewObj = viewsMap[view];
  const id = value[viewObj.id];
  return (
    <div
      className={`flex cursor-pointer`}
      data-testid="menu-edit-btn"
      onClick={() => {
        editLaunchbar(id,navigate,dispatch)
      }}
    >
      <Edit />
    </div>
  );
};

export { statusTemplate, lastModifiedTemplate, teamsTemplate, editTemplate };
