import React from 'react';
import Draggable from 'react-draggable';
import { ReactComponent as DragIndicator } from "../../../assets/svgIcons/drag_indicator.svg";

const AddButton = ({ iconSrc, label, showLabel }) => (
  <a href="#" className="add-button">
    <img src={`/images/launcherIcons/${iconSrc}.svg`} alt={label} className="icon" />
    {showLabel && <span className="label">{label}</span>}
  </a>
);

const DynamicAddBar = ({ items }) => {
  const showLabels = items?.length <= 5;

  return (
    <Draggable>
      <div className="add-bar-container z-4">
        {items?.length > 0 && (
          <div className={`add-bar ${!showLabels ? 'icons-only' : ''}`}>
            <DragIndicator
              className="pi pi-drag white"
              style={{ height:24,width:24, fontSize: '1.2rem', color: '#ccc', marginRight: '30px' }}
            ></DragIndicator>
            {items.map((item) => (
              <AddButton
                key={item?.id}
                iconSrc={item?.icon?.imgName}
                label={item.displayName}
                showLabel={showLabels}
              />
            ))}
          </div>
        )}
        <style jsx>{`
          .add-bar-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 50%;
            cursor: move; /* Indicate that the element is draggable */
          }

          .add-bar {
            display: flex;
            align-items: center;
            background-color: var(--N600);
            border-radius: 12px;
            padding: 8px 16px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          }

          .add-button {
            display: flex;
            align-items: center;
            color: #ffffff;
            font-size: 13px;
            font-weight: 500;
            margin-right: 24px;
            text-decoration: none;
          }

          .add-button:last-child {
            margin-right: 0;
          }

          .add-button .icon {
            margin-right: 8px;
            width: 30px;
            height: 30px;
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
          }

          .add-button:hover {
            opacity: 0.8;
          }

          .icons-only .add-button {
            margin-right: 16px;
          }

          .icons-only .add-button .icon {
            margin-right: 0;
          }
        `}</style>
      </div>
    </Draggable>
  );
};

export default DynamicAddBar;
