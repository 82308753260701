import React from "react";
import 'primeflex/primeflex.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import DynamicSVG from '../DynamicSVGComponent/dynamicSVG';

const SpeedoMeterPreview = ({ widgetInformation = {}, onWidgetEdit, current = 102, total = 250 }) => {
  const percentage = (current / total) * 100;
  const { config } = widgetInformation;

  return (
    <div 
      onClick={onWidgetEdit} 
      className="bg-white relative w-full h-full  p-3 flex flex-column justify-content-between draggable-handle"
      style={{
        '--base-size': 'min(100vw, 100vh)',
        '--icon-size': 'calc(var(--base-size) * 0.03)',
        '--progress-size': 'calc(var(--base-size) * 0.15)',
        '--main-text-size': 'calc(var(--base-size) * 0.035)',
        '--sub-text-size': 'calc(var(--base-size) * 0.015)',
        '--bottom-text-size': 'calc(var(--base-size) * 0.022)',
      }}
    >
      <div className="flex justify-content-end right-0 mr-3 absolute">
        <DynamicSVG 
          iconName={config?.icon?.imgName}  
          color={'#2F3237'}
          width="24px" 
          height="24px" 
          opacity={.3}
        />
      </div>
      <div className="flex-grow-1 flex align-items-center justify-content-center relative">
        <div 
          style={{ 
            width: 'var(--progress-size)', 
          }}
        >
          <CircularProgressbar
            value={percentage}
            maxValue={100}
            circleRatio={0.75}
            strokeWidth={4}
            styles={buildStyles({
              rotation: 0.625,
              strokeLinecap: "round",
              pathColor: config?.setThresholds?'#f6b042':'var(--N400)',
              trailColor: '#e0e0e0',
              pathTransitionDuration: 0.5,

            })}
          />
          <div 
            className="absolute top-0 left-0 w-full h-full flex flex-column justify-content-center align-items-center">
            <span style={{ fontSize: 'var(--main-text-size)' }} className="font-bold text-gray-800">
              {config?.count || current}
            </span>
            <div style={{ fontSize: 'var(--sub-text-size)' }} className="text-gray-500">
              {config?.maxCount ? `Out of ${config.maxCount}` : `Out of ${total}`}
            </div>
          </div>
        </div>
      </div>
      <div className="pb-3 overflow-ellipsis absolute bottom-0 left-50" style={{transform: 'translateX(-50%)'}} >
        <div  style={{color: "var(--N600)", fontSize: "14px"}} className="font-medium text-start overflow-ellipsis" title={config?.name}>
          {config?.name || "Speedometer"}
        </div>
      </div>
    </div>
  );
};

export default SpeedoMeterPreview;