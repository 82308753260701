import React, { useEffect, useMemo } from "react";
import Text from "../../../components/Text";
import {
  casaWebConstants,
  globalConstantValues,
  globalConstants,
  toastMessageConstants,
} from "../../../constants/globalConstant";
import cloneDeep from "lodash.clonedeep";
import useToast from "../../../hooks/useToast";
import CustomButton from "../../../components/CustomButton";
import LsqSwitch from "../../../components/LsqSwitch";
import { useForm } from "react-hook-form";
import TeamAssignmentMultiselectTreeDropdown from "../../../components/TeamAssignmentMultiselectTreeDropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  teamIdsToTreeList,
  teamListBuilderForMenuBuilder,
} from "../../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";
import { hierarchyToLinear } from "../../../utils/globalUtils";
import { generateTeamAssignmentWebHomepagePostData } from "../../../utils/casaUtils";
import {
  addToastMessageFromWebHomepage,
  setVisiblePanel,
} from "../../../reducers/webHomepageSlice";
import { publishWebLaunchBar,updateLaunchBarTeamAssignment,apiGetAllTeamsWebLaunchBars, apiGetAllWebLaunchBars} from "../../../reducers/webLaunchbarSlice";

export default function PublishLaunchBar({ publishState, setPublishState,updateLaunchBarData }) {
  const dispatch = useDispatch();
  const toast = useToast(addToastMessageFromWebHomepage);
  const teamHierarchy = useSelector((state) => state.homepage.teams);
  const { orgCode } = useSelector((state) => state.homepage.user);

  const {
    teamLaunchBars,
    publish
  } = useSelector((state) => state.webLaunchBar);
  const defaultHomepages = teamLaunchBars[orgCode] || [];
  const cloneTeamWebDashboard = cloneDeep(teamLaunchBars);
  delete cloneTeamWebDashboard[orgCode];
  const teamDashboards = cloneTeamWebDashboard;
  const teamLinear = useMemo(() => {
    return hierarchyToLinear(teamHierarchy);
  }, [teamHierarchy]);

  const teamIds = publishState.data?.teamIds;
  const dashboardId = publishState.data?.selectedHomepageId;
  const isDefault = publishState.data?.isDefault;

  const defaultValues = {
    isDefault: false,
    teamAssigned: {},
  };
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm({ defaultValues });

  const isDefaultEnabled = watch("isDefault");
  const watchTeamAssigned = watch("teamAssigned");

  const closePublishPanel = () => {
    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    setPublishState({ state: false, data: null });
  };

  const isPublishDisabled = () => {
    const disabled = !!(isDefaultEnabled === false && Object.keys(watchTeamAssigned).length === 0);
    return disabled;
  };

  const isPublishClicked = (data) => {
    const existingTeamAssigned = {
      isDefault: publishState.data?.isDefault,
      teamAssigned: teamIds,
    };
    const currentTeamAssigned = {
      ...data,
    };
    if (isDefaultEnabled) {
      currentTeamAssigned.teamAssigned = {};
    }
    const teamAssigmentPostData = generateTeamAssignmentWebHomepagePostData(
      existingTeamAssigned,
      currentTeamAssigned,
      orgCode
    );
    if (publish.isPublished === "published") {
      dispatch(
        updateLaunchBarTeamAssignment({
          dashboardId,
          postBody: teamAssigmentPostData,
        })
      ).then(async({ type, payload }) => {
        await dispatch(apiGetAllTeamsWebLaunchBars());
        await  dispatch(apiGetAllWebLaunchBars())
        await updateLaunchBarData();
        if (type.includes(globalConstants.REJECTED)) {
          const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
          toast.error(message);
        }
      });
    } else {
      dispatch(
        publishWebLaunchBar({
          dashboardId,
          postBody: {
            addTeams: teamAssigmentPostData.addTeams,
          },
        })
      ).then(async({ type, payload }) => {
        await dispatch(apiGetAllTeamsWebLaunchBars());
        await  dispatch(apiGetAllWebLaunchBars())
        await updateLaunchBarData();
        if (type.includes(globalConstants.REJECTED)) {
          const message = payload || toastMessageConstants.SOMETHING_WENT_WRONG;
          toast.error(message);
        }
      });
    }
    closePublishPanel();
  };

  const teamTreeList = teamListBuilderForMenuBuilder(
    teamHierarchy,
    teamDashboards,
    dashboardId,
    'launchbar'
  );
  const isDefaultSwitchDisabled =
    defaultHomepages?.length >=
      globalConstantValues.MAX_NUMBER_OF_DEFAULT_LUUNCHBAR &&
    !defaultHomepages?.includes(dashboardId);

  useEffect(() => {
    setValue("teamAssigned", teamIdsToTreeList(teamIds, teamLinear));
    setValue("isDefault", isDefault);
  }, [teamIds]);
  return (
    <div className="flex h-full p-2 gap-2 flex-column" >
      <Text type="T1B" color="var(--N400)">
        {casaWebConstants.PUBLISH_HOME}
      </Text>
      <div className="flex h-full flex-column gap-2">
        <LsqSwitch
          control={control}
          label={casaWebConstants.SET_AS_DEFULT}
          switchName="isDefault"
          disabled={isDefaultSwitchDisabled}
        />
        {!isDefaultEnabled && (
          <TeamAssignmentMultiselectTreeDropdown
            control={control}
            errors={errors}
            fieldName="teamAssigned"
            teamHierarchy={teamHierarchy}
            teamTreeList={teamTreeList}
          />
        )}
      </div>
      <div className="flex justify-content-end gap-2 w-full p-1 align-items-center">
        <CustomButton
          varient="outline"
          label={casaWebConstants.CANCEL}
          onClick={closePublishPanel}
        />
        <CustomButton
          varient="filled"
          label={casaWebConstants.PUBLISH}
          disabled={isPublishDisabled()}
          onClick={handleSubmit(isPublishClicked)}
        />
      </div>
    </div>
  );
}
