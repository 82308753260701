import React, { useRef, useState } from "react";
import { ReactComponent as Edit } from "../../assets/svgIcons/lsq_edit.svg";
import { FileUpload } from "primereact/fileupload";
import { FormErrorMessage } from "../FormErrorMessage";

export default function ImagePreview({
  element,
  imageUploadHandler,
  maxFileSize,
}) {
  const fileRef = useRef();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  return (
    <div
      className="flex flex-column overflow-hidden w-5 border-round relative"
      data-testid="image-preview"
    >
      <div className="flex w-full" style={{ height: "2.5rem" }}>
      <img src={`${element?.objectURL}`} alt="img" className="w-full" />
      </div>
      {showErrorMessage && (
        <FormErrorMessage
          message={`Please upload a file less than ${maxFileSize / 1000000}mb!`}
        />
      )}
      <div className="flex w-full justify-content-end absolute p-1 gap-2">
        <div
          className="flex bg-white border-round cursor-pointer"
          onClick={() => {
            fileRef.current.choose();
          }}
          data-testid="image-edit-btn"
        >
          <Edit />
        </div>
        <FileUpload
          style={{ display: "none" }}
          className="w-5"
          mode="basic"
          name="banner-image"
          accept="image/jpeg,image/png,image/jpg,image/gif"
          maxFileSize={maxFileSize}
          chooseLabel="Add"
          customUpload={true}
          auto
          uploadHandler={(e) => {
            imageUploadHandler(e);
            setShowErrorMessage(false);
          }}
          onValidationFail={() => setShowErrorMessage(true)}
          ref={fileRef}
        />
      </div>
    </div>
  );
}
