import React, { useEffect, useState } from "react";
import { webWidgetTypes } from "../../../../constants/globalEnums";
import SingleCounterPreview from "../../CasaWebWidgets/CounterWidget/SingleCounterPreview";
import ProgressBarPreview from "../../CasaWebWidgets/ProgressBarWidget/ProgressBarPreview";
import SpeedoMeterPreview from "../../CasaWebWidgets/SpeedoMeterWidget/SpeedoMeterPreview";
import {
  casaWebConstants
} from "../../../../constants/globalConstant";
import WebViewPreview from "../../CasaWebWidgets/WebViewWidget/WebViewPreview";
import BannerPreview from "../../CasaWebWidgets/BannerWidget/BannerPreview";
import Calendar from "../../CasaWebWidgets/CalanderWidget/calander";
import DragHandler from './DragHandler';

export default function WidgetPreviewWrapper({ widgetInformation, onWidgetEdit, visiblePanel, webPreviewMode }) {
  const [isHovered, setIsHovered] = useState(false);

  const widgetType = widgetInformation?.widgetType;
  useEffect(()=>{
      if(visiblePanel === casaWebConstants.RIGHT ){
        setIsHovered(false);
      }
  },[visiblePanel])
  const handleMouseEnter = () => {
    if(visiblePanel === casaWebConstants.RIGHT ) return;
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderWidgetPreview = {
    [webWidgetTypes.SINGLE_COUNTER]: (
      <SingleCounterPreview
        widgetInformation={widgetInformation}
        onWidgetEdit={onWidgetEdit}
      />
    ),
    [webWidgetTypes.PROGRESS_BAR]: (
      <ProgressBarPreview
        widgetInformation={widgetInformation}
        onWidgetEdit={onWidgetEdit}
      />
    ),
    [webWidgetTypes.SPEEDO_METER]: (
      <SpeedoMeterPreview
        widgetInformation={widgetInformation}
        onWidgetEdit={onWidgetEdit}
      />
    ),
    [webWidgetTypes.WEB_VIEW]: (
      <WebViewPreview
        widgetInformation={widgetInformation}
        onWidgetEdit={onWidgetEdit}
        visiblePanel={visiblePanel}
      />
    ),
    [webWidgetTypes.BANNER]: (
      <BannerPreview
        widgetInformation={widgetInformation}
        onWidgetEdit={onWidgetEdit}
      />
    ),
    [webWidgetTypes.CALANDER]: (
      <Calendar
        widgetInformation={widgetInformation}
        onWidgetEdit={onWidgetEdit}
      />
    ),
  };

  return (
    <div
      className={`widget-container widget-preview`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered && !webPreviewMode && <DragHandler/>}
      {renderWidgetPreview[widgetType] || <></>}
    </div>
  );
}
