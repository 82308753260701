import React, { useEffect } from "react";
import Text from "../../../../components/Text";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { ReactComponent as Delete } from "../../../../assets/svgIcons/lsq_delete.svg";
import { NoResultsFound } from "../../../../components/NoResultsFound";
import { useForm } from "react-hook-form";
import CustomButton from "../../../../components/CustomButton";
import { ReactComponent as NoWidgetsAdded } from "../../../../assets/illustrations/no_widgets_available.svg";
import FieldComponentsParser from "./FieldComponentsParser";
import {
  casaWebConstants,
  casaWebWidgetsConstants,
  globalConstants,
} from "../../../../constants/globalConstant";
import {
  formatMetaValueByWidgetType,
  getFormDefaultValue,
  getWidgetTitleByWidgetType,
} from "../../../../utils/casaWebUtils";
import {
  apiAddWebWidget,
  apiGetWebDashboards,
  changeSelectedWidgetLanguage,
  deleteWebWidget,
  setVisiblePanel,
  apiUpdateWebHomepage,
  updateCoverPage,
  setDeleteDialog,
  apiUpdateWebWidget,
  resetSelectedWidget,
  apiGetCloneWebDashboards,
} from "../../../../reducers/webHomepageSlice";
import { formatTaskMetaValue } from "../../../../components/TaskTypeMultiselectDropdown/TaskTypeMultiselectDropdownUtils";
import { formatTaskTypes } from "../../../../utils/widgetUtils";
import { fetchImageURL } from "../../../../utils/leadControlsUtils";
import { validateThreshold } from "./helper";

export default function WidgetConfigurationRightPanel() {
  const dispatch = useDispatch();
  const {
    selectedWidgetLanguage,
    widgetMetaData,
    renderLayoutConfig,
    selectedWebHomepage,
    entityMetaData,
    layout,
    coverPageData,
  } = useSelector((state) => state.webHomepage);

  const { cloneDashboardId, id: publishedDashboardId } = selectedWebHomepage;
  const dashboardId =
    renderLayoutConfig === "clone" && cloneDashboardId
      ? cloneDashboardId
      : publishedDashboardId;

  const widgetLanguage =
    widgetMetaData[selectedWidgetLanguage?.widgetType] || {};
  const defalutValues = getFormDefaultValue(widgetLanguage);
  const defaultThresholdsValues = {
    setThresholds: false,
    min: 0,
    max: 100,
    thresholds: { min: 25, max: 75 },
  };
  const combinedDefaultValues = {
    ...defalutValues,
    ...defaultThresholdsValues,
    ...selectedWidgetLanguage?.config,
    tasksToDisplay: {},
    navigateTo: "",
    internalLink: {},
    externalLink: "",
    dynamicForm: {
      entity: "",
      workArea: null,
      form: {},
    },
  };
  const widgetForm = useForm({ defaultValues: combinedDefaultValues });

  useEffect(() => {
    if (selectedWidgetLanguage?.mode === globalConstants.UPDATE) {
      const fields = Object.keys(selectedWidgetLanguage?.config);
      fields.forEach((field) => {
        if (field === "image") {
          fetchImageURL(selectedWidgetLanguage?.config[field]).then(
            (payload) => {
              widgetForm.setValue(field, payload);
            }
          );
        } else {
          widgetForm.setValue(field, selectedWidgetLanguage?.config[field]);
        }
      });

      // Ensure `taskStatus` is set if it exists in the config
      if (selectedWidgetLanguage?.config?.taskStatus) {
        widgetForm.setValue(
          "taskStatus",
          selectedWidgetLanguage.config.taskStatus
        );
      }
    }
  }, [selectedWidgetLanguage]);

  useEffect(() => {
    if (selectedWidgetLanguage?.widgetType !== "Cover") return;
    const subscription = widgetForm.watch((value) => { 
      if(value?.customRadio === "color" && !value?.selectedColor){
        dispatch(updateCoverPage({ ...coverPageData, ...value, selectedColor: "#000000" }));
      }
      else {
        dispatch(updateCoverPage({ ...coverPageData, ...value }));
      }
    });

    return () => subscription.unsubscribe();
  }, [widgetForm.watch, coverPageData]);

  const editWidgetConfig = async (formData) => {
    if (
      formData?.tasksToDisplay &&
      Object.keys(formData?.tasksToDisplay)?.length
    ) {
      delete formData.taskTypes;
      const taskTypes = formatTaskMetaValue(
        formData.tasksToDisplay,
        formatTaskTypes(entityMetaData?.tasks?.taskTypes)
      );
      delete formData.tasksToDisplay;
      formData["taskTypes"] = taskTypes;
    }

    if(selectedWidgetLanguage.widgetType !== "Cover"){
      await dispatch(
        apiUpdateWebWidget({
          id: selectedWidgetLanguage?.id,
          payload: formData,
          dashboardId,
        })
      );
    }

    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    dispatch(changeSelectedWidgetLanguage());
    dispatch(resetSelectedWidget());
  };

  const onSubmit = async (formData) => {
    if (!validateThreshold(widgetForm, formData)) return;
    if (
      formData.name === "Cover Page" &&
      formData.customRadio === "image" &&
      ((!formData?.image || !formData?.image?.imageURL))
    ) {
      widgetForm.setError("customRadio", {
        type: "manual",
        message: "This field cannot be left blank",
      });
      return;
    }
    const { entity } = formData;
    if (entity?.id === "tasks") {
      // Remove the fields smartView and smartViewTab
      delete formData.smartView;
      delete formData.smartViewTab;
      delete formData.teams;
    } else if (entity?.id === "smartviews") {
      // Remove the fields dateFilters, taskStatus, and taskTypes
      delete formData.dateFilters;
      delete formData.taskStatus;
      delete formData.taskTypes;
      delete formData.tasksToDisplay;
    }
    if (!formData?.setThresholds) {
      delete formData.thresholds;
      delete formData.min;
      delete formData.max;
    }
    if (
      formData?.taskStatus?.id !== "completed" &&
      entity?.id !== "smartviews"
    ) {
      delete formData.thresholds;
      delete formData.setThresholds;
      delete formData.min;
      delete formData.max;
    }
    if (selectedWidgetLanguage.mode === "UPDATE") {
      await editWidgetConfig(formData);
      const updatedLayout = layout;
      const postBody = {
        layout: {
          widgets: updatedLayout,
          coverPage: {
            heading: coverPageData?.heading,
            description: coverPageData?.description,
            customRadio: coverPageData?.customRadio,
            image: coverPageData?.customRadio === casaWebConstants.IMAGE ? coverPageData?.image : null,
            selectedColor: coverPageData?.selectedColor,
          },
        },
      };

      await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));

      if (renderLayoutConfig === "clone" && cloneDashboardId) {
        await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
      } else {
        await dispatch(apiGetWebDashboards());
      }
      return;
    }

    const { customRadio, selectedColor, ...widgetData } = formData;

    const data = {
      ...widgetData,
    };
    customRadio;
    selectedColor;

    try {
      if (selectedWidgetLanguage.widgetType !== "Cover") {
        if (
          data?.tasksToDisplay &&
          Object.values(data?.tasksToDisplay)?.length
        ) {
          const taskTypes = formatTaskMetaValue(
            data.tasksToDisplay,
            formatTaskTypes(entityMetaData?.tasks?.taskTypes)
          );
          delete data.tasksToDisplay;
          data["taskTypes"] = taskTypes;
        }
        const postData = formatMetaValueByWidgetType(
          selectedWidgetLanguage.widgetType,
          data
        );

        const addResult = await dispatch(
          apiAddWebWidget({
            dashboardId,
            payload: postData,
          })
        );

        const widgetData = unwrapResult(addResult);
        const updatedLayout = layout.map((widget) =>
          widget.id === casaWebWidgetsConstants.DEMO_WIDGET
            ? { ...widget, id: widgetData.id }
            : widget
        );

        // Build the coverPage object based on the customRadio value
        const coverPage = {
          heading: coverPageData?.heading,
          description: coverPageData?.description,
          customRadio: coverPageData?.customRadio,
          image: coverPageData?.customRadio === casaWebConstants.IMAGE ? coverPageData?.image : null,
        };
        if (coverPageData?.customRadio === "color") {
          coverPage.selectedColor = coverPageData?.selectedColor;
        }

        const postBody = {
          layout: {
            widgets: updatedLayout,
            coverPage,
          },
        };

        await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
      } else {
        const updatedLayout = layout;

        // Build the coverPage object based on the customRadio value
        const coverPage = {
          heading: coverPageData?.heading,
          description: coverPageData?.description,
          customRadio: coverPageData?.customRadio,
          image: coverPageData?.customRadio === casaWebConstants.IMAGE ? coverPageData?.image : null,
        };

        if (coverPageData?.customRadio === "color") {
          coverPage.selectedColor = coverPageData?.selectedColor;
        }

        const postBody = {
          layout: {
            widgets: updatedLayout,
            coverPage,
          },
        };

        await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
      }

      if (renderLayoutConfig === "clone" && cloneDashboardId) {
        await dispatch(apiGetCloneWebDashboards(cloneDashboardId));
      } else {
        await dispatch(apiGetWebDashboards());
      }

      dispatch(setVisiblePanel(casaWebConstants.LEFT));
      dispatch(changeSelectedWidgetLanguage());
      dispatch(resetSelectedWidget());
    } catch (error) {
      console.error("Failed to save widget or update homepage:", error);
    }
  };

  const discardWidgetChanges = async () => {
    dispatch(deleteWebWidget({ id: casaWebWidgetsConstants.DEMO_WIDGET }));
    dispatch(changeSelectedWidgetLanguage());
    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    if (renderLayoutConfig === "clone" && cloneDashboardId) {
      await dispatch(apiGetCloneWebDashboards(cloneDashboardId))
    }
    else {
      await dispatch(apiGetWebDashboards());
    }
  };

  const onDelete = () => {
    if (selectedWidgetLanguage.mode !== "UPDATE") discardWidgetChanges();
    else
      dispatch(
        setDeleteDialog({
          state: true,
          widgetId: selectedWidgetLanguage?.id,
        })
      );
  };
  return (
    <div
      className={`flex flex-column flex-start gap-2 overflow-scroll h-full`}
      style={{padding:"16px"}}
    >
      <div className="flex justify-content-between align-items-center">
        <Text type="T1B" color="var( --text-primary)">
          {getWidgetTitleByWidgetType(selectedWidgetLanguage?.widgetType)}
        </Text>
        <div
          className={`flex cursor-pointer align-items-center justify-content-center border-round`}
          style={{ background: "var(--R100)",height:32,width:32 }}
          data-testid="configuration-page-delete-btn"
          onClick={onDelete}
        >
          <Delete fill="var(--R700)" style={{ width: "1.2rem" }} />
        </div>
      </div>
      <div className={`flex flex-column h-full overflow-hidden`}>
        <div className="flex h-full flex-column justify-content-between gap-1 overflow-hidden right-panel-form">
          <div
            className={`flex flex-column gap-2  pt-2 mb-5 overflow-y-scroll h-full`}
          >
            {!widgetLanguage?.length && (
              <NoResultsFound
                Illustration={NoWidgetsAdded}
                children={
                  <div>
                    <Text type="T2B" color="var(--N80)">
                      {casaWebConstants.NO_WIDGETS_ADDED}
                    </Text>
                  </div>
                }
              />
            )}

            {widgetLanguage &&
              widgetLanguage.length > 0 &&
              widgetLanguage?.map((field) => {
                return (
                  <FieldComponentsParser
                    key={field.id}
                    field={field}
                    form={widgetForm}
                    coverPageData={coverPageData}
                    widgetType={selectedWidgetLanguage?.widgetType}
                    mode={selectedWidgetLanguage.mode}
                  />
                );
              })}
          </div>
          <div
            className="flex justify-content-end gap-2 w-full fixed bottom-0 right-15 mb-3"
            style={{ right: "20px" }}
          >
            <CustomButton
              label={casaWebConstants.CANCEL}
              varient="outline"
              onClick={discardWidgetChanges}
            />
            <CustomButton
              label={casaWebConstants.SAVE}
              varient="filled"
              onClick={widgetForm.handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
