import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./style.module.css";
import Text from "../Text";
import LsqExpandableTile from "../LsqExpandableTile.js";
import { deleteImagesFromS3 } from "../../utils/bannerUtils.js";
import ImageNavigator from "./ImageNavigator.js";
import {
  globalConstants,
  imageGallerySettingsConstants,
} from "../../constants/globalConstant.js";
import LsqProgressSpinner from "../LsqProgressSpinner/index.js";
import { ReactComponent as LsqRetry } from "../../assets/svgIcons/banner_retry.svg";

export default function ImageGalleryEditor({
  mode,
  imageList,
  imageConfiguration,
  setRemovedImageKeys,
  updateGalleryInPreviewPane,
}) {
  const [updatedElementIndex, setupdatedElementIndex] = useState();
  const [expanded, setExpanded] = useState(null);
  const [edit, setEdit] = useState(false);
  const imageCount = imageList.fields.length;

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    imageList.move(result.source.index, result.destination.index);
    updateGalleryInPreviewPane();
  };

  const onElementEdit = (index, element) => {
    setExpanded(element.id);
    setEdit(true);
    setupdatedElementIndex(index);
    Object.keys(imageList.fields[index]).forEach((key) => {
      if (key === "id") return;
      imageConfiguration.setValue(key, imageList.fields[index][key]);
    });
  };

  const onElementDeleted = (index) => {
    imageList.remove(index);
    imageConfiguration.reset();
    setExpanded();
    updateGalleryInPreviewPane();
    if (mode === globalConstants.CREATE) {
      const imageKeys = [imageList.fields[index].imageKey];
      deleteImagesFromS3(imageKeys);
    } else if (mode === globalConstants.UPDATE) {
      setRemovedImageKeys((prev) => [
        ...prev,
        imageList.fields[index].imageKey,
      ]);
    }
  };

  const getTileImage = (element) => {
    switch (element.uploadStatus) {
      case imageGallerySettingsConstants.PENDING:
        return <LsqProgressSpinner strokeWidth={6} strokeColor="#43536F" />;
      case imageGallerySettingsConstants.FAILED:
        return (
          <LsqRetry
            style={{
              cursor: "pointer",
              width: "1.3rem",
            }}
            data-testid="retry-icon"
          />
        );
      case imageGallerySettingsConstants.SUCCESS:
        return <img src={`${element.imageURL}`} className="w-full" />;
      default:
        return <></>;
    }
  };
  return (
    <div
      className={`flex flex-column gap-2 w-full p-3 overflow-hidden ${styles["image-gallery"]}`}
    >
      <Text type={"T4B"} color="#283A5A">
        {`Add Images (${imageCount}/10)`}
      </Text>
      <div className="flex flex-column  gap-2 overflow-scroll">
        {!expanded && imageCount < 10 && (
          <ImageNavigator
            imageList={imageList}
            imageConfiguration={imageConfiguration}
            updatedElementIndex={updatedElementIndex}
            setupdatedElementIndex={setupdatedElementIndex}
            updateGalleryInPreviewPane={updateGalleryInPreviewPane}
            mode={globalConstants.CREATE}
            edit={edit}
            setEdit={setEdit}
          />
        )}
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable
            direction="vertical"
            type="elements"
            droppableId="element-handler"
          >
            {(provided) => {
              return (
                <div
                  data-testid="elementDragHandlerBox"
                  className="flex flex-column gap-1"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {imageList.fields?.map((element, index) => {
                    return (
                      <Draggable
                        index={index}
                        key={index}
                        draggableId={`image-${index}`}
                        data-testid="draggable"
                      >
                        {(provided) => {
                          return (
                            <div
                              className="flex"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <LsqExpandableTile
                                label={element?.label}
                                dragHandleProps={{
                                  ...provided.dragHandleProps,
                                }}
                                expanded={element.id === expanded}
                                onEdit={() => onElementEdit(index, element)}
                                onDelete={() => onElementDeleted(index)}
                                hasTileImage={true}
                                tileImage={
                                  <div
                                    className={`flex justify-content-center align-items-center ${styles["img-preview-container"]}`}
                                  >
                                    {getTileImage(element)}
                                  </div>
                                }
                                children={
                                  <ImageNavigator
                                    imageList={imageList}
                                    imageConfiguration={imageConfiguration}
                                    updatedElementIndex={updatedElementIndex}
                                    setupdatedElementIndex={
                                      setupdatedElementIndex
                                    }
                                    mode={globalConstants.UPDATE}
                                    closeExpandedPanel={() => setExpanded()}
                                    updateGalleryInPreviewPane={
                                      updateGalleryInPreviewPane
                                    }
                                    edit={edit}
                                    setEdit={setEdit}
                                  />
                                }
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}
