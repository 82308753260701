import React, { useEffect, useState } from 'react';

const DynamicSVG = ({ className, iconName = 'lsq_copy',color, width='20px', height='20px',opacity }) => {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    // Fetch the SVG file from the public folder
    fetch(`/images/launcherIcons/${iconName}.svg`)
      .then(response => response.text())
      .then(svgText => {
        let modifiedSvg = svgText;
        // Apply color replacement only if color is not the default value
        if (color) {
          modifiedSvg = modifiedSvg.replace(/fill="[^"]*"/g, `fill="${color}"`);
        }

        // Remove width and height attributes
        modifiedSvg = modifiedSvg.replace(/(width|height)="[^"]*"/g, '');

        // Inline style width and height
        modifiedSvg = modifiedSvg.replace('<svg', `<svg style="width: ${width}; height: ${height};"`);

        setSvgContent(modifiedSvg);
      })
      .catch(error => console.error('Error loading SVG:', error));
  }, [iconName, color, width, height]);
  const iconOpacity = iconName === 'lsq_copy' ? 1 : opacity;
  return (
    <div
      className={`cursor-pointer svg-icon ${className}`}
      style={{ width, height,opacity:iconOpacity}}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

export default DynamicSVG;
