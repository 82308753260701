import React from "react";
import lsqHelpImage from "../../../../assets/illustrations/lsq_help_url.svg"
import { casaWebConstants } from "../../../../constants/globalConstant";
import "./WebViewPreview.css";
import { ReactComponent as Link } from "./../../../../assets/lsq_external_link.svg";

export default function WebViewPreview({
  widgetInformation = {},
  onWidgetEdit,
}) {
  const { config = {} } = widgetInformation;
  const src = config?.navigateTo || casaWebConstants.LSQ_HELP_URL;
  const title = config?.name;
  return (
    <div
      className={`webview-widget widget-container ${title && "show-frame"}`}
      onClick={onWidgetEdit}
    >
      <button className="navigate-button" aria-label="Open in new window">
        <Link
          color={"white"}
          width="24px"
          height="24px"
        />
      </button>
      <div className="zoom-controls">
        <div>+</div>
        <div>-</div>
      </div>
      {title && (
        <div className="title overflow-ellipsis">
          <label className="overflow-ellipsis" title={title}>
            {title}
          </label>
        </div>
      )}
    
      <div className="frame">
      {!config?.navigateTo
          ? <img src={lsqHelpImage} alt="LSQ Help" />:
        <iframe
          target="_parent"
          src={src}
          width="100%"
          height="100%"
          role="web-view"
          title={title}
        ></iframe>}
        <div className="web-view-wrapper" onClick={onWidgetEdit}></div>
      </div>
    </div>
  );
}
