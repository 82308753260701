import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CustomInput from "../CustomInput";
import CustomButton from "../CustomButton";
import { countLimit } from "../../utils/globalUtils";
import { CustomImageInputV2 } from "../CustomImageInputV2";
import LsqMobileNavigator from "../LsqMobileNavigator";
import {
  globalConstants,
  bannerSettingsConstants,
  imageGallerySettingsConstants,
} from "../../constants/globalConstant";
import {
  API_GET_UPLOAD_URL,
  API_IMAGE_UPLOAD,
} from "../../api/configurationScreenServices";
import ImagePreview from "./ImagePreview";
import { getNavigationData } from "../../reducers/widgetTemplateListSlice";
import getSquareCroppedURL from "../../utils/imageGalleryUtils";

export default function ImageNavigator({
  mode,
  imageList,
  imageConfiguration,
  updatedElementIndex,
  setupdatedElementIndex,
  closeExpandedPanel,
  updateGalleryInPreviewPane,
  edit,
  setEdit,
}) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const watchImage = imageConfiguration.watch(
    bannerSettingsConstants.ORIGINAL_IMAGE
  );
  const { widgetList } = useSelector((state) => state.widgetTemplateList);
  const [navigationList, setNavigationList] = useState([
    {
      name: "External Link",
      items: [{ id: "ExternalLink", name: "-----" }],
    },
    {
      name: "Internal Navigation",
      items: [],
    },
  ]);

  useEffect(() => {
    let tempNavigationList = [...navigationList];
    if (!widgetList.widgetMetaData?.quicklauncher) {
      dispatch(getNavigationData()).then(({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          const { _widgetMetaData } = payload;
          const navigationListItems = _widgetMetaData?.quicklauncher?.menu;
          tempNavigationList[1].items = navigationListItems;
        }
      });
    } else {
      const navigationListItems = widgetList.widgetMetaData.quicklauncher?.menu;
      tempNavigationList[1].items = navigationListItems;
    }
    setNavigationList(tempNavigationList);
  }, []);

  const setSelectedImage = (img) => {
    imageConfiguration.setValue(bannerSettingsConstants.ORIGINAL_IMAGE, img);
    imageConfiguration.setValue(bannerSettingsConstants.IMAGE_NAME, img.name);
  };

  const imageUploadHandler = (event) => {
    const file = event.files[0];
    const imgFile = {
      objectURL: file.objectURL,
      name: file.name,
      size: file.size,
      file: file.type,
      lastModified: file.lastModified,
    };
    setSelectedImage(imgFile);
    event.options.clear();
  };

  const uploadImageInToBucket = async (imageConfigurationValues, index) => {
    let elementIndex;
    if (index !== undefined) {
      elementIndex = index;
    } else if (updatedElementIndex !== undefined) {
      elementIndex = updatedElementIndex;
    } else {
      elementIndex = imageList.fields.length;
    }
    let element = {
      ...imageConfigurationValues,
      uploadStatus: bannerSettingsConstants.PENDING,
    };
    imageList.update(elementIndex, element);
    try {
      const { imageURL, imageName, imageKey } = imageConfigurationValues;
      const uploadUrlResponse = await API_GET_UPLOAD_URL(imageKey);
      const uploadUrl = uploadUrlResponse.data.url;
      const signedKey = uploadUrlResponse.data.key;
      const uploadAPIFields = uploadUrlResponse.data.fields;
      const res = await fetch(imageURL);
      const blob = await res.blob();
      const file = new File([blob], imageName, { type: blob.type });
      await API_IMAGE_UPLOAD(uploadUrl, uploadAPIFields, file);
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.SUCCESS,
        imageKey: signedKey,
      };
      imageList.update(elementIndex, element);
    } catch (error) {
      element = {
        ...imageConfigurationValues,
        uploadStatus: bannerSettingsConstants.FAILED,
      };
      imageList.update(elementIndex, element);
    }
  };

  const onImageSubmit = async (data) => {
    mode === globalConstants.UPDATE && closeExpandedPanel();
    imageConfiguration.reset();
    const croppedImageURL = await getSquareCroppedURL(
      data.originalImage.objectURL
    );
    const imageConfigurationValues = {
      ...data,
      imageURL: croppedImageURL,
    };
    if (updatedElementIndex !== undefined) {
      imageList.update(updatedElementIndex, imageConfigurationValues);
    } else {
      imageList.append(imageConfigurationValues);
    }
    uploadImageInToBucket(imageConfigurationValues);
    updateGalleryInPreviewPane();
    setupdatedElementIndex();
  };

  return (
    <div className="flex flex-column w-full gap-2 mt-2" data-testid="image-navigator">
      <div className="flex w-full gap-1">
        {watchImage ? (
          <ImagePreview
            element={watchImage}
            imageUploadHandler={imageUploadHandler}
            maxFileSize={countLimit.IMAGE_GALLERY_MAX_FILE_SIZE}
          />
        ) : (
          <CustomImageInputV2
            imageUploadHandler={imageUploadHandler}
            maxFileSize={countLimit.IMAGE_GALLERY_MAX_FILE_SIZE}
          />
        )}
        <CustomInput
          control={imageConfiguration.control}
          errors={imageConfiguration.formState.errors}
          fieldName="label"
          maxLength={30}
          className="lsq-input-bold w-full"
          label={imageGallerySettingsConstants.LABEL}
          isMandatory={false}
          data-testid="label"
          containerStyle={{ width: "100%" }}
        />
      </div>
      <LsqMobileNavigator
        formValues={imageConfiguration}
        mobileNavigationMenu={navigationList}
        isMandatory={true}
        edit={edit}
        setEdit={setEdit}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        mode={edit ? globalConstants.UPDATE : globalConstants.CREATE}
      />
      <div
        className={"flex w-full align-items-center justify-content-end gap-2"}
      >
        <CustomButton
          type="reset"
          label={
            mode === globalConstants.UPDATE
              ? globalConstants.CANCEL
              : globalConstants.RESET
          }
          varient="text"
          onClick={() => {
            imageConfiguration.reset();
            setupdatedElementIndex();
            mode === globalConstants.UPDATE && closeExpandedPanel();
          }}
          data-testid="cancel-btn"
        />
        <CustomButton
          type="submit"
          label={globalConstants.ADD}
          varient="filled"
          onClick={imageConfiguration.handleSubmit(onImageSubmit)}
          disabled={!watchImage}
          data-testid="add-btn"
        />
      </div>
    </div>
  );
}
