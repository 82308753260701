import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../CasaWebHomepage/styles.module.css";
import { getTeams, setShowSidePanel } from "../../../reducers/homePageSlice";
import PublishLaunchBar from "./PublishLaunchBar";
import {
  apiGetAllWebLaunchBars,
  apiGetAllTeamsWebLaunchBars,
  apiGetWebLaunchBar,
} from "../../../reducers/webLaunchbarSlice";
import { IndeterminateLoader } from "../../../components/IndeterminateLoader";
import LaunchBarTopNav from "./LaunchbarTopNav";
import ToastMessage from "../../../components/ToastMessage";
import LaunchBarRightPanel from "./LaunchBarRighPanel";
import DynamicAddBar from "./ToolBar";
import './LaunchBar.css'

export default function LaunchBar() {
  const dispatch = useDispatch();
  const teamsHierarchy = useSelector((state) => state.homepage.teams);
  const { isLaunchBarLoading, toastMessages } =
    useSelector((state) => state.webLaunchBar);
  const [publishState, setPublishState] = useState({
    state: false,
    data: null,
  });
  const [items, setItems] = useState([]);  

  const addItemInLaunchBar = (item) => {
    setItems((prevItems) => {
        if (!Array.isArray(prevItems)) {
            return [item]; 
        }
        return [...prevItems, item];
    });
};


  const updateItemInLaunchBar = (updatedItem, index) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index] = updatedItem;
      return newItems;
    });
  };

  const deleteItemInLaunchBar = (index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (teamsHierarchy?.length === 0) {
      dispatch(getTeams());
    }

    dispatch(setShowSidePanel(false));

    const fetchInitialData = async () => {
      await dispatch(apiGetAllTeamsWebLaunchBars());
      await dispatch(apiGetAllWebLaunchBars());
      const initialDashboardData = await dispatch(apiGetWebLaunchBar());
      setItems(initialDashboardData?.payload?.config || []);
    };

    fetchInitialData();
  }, [dispatch, teamsHierarchy.length]);

  const updateLaunchBarData = async () => {
    const updatedDashboardData = await dispatch(apiGetWebLaunchBar());
    setItems(updatedDashboardData.payload.config || []);
  };
  return (
    <div
      className="launch-bar flex h-screen w-screen align-items-center"
      data-testid="casa-web-view" 
    >
      <div className="toast_container" style={{ top: "2rem" }}>
        <ToastMessage toastMessages={toastMessages} />
      </div>
      <div className="flex flex-column h-full w-full align-items-center overflow-hidden">
        <div className="flex flex-column w-full">
          <LaunchBarTopNav
            setPublishState={setPublishState}
          />
          {isLaunchBarLoading && (
            <IndeterminateLoader
              color="#0A1F43"
              height="0.25rem"
              backgroundColor="#B0B7C2"
            />
          )}
        </div>
        <div className="flex w-full h-full" style={{ background: "var(--N30)" }}>
          <div className="dotted-background flex w-full  m-2 border-round align-items-center justify-content-center">
            {items.length !== 0 && (
              <DynamicAddBar items={items} />
            )}
          </div>
          <div
            className={`flex flex-column w-3 h-full bg-white ${styles["right-panel"]} ${styles["visible-panel"]} `}
            style={{transform: 'none'}}
          >
            {!publishState.state && <LaunchBarRightPanel
              addItemInLaunchBar={addItemInLaunchBar}
              updateItemInLaunchBar={updateItemInLaunchBar}
              deleteItemInLaunchBar={deleteItemInLaunchBar}
              initialConfig={items}
              setItems={setItems}
            />}
            {publishState.state && 
                <PublishLaunchBar
                  publishState={publishState}
                  setPublishState={setPublishState}
                  updateLaunchBarData={updateLaunchBarData}
                />
              }
          </div>
        </div>
      </div>
    </div>
  );
}
