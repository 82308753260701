const hostMap = {
  "fs-dashboard.marketxpander.net": "https://fieldsales.marketxpander.net",
  "fs-dashboard.crm4b2c.com": "https://fieldsales.crm4b2c.com",
  "fs-dashboard.lsq-staging.com": "https://fieldsales.lsq-staging.com",
  "fs-dashboard-in22.leadsquared.com":
    "https://fieldsales-in22.leadsquared.com",
};

const defaultHost = "https://fieldsales-in21.leadsquared.com";
let baseURL = hostMap[window.location.host] || defaultHost;
export default baseURL;
