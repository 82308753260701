import React, { useState } from "react";
import "./index.css";
import Text from "../Text";

const Recommendations = ({ heading, points }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="recommendations-container">
      <div className="header overflow-ellipsis">
        <img src={`/images/tips_outline.svg`} alt={"tips"} />
        <Text type="T5B" color="var(--N400)" className="overflow-ellipsis ml-1">
          {heading}
        </Text>
        <span className="collapse-icon ml-auto" onClick={toggleCollapse}>
          <i className={`pi pi-angle-up ${isCollapsed ? "rotate-180" : ""}`} role="collapse-icon" />
        </span>
      </div>
      <ul className={`points-list ${isCollapsed ? "collapsed" : "expanded"}`}>
        {points.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </div>
  );
};

export default Recommendations;
