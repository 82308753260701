import React, { useEffect, useState } from "react";
import { fetchImageURL } from "../../../../utils/leadControlsUtils";
import "./BannerPreview.css";
export default function BannerPreview({
  widgetInformation = {},
  onWidgetEdit,
}) {
  const { config = {} } = widgetInformation;
  const [imgUrl, setImgUrl] = useState({});

  const setImageInPreview = async () => {
    try {
      if (config?.image?.imageKey) {
        const image = await fetchImageURL(config?.image);
        setImgUrl(image);
      } else {
        setImgUrl(config?.image);
      }
    } catch (error) {
      console.error("Failed to fetch image", error);
      setImgUrl({ imageURL: "/images/banner.png" }); // Set fallback image on error
    }
  };

  useEffect(() => {
    if (config.image) {
      setImageInPreview();
    }
  }, [config]);
  return (
    <div
      className={`banner-container ${config?.navigateTo && "navigate-to"} ${
        config?.showFrame && "show-frame"
      }`}
      role="banner"
      onClick={onWidgetEdit}
    >
      <div className="image-container">
        <img src={`${imgUrl?.imageURL || "/images/banner.png"}`} alt="img" />
      </div>
      {config.name && (
        <div className="banner-name overflow-ellipsis">{config.name}</div>
      )}
    </div>
  );
}
