import React from "react";
import CustomInputV2 from "../../../../../components/CustomInputV2";
import CustomButton from "../../../../../components/CustomButton";
import Text from "../../../../../components/Text";
import { Tag } from "primereact/tag";
import { menuBuilderConstants } from "../../../../../constants/globalConstant";
import { ReactComponent as SearchIcon } from "../../../../../assets/svgIcons/search.svg";
import { ReactComponent as Add } from "../../../../../assets/svgIcons/add.svg";
import { viewsMap } from "../helper/helper";

const Header = ({ searchState, list = [], view, navigateCreateNewDashBoard }) => {
  const { title, placeHolder } = viewsMap[view] || {};
  const totalMenuCreated = list.length;

  return (
    <div className="flex w-full align-items-center justify-content-between p-3">
      <div className="flex align-items-center gap-1">
        {title && (
          <>
            <Text type="T1B" color="var(--text-primary)">
              {title}
            </Text>
            <Tag style={{ background: "#fff",border:"1px solid #ccc" }}>
              <Text type="T4B" color="#43536F">
              <b>{totalMenuCreated}</b> <span className="font-light">created</span>
              </Text>
            </Tag>
          </>
        )}
      </div>
      <div className="flex align-items-center gap-1">
        <CustomInputV2
          style={{ width: "20rem", height: "2.25rem" }}
          placeholder={placeHolder}
          LeftIcon={SearchIcon}
          value={searchState[0]}
          onChange={(e) => searchState[1](e.target.value)}
          data-testid="menu-search"
        />
        <CustomButton
          label={menuBuilderConstants.CREATE_NEW}
          varient="filled"
          icon={<Add />}
          style={{ width: "100%", height: "2.25rem" }}
          onClick={()=>  navigateCreateNewDashBoard("Homepages View")}
          data-testid="create-menu-btn"
        />
      </div>
    </div>
  );
};

export default Header;
