import { FileUpload } from "primereact/fileupload";
import "./index.css";
import { FormErrorMessage } from "../FormErrorMessage";
import { useState } from "react";

export const CustomImageInputV2 = ({
  imageUploadHandler,
  maxFileSize,
  isImageInputDisabled = false,
  type='primary'
}) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  return (
    <div data-testid="file-upload" className={type}>
      <div className={`custom-image-input-v2 w-full`}>
        <FileUpload
          mode="basic"
          name="image-input"
          accept="image/jpeg,image/png,image/jpg,image/gif"
          maxFileSize={maxFileSize}
          chooseLabel={
            <div>
              Upload<span className="">*</span>
            </div>
          }
          customUpload={true}
          auto
          uploadHandler={(e) => {
            imageUploadHandler(e);
            setShowErrorMessage(false);
          }}
          onValidationFail={() => {
            setShowErrorMessage(true);
          }}
          data-testid="primary-file-upload"
          disabled={isImageInputDisabled}
        />
      </div>
      {showErrorMessage && (
        <FormErrorMessage
          message={`Please upload a file less than ${maxFileSize / 1000000}mb!`}
        />
      )}
    </div>
  );
};
