import React, { useEffect, useState } from "react";
import Text from "../../../../components/Text";
import styles from "../styles.module.css";
import { API_GET_SIGNED_URL } from "../../../../api/configurationScreenServices";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as NoWidgetsAdded } from "../../../../assets/illustrations/add_widgets.svg";
import { ReactComponent as AddCoverPage } from "../../../../assets/illustrations/add_cover_page.svg";
import { 
  deleteWebWidget, 
  apiDeleteWebWidget,
  resetSelectedWidget,
  setVisiblePanel,
  setDeleteDialog,
  apiUpdateWebHomepage,
  apiGetWebDashboards,
  apiGetCloneWebDashboards,
  changeSelectedWidgetLanguage,
  setSignedUrlApiLoader,
  setCoverPageThumbnail
} from "../../../../reducers/webHomepageSlice";
import { NoResultsFound } from "../../../../components/NoResultsFound";
import { casaWebConstants } from "../../../../constants/globalConstant";
import WidgetPreviewLayout from "../WidgetCentralPanel/WidgetPreviewLayout";
import WebCoverPage from "../../CasaWebWidgets/CoverPageWidget/WebCoverPage";
import ConfirmDialog from "../../../../components/Modal/ConfirmDialog";
import { ReactComponent as DeleteIllustration } from "../../../../assets/illustrations/error.svg";
export default function WebHomepagePreview({ webPreviewMode, addCoverPage, unSavedChanges }) {
  const dispatch = useDispatch();
  const { layout, coverPageData,dialog, visiblePanel, renderLayoutConfig } = useSelector((state) => state.webHomepage);
  const { cloneDashboardId, id: publishedDashboardId } = useSelector(state => state.webHomepage.selectedWebHomepage);
  const [showGridContainer, setShowGridContainer] = useState(false);
  const [imageUrl, setImageUrl] = useState("");

  const dashboardId = renderLayoutConfig === "clone" ? cloneDashboardId : publishedDashboardId;

  useEffect(() => {
    const fetchImageSignedURL = async () => {
      if (coverPageData?.image?.imageKey && coverPageData?.image?.uploadStatus === 'SUCCESS') {
        try {
          dispatch(setCoverPageThumbnail(null));
          dispatch(setSignedUrlApiLoader(true));
          const { data } = await API_GET_SIGNED_URL(coverPageData.image.imageKey);
          dispatch(setSignedUrlApiLoader(false));
          if (data?.url) {           
            setImageUrl(data.url);
            dispatch(setCoverPageThumbnail(data.url));
          }
        } catch (error) {
          console.error('Error fetching signed URL:', error);
        }
      }
    };
    fetchImageSignedURL();
  }, [coverPageData]);

  const getBackgroundColor = () => {
    if(!coverPageData?.selectedColor)
      return 'var(--N20)'
    if (webPreviewMode) {
      return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${coverPageData?.selectedColor || "black"}` ;
    }
    return `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), ${coverPageData?.selectedColor || "#F6F6F7"}` ;
  };

  const editCoverPage = () => {
    !webPreviewMode && addCoverPage();
  };
  const deleteCoverPage=async()=>{
    const coverPage = {
      heading: '',
      description: '',
      customRadio: '',
      selectedColor:'',
      image: null,
    };
    const postBody = {
      layout: {
        widgets: layout,
        coverPage,
      },
    };
     await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));
     
     if(cloneDashboardId){
      await dispatch(apiGetCloneWebDashboards(cloneDashboardId))
    }
     else {
      await dispatch(apiGetWebDashboards());
    }
  }
  const confirmDialogHandler = async () => {
    dispatch(setDeleteDialog({ state: false, widgetId: null }));
    dispatch(setVisiblePanel(casaWebConstants.LEFT));
    dispatch(resetSelectedWidget());
    if(dialog.widgetId === 'demoWidget'){
      deleteCoverPage()
    } else {
      dispatch(deleteWebWidget({ id: dialog.widgetId }));

      await dispatch(apiDeleteWebWidget({
        dashboardId,
        widgetId: dialog.widgetId
      }));

      dispatch(changeSelectedWidgetLanguage(null));

      const updatedLayout = layout.filter(
        (widget) => widget.id !== dialog.widgetId
      );

      const postBody = {
        layout: {
          widgets: updatedLayout,
          coverPage: coverPageData
        },
      };

      await dispatch(apiUpdateWebHomepage({ dashboardId, postBody }));

      if (cloneDashboardId) {
        await dispatch(apiGetCloneWebDashboards(cloneDashboardId))
      }
      else {
        await dispatch(apiGetWebDashboards());
      }
    }
  };
  const dialogClickListener = () => {
    confirmDialogHandler();
  }
  return (
    <div className={`flex w-full h-full relative ${webPreviewMode ? 'webPreviewMode' : ''}`} >
      {showGridContainer && !webPreviewMode && (
        <div className={styles["grid-container"]}></div>
      )}
      <div className="w-full" id="web-homepage-preview">
        <div
          className={`${styles["cover-page"]} relative`}
          onClick={editCoverPage}
          style={{
            background: getBackgroundColor(),
            zIndex: dialog ? 0 : 4,
            height: '150px',
            minHeight: '150px',
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {coverPageData?.customRadio === casaWebConstants.IMAGE && coverPageData?.image?.imageURL && imageUrl &&(
            <img
              src={imageUrl}
              alt="Cover"
              className={styles.coverImageElement}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                position: 'absolute',
                filter: 'brightness(0.7)',
                top: 0,
                left: 0,
              }}
            />
          )}
          <div className={webPreviewMode ? styles.webCoverPageContainerPreview : styles.webCoverPageContainer}>
             <WebCoverPage webPreviewMode={webPreviewMode} coverPageData={coverPageData} />
          </div>

          {!webPreviewMode && (visiblePanel === casaWebConstants.LEFT) && (!coverPageData?.selectedColor&&!coverPageData?.image?.imageURL) && (
            <div 
              className="coverpage absolute w-full h-full" 
              onClick={addCoverPage}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                top: 0,
                left: 0,
                zIndex: 2,
              }}
            >
              <NoResultsFound
                Illustration={AddCoverPage}
                children={
                  <div style={{ textAlign: 'center' }}>
                    <Text type="T5" color="var(--N80)">
                      {casaWebConstants.ADD_COVER_IMAGE}
                    </Text>
                  </div>
                }
              />
            </div>
          )}
        </div>
        <div className="relative" style={{
            width: webPreviewMode ? "80%" : "100%",
            margin: webPreviewMode ? "-30px auto" : "0",
            zIndex: webPreviewMode ? "4" : "0",
            display: 'flex',
            flexDirection: 'column',
            
            minHeight: '100%',
          }}>
          {layout?.length === 0 ? (
            <div className="mt-6">
              <NoResultsFound
                Illustration={NoWidgetsAdded}
                children={
                  <div>
                    <Text type="T2B" color="var(--N80)">
                      {casaWebConstants.NO_WIDGETS_ADDED}
                    </Text>
                  </div>
                }
              /></div>
          ) : (
            <WidgetPreviewLayout webPreviewMode={webPreviewMode} setShowGridContainer={setShowGridContainer} unSavedChanges={unSavedChanges} confirmDialogHandler={dialogClickListener}/>
          )}
        </div>
      </div>
      {dialog.state && (
        <div className="modal-wrapper " onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <ConfirmDialog
              setState={(newState) => dispatch(setDeleteDialog(newState))}
              illustration={<DeleteIllustration/>}
              buttonLabel="Delete"
              displayText={
                <>
                  <Text type="heading" className="capitalize" color="#0A1F43">
                    Delete Widget
                  </Text>
                  <Text type="sub-heading" color="#0A1F43">
                    Are you sure you want to delete this widget? It will be removed from all the teams that are currently assigned to this homepage!
                  </Text>
                </>
              }
              clickListener={dialogClickListener}
            />
          </div>
        </div>
      )}
    </div>
  );
}