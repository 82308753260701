import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as DragIndicator } from "../../assets/svgIcons/drag_indicator.svg";
import { ReactComponent as LsqEdit } from "../../assets/svgIcons/lsq_edit.svg";
import { ReactComponent as LsqClose } from "../../assets/svgIcons/lsq_close.svg";
import styles from "./styles.module.css";
import PropTypes from "prop-types";
import { QuickLauncherIcons } from "../../assets/icons/QuickLauncherIcons";
import Text from "../Text";
import { getClassName } from "../../utils/stringUtils";
import { customFabConstants } from "../../constants/globalConstant";
export default function LauncherElementDragHandler({
  elementList = [],
  onElementEdit,
  remove,
  move,
  updateLauncherInPreviewPane = () => {},
  updatedElementIndex,
  onDeleteItem,
  showSelected=false
}) {
  const onElementDeleted = (index) => {
    remove(index);
    updateLauncherInPreviewPane();
    onDeleteItem(index);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;
    move(result.source.index, result.destination.index);
    updateLauncherInPreviewPane();
  };
  const getElementClass = (index) => {
    let elementClass = styles["drag_element"];
    if (updatedElementIndex !== undefined)
      elementClass =
        updatedElementIndex !== index
          ? styles["drag_element_disabled"]
          : styles["selected_drag_element"];
    return elementClass;
  };

  return (
    <div className="w-12 overflow-auto">
      {showSelected&&<div className="flex justify-content-between px-2 mb-2 align-items-center">
        <Text type="T4" color="var(--text-primary)">
          {customFabConstants.LAUNCHBAR_WIDGET_ADDED}
        </Text>
        <div className="border-round px-2 py-1" style={{background:'var(--N30)'}}>
          <Text type="T4B" color="var(--text-primary)">
            {elementList.length}/10
          </Text>
        </div>
      </div>}
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Droppable
          direction="vertical"
          type="elements"
          droppableId="element-handler"
        >
          {(provided) => {
            return (
              <div
                data-testid="elementDragHandlerBox"
                className="flex flex-column w-full gap-1"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {elementList.map((element, index) => {
                  return (
                    <Draggable
                      index={index}
                      key={element.id}
                      draggableId={element.id}
                      isDragDisabled={updatedElementIndex === index}
                    >
                      {(provided) => {
                        return (
                          <div
                            className={ getClassName('flex flex-row',getElementClass(index))}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className="flex justify-content-center align-items-center"
                              {...provided.dragHandleProps}
                            >
                              <DragIndicator />
                            </div>
                            <div
                              className={`flex flex-1 mx-2 flex-row align-items-center justify-content-between `}
                            >
                              <div className="flex flex-row align-items-center gap-1">
                                <QuickLauncherIcons
                                  style={{
                                    width: "1.5rem",
                                    fill: "red",
                                  }}
                                  icon={element.icon.imgName}
                                />
                                <div className={styles.drag_element_label}>
                                  <Text color="#314361">
                                    {element.displayName}
                                  </Text>
                                </div>
                              </div>
                              <div className="flex flex-row align-items-center gap-1">
                                <LsqEdit
                                  style={{ cursor: "pointer" }}
                                  onClick={() => onElementEdit(index)}
                                  className={`${
                                    updatedElementIndex !== undefined &&
                                    updatedElementIndex === index &&
                                    `hidden`
                                  }`}
                                  data-testid="lsq_edit"
                                />
                                <LsqClose
                                  style={{ cursor: "pointer", fill: "#314361" }}
                                  data-testid={`dragElementClose${index}`}
                                  onClick={() => onElementDeleted(index)}
                                  className={`${
                                    updatedElementIndex !== undefined &&
                                    updatedElementIndex === index &&
                                    `hidden`
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

LauncherElementDragHandler.propTypes = {
  elementList: PropTypes.array,
};
