import axios from "axios";
import { Buffer } from "buffer";
import { redirectToLogin } from "../utils/globalUtils";
import baseURL from "./server";
import { apiTrace } from "../utils/apiTrace";

const axiosInstanceWeb = axios.create({
  baseURL: `${baseURL}/api/casa-service/v1`,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  withCredentials: true
});
if (sessionStorage.getItem("authorization")) {
  const token = sessionStorage.getItem("authorization");
  const decryptedToken = Buffer.from(token, "base64");
  axiosInstanceWeb.defaults.headers.common["authorization"] = decryptedToken;
}
axiosInstanceWeb.interceptors.response.use(
  (response) => {
    if (response.headers?.authorization) {
      const encryptedToken = Buffer.from(response.headers.authorization).toString("base64");
      sessionStorage.setItem("authorization", encryptedToken);
      axiosInstanceWeb.defaults.headers.common["authorization"] = Buffer.from(encryptedToken, "base64");
    }
    apiTrace(response);
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      redirectToLogin()
    }
    return Promise.reject(error);
  }
);
export default axiosInstanceWeb;