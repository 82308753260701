import React from "react";
import { useSelector, useDispatch } from "react-redux";
import ItemsDragHandler from "../../components/ItemsDragHandler";
import { menuBuilderConstants } from "../../constants/globalConstant";
import MenuTab from "./MenuTab";
import ItemAddList from "../../components/ItemAddList";
import { isDefaultMenutab } from "../../utils/menuBuilderUtils";
import { changePublishMenuState } from "../../reducers/menuBuilderSlice";

export default function SideNavigation({
  sideAvailableTabs,
  sideVisibleTabs,
  setCreateNewTab,
  setDialog,
  bottomNavigationTabs,
}) {
  const dispatch = useDispatch();
  const { menuTabs, publish } = useSelector((state) => state.menuBuilder);
  const defaultMenuTabs = menuTabs.defaultTabs;
  const customMenuTabs = menuTabs?.customTabs?.filter((menu) => (!menu?.config?.isSieraLink));
  const onCreateNewButtonClicked = () => {
    setCreateNewTab({ state: true, data: null });
  };

  const onAvailableItemAddClick = (index, availableItem) => {
    sideVisibleTabs.append(availableItem);
    sideAvailableTabs.remove(index);
    publish.isPublished && dispatch(changePublishMenuState(false));
  };

  const onVisibleItemCloseClick = (index, element) => {
    sideVisibleTabs.remove(index);
    if (isDefaultMenutab(defaultMenuTabs, element.tabId))
      sideAvailableTabs.append(element);
    publish.isPublished && dispatch(changePublishMenuState(false));
  };

  const onCustomMenuTabAdd = (_, customTab) => {
    sideVisibleTabs.append(customTab);
    publish.isPublished && dispatch(changePublishMenuState(false));
  };

  const navigationTabs = [...sideVisibleTabs.fields, ...bottomNavigationTabs];

  return (
    <MenuTab
      leftSideLabel={menuBuilderConstants.VISIBLE_MENU_TABS}
      leftSideItems={
        <ItemsDragHandler
          id={"side_navigation"}
          elements={sideVisibleTabs.fields}
          move={sideVisibleTabs.move}
          hasCloseButton={true}
          onCloseButtonClick={onVisibleItemCloseClick}
          onDraggingItem={() =>
            publish.isPublished && dispatch(changePublishMenuState(false))
          }
        />
      }
      rightSideLabel={menuBuilderConstants.AVAILABLE_MENU_TABS}
      rightSideItems={
        <ItemAddList
          id={"side_navigation_available_item"}
          items={sideAvailableTabs.fields}
          onAddItemClick={onAvailableItemAddClick}
        ></ItemAddList>
      }
      navigationTabs={navigationTabs}
      hasCreateNewSection={true}
      customTabs={customMenuTabs}
      onCreateNewButtonClicked={onCreateNewButtonClicked}
      setCreateNewTab={setCreateNewTab}
      onCustomMenuTabAdd={onCustomMenuTabAdd}
      setDialog={setDialog}
      hasCICOConfigurationSection = {true}
    ></MenuTab>
  );
}
