import React, { useEffect, useCallback } from "react";
import RGL, { WidthProvider } from 'react-grid-layout';
import { useSelector, useDispatch } from "react-redux";
import WidgetPreviewWrapper from "./WidgetPreviewWrapper";
import { 
  updateLayout, 
  setSelectedWidgetId,
  changeSelectedWidgetLanguage,
  setVisiblePanel,
  setDeleteDialog
} from "../../../../reducers/webHomepageSlice";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {
  casaWebConstants,
  globalConstants,
  defaultWidgetConfigurations
} from "../../../../constants/globalConstant";

const ReactGridLayout = WidthProvider(RGL);

const WidgetPreviewCentralPanel = ({ className = "layout", rowHeight = 20, webPreviewMode, setShowGridContainer,unSavedChanges }) => {
  const { layout, visiblePanel, selectedWidgetLanguage, currentSavedLayout, selectedWidgetId } = useSelector((state) => state.webHomepage);
  const dispatch = useDispatch();

  useEffect(() => {
    const isLayoutChanged = JSON.stringify(layout) !== JSON.stringify(currentSavedLayout);
    unSavedChanges(isLayoutChanged)
  }, [layout, currentSavedLayout]);

  const handleLayoutChange = useCallback((newLayout) => {
    const updatedLayout = newLayout.map((item) => {
      const widget = layout.find((w) => w.id === item.i);
      return {
        ...item,
        widgetType: widget?.widgetType || "",
        minW: widget?.minW,
        maxW: widget?.maxW,
        minH: widget?.minH,
        maxH: widget?.maxH,
      };
    });
    updatedLayout.forEach((item) => {
      if (item.x + item.w > 16) {
        item.w = 16 - item.x; // Adjust width to stay within bounds
      }
    });
    dispatch(updateLayout(updatedLayout));
  }, [layout, dispatch]);


  const onWidgetEdit = (widget) => {
    if (visiblePanel === casaWebConstants.RIGHT || webPreviewMode) return;
    dispatch(
      changeSelectedWidgetLanguage({
        id: widget?.id,
        widgetType: widget?.widgetType,
        mode: globalConstants.UPDATE,
        config: widget?.config,
      })
    );
    dispatch(setVisiblePanel(casaWebConstants.RIGHT));
    dispatch(setSelectedWidgetId(widget?.id));
  };



  const onWidgetDeleteConfirm = (widgetId) => {
    dispatch(setDeleteDialog({
      state: true,
      widgetId,
    }));
  };



  const handleDragStart = () => {
    setShowGridContainer(true);
  };

  const handleDragStop = () => {
    setShowGridContainer(false);
  };

  const handleResizeStart = () => {
    setShowGridContainer(true);
  };

  const handleResizeStop = () => {
    setShowGridContainer(false);
  };
  const getResizeHandles = (widget) => {
    const config = defaultWidgetConfigurations[widget.widgetType];
    const handles = [];
    
    if (config?.minW !== config?.maxW) {
      handles.push('e');
    }
    if (config?.minH !== config?.maxH) {
      handles.push('s');
    }
    
    return handles;
  };

  return (
    <div className="flex-column px-1">
      <ReactGridLayout
        className={className}
        cols={16}  // Ensure exactly 16 columns are used
        rowHeight={rowHeight}
        layout={layout.map(widget => ({ ...widget, i: widget.id }))}
        onLayoutChange={handleLayoutChange}
        isDraggable={visiblePanel === casaWebConstants.LEFT && !webPreviewMode}
        isResizable={visiblePanel === casaWebConstants.LEFT && !webPreviewMode}
        margin={[15, 20]}
        containerPadding={[5, 10]}  // This should match the padding used in BackgroundGrid
        draggableHandle=".draggable-handle"
        draggableCancel=".non-draggable"
        onDragStart={handleDragStart}
        onDragStop={handleDragStop}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
      >
        {layout && layout.map((widget) => {
          const resizeHandles = getResizeHandles(widget);
          return (
            <div
              key={widget.id}
              style={{filter: 'drop-shadow(0px 1.43px 8.6px rgba(136, 136, 136, 0.18))'
              }}
              className={` border-round-sm bg-white ${(selectedWidgetId === widget.id && selectedWidgetLanguage?.mode === 'UPDATE') || (widget.id === "demoWidget") ? 'widget-selected cursor-pointer' : ''} resizable-widget`} 
              data-grid={{
                x: widget.x,
                y: widget.y,
                w: widget.w,
                h: widget.h,
                minW: widget.minW,
                maxW: widget.maxW,
                minH: widget.minH,
                maxH: widget.maxH,
                resizeHandles: resizeHandles
              }}
            >
              <WidgetPreviewWrapper
                onWidgetDelete={onWidgetDeleteConfirm}
                onWidgetEdit={() => onWidgetEdit(widget)}
                widgetInformation={widget}
                visiblePanel={visiblePanel}
                webPreviewMode={webPreviewMode}
                isSelected={selectedWidgetId === widget.id}
              />
            </div>
          )
        })}
      </ReactGridLayout>
    </div>
  );
};

export default WidgetPreviewCentralPanel;