import React from "react";
import ToggleTab from "../../components/ToggleTab";
import styles from "./styles.module.css";
import { themeConfiguratorTabs } from "../../utils/homepageUtils";
import CustomThemeRadioGroup from "../../components/CustomRadioGroup/CustomThemeRadioGroup";
import CustomButton from "../../components/CustomButton";
import { lsqPreDefinedThemes } from "../../utils/themeUtils";
import {
  themeConstants,
  toastMessageConstants,
} from "../../constants/globalConstant";
import { addToastMessage } from "../../reducers/themeBuilderSlice";
import useToast from "../../hooks/useToast";
import CustomTheme from "./CustomTheme";
import { useForm } from "react-hook-form";
import { getDefalutThemeValues } from "./helper/helper";
import { useSelector } from "react-redux";

export default function ThemeConfigurator({
  activeTab,
  setActiveTab,
  setColorPalette,
}) {
  const toast = useToast(addToastMessage);
  const { theme: currentTheme } = useSelector((state) => state.themeBuilder);
  const theme = useForm({
    defaultValues: getDefalutThemeValues(currentTheme),
  });
  const applyTheme = (palette) => {
    setColorPalette(palette);
    toast.success(toastMessageConstants.THEME_APPLIED_SUCCESSFULLY);
  };

  const onSubmit = (value) => {
    applyTheme(value);
  };

  const onThemeReset = () => {
    theme.reset();
  };
  const onChange = (option = {}) => {
    Object.keys(option).forEach((e) => theme.setValue(e, option[e]));
    theme.clearErrors()
  };

  return (
    <div
      className={`flex flex-column w-full h-full ${styles["theme-configurator"]}`}
    >
      <div
        className={`flex align-items-center justify-content-between w-full py-3`}
      >
        <ToggleTab
          tabs={themeConfiguratorTabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          textType="T4B"
        ></ToggleTab>
      </div>
      <div
        className={`flex flex-column w-full h-full mb-3 p-2 justify-content-between overflow-scroll ${styles["theme-configurator-tab"]}`}
      >
        <div className={`flex flex-column gap-3`}>
          <div
            className={`flex flex-column justify-content-center w-full gap-3 p-2 coach-default-theme`}
          >
            {lsqPreDefinedThemes?.map((option) => {
              return (
                <CustomThemeRadioGroup
                  key={option.name}
                  control={theme.control}
                  option={option}
                  radioName="name"
                  onChange={onChange}
                />
              );
            })}
          </div>
          <CustomTheme
            theme={theme}
            isCustomColorDisabled={
              theme.watch("name") !== themeConstants.CUSTOM
            }
            id="coach-custom-theme"
            data-testid="theme-custom-button"
            onChange={onChange}
          ></CustomTheme>
        </div>
        <div
          className={`flex w-11 py-2 mt-3 align-self-center align-items-center justify-content-end mt-auto gap-2`}
        >
          <CustomButton
            type="reset"
            onClick={onThemeReset}
            varient="text"
            data-testid="theme-reset-button"
            label={themeConstants.RESET}
          />
          <CustomButton
            type="submit"
            varient="filled"
            label={themeConstants.APPLY}
            onClick={theme.handleSubmit(onSubmit)}
            data-testid="theme-apply-button"
            disabled={Object.keys(theme.formState.errors).length > 0}
          />
        </div>
      </div>
    </div>
  );
}
