import React from "react";
import { Controller } from "react-hook-form";
import MultiRangeSlider from "multi-range-slider-react";
import Text from "../../../../components/Text";
import './CustomMultiRangeSlider.css';

export default function LSQMultiRangeSlider({
  control,
  thresholdsName,
  ...props
}) {
  return (
    <Controller
      name={thresholdsName}
      control={control}
      render={({ field }) => {
        return (
          <div>
            <MultiRangeSlider
              data-testid="lsq-multi-range-slider"
              style={{
                border: "none",
                boxShadow: "none",
                padding: "15px 10px",
              }}
              step={1}
              minValue={field?.value?.min ?? 0} // Use 0 as fallback if field.value.min is undefined
              maxValue={field?.value?.max ?? 100} // Use 100 as fallback if field.value.max is undefined
              ruler="false"
              onChange={(e) => {
                field.onChange({ min: e.minValue, max: e.maxValue });
              }}
              barLeftColor="#FB4444"
              barInnerColor="#FFC338"
              barRightColor="#24B12F"
              thumbLeftColor="#FB4444"
              thumbRightColor="#24B12F"
              {...props}
            />
            <div
              className="flex justify-content-between"
              style={{ marginTop: "-10px" }}
            >
              <Text type="T5" color="var(--text-primary)">
                0%
              </Text>
              <Text type="T5" color="var(--text-primary)">
                100%
              </Text>
            </div>
          </div>
        );
      }}
    />
  );
}
