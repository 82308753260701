import React from "react";
import 'primeflex/primeflex.css';
import DynamicSVG from '../DynamicSVGComponent/dynamicSVG';
const SingleCounterPreview = ({ widgetInformation = {},onWidgetEdit}) => {
  const { config } = widgetInformation;
const status=config?.taskStatus?.id||'completed'
  return (
    <div className={`non-draggable bg-white p-3 w-full h-full relative overflow-hidden flex flex-column justify-content-between`} onClick={onWidgetEdit} >
      <div className="flex justify-content-between align-items-start mb-2">
        <div className="flex align-items-baseline">
          <div className="flex align-items-center">
            {config?.setThresholds&&<span className="inline-block mr-2" style={{ width: '8px', height: '8px', backgroundColor: 'green', borderRadius: '50%' }}></span>}
            <span className="text-3xl text-gray-800 font-medium">{420}</span>
          </div>
          {status==='completed'&&<span className="text-sm text-gray-500 ml-1">/400</span>}
        </div>
        <div className="flex align-items-center" style={{position:'absolute',right:"16px",top:"16px"}}>
          {/* <img src={`/images/launcherIcons/${config?.icon?.imgName || 'lsq_email'}.svg`} alt="icon" style={{ width: '30px', height: '30px' }} className="cursor-pointer ml-auto" /> */}
          <DynamicSVG  color={'#2F3237'} iconName={config?.icon?.imgName} width = '24px' height = '24px' opacity={.3}/>
        </div>
      </div>
      <div className="flex justify-content-between align-items-center relative z-1 overflow-ellipsis" >
        <span style={{color: "var(--N600)", fontSize: "14px"}} className="font-medium overflow-ellipsis" title={config?.name}>{config?.name || 'Single counter'}</span>
       
      </div>
    </div>
  );
};

export default SingleCounterPreview;


