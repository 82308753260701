import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  countLimit,
  dashboardsToTeamMapping,
  hierarchyToLinear,
} from "../utils/globalUtils";
import {
  changeSelectedHomepage,
  addToastMessage,
  cloneHomepage,
  apiAddHomepage,
  deleteHomePage,
  updateHomePage,
  updateTeamsDashboards,
} from "../reducers/homePageSlice";
import { defaultColorPaylod } from "../utils/casaWebUtils";
import {apiAddWebHomepage } from '../reducers/webHomepageSlice';
import {apiAddWebLaunchBar} from '../reducers/webLaunchbarSlice'
import useToast from "./useToast";
import analytics from "../utils/analytics";
import {
  globalConstants,
  dashboardCardConstants,
  ROUTES_PATH,
} from "../constants/globalConstant";

function useHomepageCard() {
  const { homepagesById } = useSelector((state) => state.homepage);
  const navigate = useNavigate();
  const toast = useToast(addToastMessage);
  const dispatch = useDispatch();
  const teamsHierarchy = useSelector((state) => state.homepage.teams);
  const teamDashboards = useSelector((state) => state.homepage.teamDashboards);
  const selectedTab = useSelector((state) => state.homepage.selectedTab);

  const teamsLinear = useMemo(() => {
    return hierarchyToLinear(teamsHierarchy);
  }, [teamsHierarchy]);
  const dashboardsToTeamCountMapping = useMemo(() => {
    return dashboardsToTeamMapping(
      teamDashboards?.teamsDashboards,
      teamsLinear
    );
  }, [teamDashboards, teamsLinear]);
  const teams = dashboardsToTeamCountMapping || [];

  const cloneHomepageClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.homepageId;
    if (Object.keys(homepagesById).length < countLimit.HOMEPAGES_COUNT) {
      dispatch(cloneHomepage(homepageId)).then(({ type, payload }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          const clonedHomepage = payload;
          const clonedHomepageId = clonedHomepage.homepageId;
          dispatch(changeSelectedHomepage(clonedHomepageId));
          navigate(ROUTES_PATH.homebuilder);
        }
      });
      analytics.sendEvent(
        `clonenhomepage${sourceClicked && " clicked from " + sourceClicked}`,
        {
          homepageId: homepage.homepageId,
          status: homepage.status,
          homepageName: homepage.homepageName,
        }
      );
    } else {
      toast.warn(
        `Maximum ${countLimit.HOMEPAGES_COUNT} homepages can be added`
      );
    }
  };

  const createHomepageClicked = (sourceClicked = "") => {
    if (Object.keys(homepagesById).length < countLimit.HOMEPAGES_COUNT) {
      dispatch(apiAddHomepage()).then(({ type }) => {
        if (type.includes(globalConstants.FULFILLED)) {
          navigate(ROUTES_PATH.homebuilder);
        }
      });
      const analytics_data = {
        category: "create_new_homepage",
        type: "click",
      };
      analytics.sendEvent(
        `create new homepage ${
          sourceClicked && " clicked from " + sourceClicked
        }`,
        analytics_data
      );
    } else {
      toast.warn(
        `Maximum ${countLimit.HOMEPAGES_COUNT} homepages can be added`
      );
    }
  };

  const deleteHomepageClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.homepageId;
    const postBody = { teamIds: teams[homepageId] ?? [] };
    dispatch(deleteHomePage({ homepageId, postBody })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: "Homepage Deleted",
            message: `${homepage.homepageName} was deleted successfully!`,
          })
        );
      }
    });
    analytics.sendEvent(
      `delete homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.homepageName,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };

  const unpublishHomepageClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.homepageId;
    const postBody = {
      status: dashboardCardConstants.UNPUBLISHED,
    };
    dispatch(updateHomePage({ homepageId, postBody })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: `Homepage ${postBody.status}`,
            message: `${homepage.homepageName} was successfully ${postBody.status}!`,
          })
        );
      }
    });
    analytics.sendEvent(
      `unpublish homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.homepageName,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };

  const publishHomepageClicked = (homepage, sourceClicked = "") => {
    const homepageId = homepage.homepageId;
    const postBody = {
      status: dashboardCardConstants.PUBLISHED,
    };
    dispatch(updateHomePage({ homepageId, postBody })).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: `Homepage ${postBody.status}`,
            message: `${homepage.homepageName} was successfully ${postBody.status}!`,
          })
        );
      }
    });
    analytics.sendEvent(
      `publish homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.homepageName,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };

  const unassignHompeageClicked = (homepage, teamId, sourceClicked = "") => {
    const homepageId = homepage.homepageId;
    const isDefault = homepage.isDefault;
    const teamsCount = dashboardsToTeamCountMapping[homepageId]?.length || 0;
    const lastAssignedTeamDashboard =
      (teamsCount === 1 || isDefault === true) &&
      homepage.status === dashboardCardConstants.PUBLISHED
        ? {
            status: dashboardCardConstants.UNPUBLISHED,
          }
        : {};
    const postBody = {
      ...(isDefault === true && { isDefault: false }),
      addTeamIds: [],
      deleteTeamIds: isDefault ? [] : [teamId],
    };
    dispatch(
      updateTeamsDashboards({ homepageId, postBody, lastAssignedTeamDashboard })
    ).then(({ type }) => {
      if (type.includes(globalConstants.FULFILLED)) {
        dispatch(
          addToastMessage({
            type: globalConstants.SUCCESS,
            title: "Homepage Unassigned",
            message: `${homepage.homepageName} was unassigned from the team!`,
          })
        );
      }
    });
    analytics.sendEvent(
      `unassign homepage ${sourceClicked && " clicked from " + sourceClicked}`,
      {
        homepageId: homepage.homepageId,
        status: homepage.status,
        homepageName: homepage.homepageName,
        tab: selectedTab === 0 ? "Teams View" : "Homepage View",
      }
    );
  };

  const navigateCreateNewDashBoard=()=>{

    dispatch(apiAddWebHomepage({ postBody: defaultColorPaylod }))
      .then((response) => {
        const dashboardInfo = response?.payload;
        if (dashboardInfo) {
          localStorage.setItem('currentDashboardInfo', JSON.stringify(dashboardInfo));
          navigate(ROUTES_PATH.webHomepage);
        }
      })
      .catch((error) => {
        console.error('Error creating new dashboard:', error);
      });
   
  }

  const navigateCreateNewLaunchbar=()=>{

    dispatch(apiAddWebLaunchBar({ postBody: defaultColorPaylod }))
      .then((response) => {
        const dashboardInfo = response?.payload;
        if (dashboardInfo) {
          localStorage.setItem('currentLaunchBarInfo', JSON.stringify(dashboardInfo));
          navigate(ROUTES_PATH.launchBarHomePage);
        }
      })
      .catch((error) => {
        console.error('Error creating new dashboard:', error);
      });
   
  }
  return { cloneHomepageClicked, createHomepageClicked, deleteHomepageClicked, unpublishHomepageClicked, publishHomepageClicked, unassignHompeageClicked, navigateCreateNewDashBoard, navigateCreateNewLaunchbar};
}

export default useHomepageCard;
