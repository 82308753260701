import React from "react";
import { useTranslation } from "react-i18next";
import LsqSwitchV2 from "../../../../components/LsqSwitchV2/index.js";
import CustomMultiRangeSlider from "./CustomMultiRangeSlider.js";
import CustomInput from "../../../../components/CustomInput/index.js";
import Text from "../../../../components/Text/index.js";

const SetThresholds = ({ thresholdForm = {} }) => {
  const { t } = useTranslation();
  const {
    watch,
    control,
    formState: { errors = {} },
  } = thresholdForm;

  const setThresholds = watch("setThresholds");
  return (
    <div className="set-threshold gap-1 flex flex-column">
      <div className="flex align-items-center">
        <Text type="T4B" className="mr-auto" color="var(--text-primary)">
          {t("SetThresholds.Title")}
        </Text>
        <LsqSwitchV2 control={control} switchName="setThresholds" />
      </div>
      {setThresholds && (
        <>
          <div className="flex align-items-center">
            <Text type="T4" className="mr-auto" color="var(--N80)">
              {t("SetThresholds.Info")}
            </Text>
            {/* <InfoIcon /> */}
          </div>
          <div className="flex  min-max align-items-start">
            <CustomInput
              fieldName="min"
              isMandatory={true}
              control={control}
              errors={errors}
              label={t("SetThresholds.Min")}
              type="number"
              min={0}
            />
            <CustomInput
              fieldName="max"
              isMandatory={true}
              control={control}
              errors={errors}
              label={t("SetThresholds.Max")}
              type="number"
              min={0}
            />
          </div>
          <div className="App">
            <CustomMultiRangeSlider
              thresholdsName={"thresholds"}
              control={control}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SetThresholds;