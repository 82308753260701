import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Text from "../../../../components/Text";
import { CustomDropdown } from "../../../../components/CustomDropdown/CustomDropdown";
import { casaWebWidgetsConstants } from "../../../../constants/globalConstant";
import {
  listOptionTemplate,
  valueTemplate,
} from "../../../../utils/dropdownUtils";
import ControlledMultiselect from "../../../../components/LSQMultiSelect/ControlledMultiselect";
import {
  flattenTeamsHeirarchy,
  getTeamsById,
} from "../../../../components/TeamAssignmentMultiselectTreeDropdown/TeamAssignmentMultiselectTreeDropdownUtils";

export default function SmartViewFilter({ form, metaData }) {
  const [smartViewTab, setSmartViewTab] = useState([]);
  const { teams } = useSelector((state) => state?.homepage);
  const linearTeamList = flattenTeamsHeirarchy(teams);
  const teamsById = getTeamsById(linearTeamList);
  const smartViews = metaData?.smartviews?.smartViews;

  const getSmartViewByTeams = () => {
    const teamIds = form.watch("teams")?.map((team) => team.teamId) || [];
    if (teamIds.length === 0) return smartViews;
    const _smartViews = {};
    for (let team of teamIds) {
      _smartViews[teamsById[team]?.SmartViewId] = true;
    }
    return smartViews?.filter((smartView) => _smartViews[smartView.id]);
  };
  const onSmartViewChange = (option) => {
    form.resetField("smartViewTab");
    setSmartViewTab(option.value.smartViewTabs);
    form.setValue("smartView", option.value);
  };

  const onTeamsChanged = (e) => {
    form.resetField("smartView");
    form.resetField("smartViewTab");
    form.setValue("teams", e.target.value);
  };

  useEffect(() => {
    const smartView = form.getValues("smartView");
    if (smartView) {
      setSmartViewTab(smartView.smartViewTabs);
    }
  }, []);
  
  return (
    <>
      <Text type={"T4B"} color={"var(--N400)"}>
        {casaWebWidgetsConstants.SMART_VIEWS_FILTERS}
      </Text>
      <ControlledMultiselect
        label="Teams"
        isMandatory={false}
        errors={form.formState.errors}
        className="w-full text-base lsq-input"
        fieldName="teams"
        control={form.control}
        options={linearTeamList}
        placeholder="Select Teams"
        itemTemplate={(option, props) =>
          listOptionTemplate(option, props, "label")
        }
        panelClassName="lsq-multiselect-panel"
        id="teams-tab"
        optionLabel="label"
        onChange={onTeamsChanged}
        maxSelectedLabels={-1}
      />
      <CustomDropdown
        label={casaWebWidgetsConstants.SELECT_SMART_VIEW}
        isMandatory={true}
        errors={form.formState.errors}
        className="w-full text-base lsq-input"
        fieldName={"smartView"}
        control={form.control}
        options={getSmartViewByTeams()}
        placeholder="Select"
        optionLabel="name"
        itemTemplate={listOptionTemplate}
        valueTemplate={valueTemplate}
        onChange={onSmartViewChange}
      />
      <CustomDropdown
        label={casaWebWidgetsConstants.SELECT_SMART_VIEW_TAB}
        isMandatory={true}
        errors={form.formState.errors}
        className="w-full text-base lsq-input"
        fieldName={"smartViewTab"}
        control={form.control}
        options={smartViewTab}
        placeholder="Select"
        optionLabel="name"
        itemTemplate={listOptionTemplate}
        valueTemplate={valueTemplate}
      />
    </>
  );
}
