import cloneDeep from "lodash.clonedeep";
import { menuBuilderConstants } from "../constants/globalConstant";

export const isDefaultMenutab = (defaultMenuTabs, id) => {
  return defaultMenuTabs?.some((object) => object.id === id);
};

export const formatNavigationTabData = (
  tabArray,
  defaultTabs,
  customTabs,
  publishedTabs
) => {
  return tabArray?.map((tab) => {
    if (!isDefaultMenutab(defaultTabs, tab.tabId)) {
      if (customTabs[tab.tabId])
        return {
          ...customTabs[tab.tabId].config,
          id: tab.tabId,
          isCustom: true,
        };
      else return publishedTabs?.find((item) => item.id === tab.tabId);
    } else {
      return {
        icon: {
          name: tab.name,
          imgName: tab.imgName || "lsq_leads",
        },
        navigateTo: {
          id: tab.tabId,
          name: tab.name,
        },
        displayName: tab.name,
        externalLink: "",
        externalLinkOptions: "",
        internalLink: {},
        id: tab.tabId,
        isCustom: false,
      };
    }
  });
};

export const getCustomMenuTabs = (customTabs) => {
  return customTabs?.map((item) => {
    return {
      id: item.tabId,
      imgName: item.config.icon.imgName,
      name: item.config.displayName,
      tabId: item.tabId,
    };
  });
};

export const getVisibleTabs = (customTabs) => {
  return customTabs?.map((item) => {
    return {
      id: item.id,
      imgName: item.icon.imgName,
      name: item.displayName,
      tabId: item.id,
    };
  });
};

export const getTabsbyTabId = (tabs) => {
  return Object.fromEntries(
    tabs?.map((tab) => {
      return [tab.tabId, tab];
    })
  );
};

export const getUniqueTabsByTabId = (tabList, tabs) => {
  return tabList?.filter((item) => {
    return !tabs?.find((tab) => tab.tabId === item.tabId);
  });
};

export const menuMoreOptions = (menu, view) => {
  const temp = view
    ? [
        {
          id: menuBuilderConstants.DELETE,
          label: menuBuilderConstants.DELETE,
        },
      ]
    : [
        {
          id: menuBuilderConstants.CLONE,
          label: menuBuilderConstants.CLONE,
        },
        {
          id: menuBuilderConstants.DELETE,
          label: menuBuilderConstants.DELETE,
        },
      ];

  if (menu.status !== menuBuilderConstants.DRAFT)
    temp.push(
      menu.status === menuBuilderConstants.UNPUBLISHED ||
        menu.status === menuBuilderConstants.DRAFT
        ? {
            id: menuBuilderConstants.PUBLISH,
            label: menuBuilderConstants.PUBLISH,
          }
        : {
            id: menuBuilderConstants.UNPUBLISH,
            label: menuBuilderConstants.UNPUBLISH,
          }
    );
  return temp;
};

export const menuStatusOptions = [
  {
    id: menuBuilderConstants.PUBLISHED,
    label: menuBuilderConstants.PUBLISHED,
  },
  {
    id: menuBuilderConstants.UNPUBLISHED,
    label: menuBuilderConstants.UNPUBLISHED,
  },
];

export const menuSortItems = [
  {
    id: menuBuilderConstants.LAST_MODIFIED,
    label: menuBuilderConstants.LAST_MODIFIED,
  },
  {
    id: menuBuilderConstants.RECENTLY_CREATED,
    label: menuBuilderConstants.RECENTLY_CREATED,
  },
];

export const menuFilterItems = [
  {
    value: menuBuilderConstants.All,
    key: menuBuilderConstants.All,
    id: "menu-all",
    "data-testid": "all-menu",
  },
  {
    value: menuBuilderConstants.PUBLISHED,
    key: menuBuilderConstants.PUBLISHED,
    id: "menu-published",
    "data-testid": "published-menu",
  },
  {
    value: menuBuilderConstants.UNPUBLISHED,
    key: menuBuilderConstants.UNPUBLISHED,
    id: "menu-unpublished",
    "data-testid": "unpublished-menu",
  },
];

export const getAllMenuData = (menus, teamsToMenuCountMapping) => {
  let clonedMenus = cloneDeep(menus);
  clonedMenus.forEach((menu) => {
    if (teamsToMenuCountMapping[menu.menuId])
      menu.teamsCount = teamsToMenuCountMapping[menu.menuId].length;
  });
  return clonedMenus;
};

export const sortMenus = (menus = [], sortParameter = "") => {
  const cloneMenus = [...menus];
  let sortFunction;
  if (sortParameter === menuBuilderConstants.RECENTLY_CREATED)
    sortFunction = (team1, team2) => {
      return new Date(team2.createdAt) - new Date(team1.createdAt);
    };
  else
    sortFunction = (team1, team2) => {
      return new Date(team2.updatedAt) - new Date(team1.updatedAt);
    };
  return cloneMenus.sort(sortFunction);
};

export const searchMenus = (menus = [], searchParameter = "") => {
  if (searchParameter.trim() === "") return menus;
  let searchedMenus = menus.filter((menu) => {
    return menu.name
      ?.trim()
      .toLowerCase()
      .includes(searchParameter.trim().toLowerCase());
  });
  return searchedMenus;
};

export const filterMenus = (
  menus = [],
  filterParameter = "",
  menuById = {}
) => {
  let filterFunction;
  switch (filterParameter) {
    case menuBuilderConstants.PUBLISHED:
      filterFunction = (menu) =>
        menuById[menu.menuId]?.status === menuBuilderConstants.PUBLISHED;
      break;
    case menuBuilderConstants.UNPUBLISHED:
      filterFunction = (menu) =>
        menuById[menu.menuId]?.status === menuBuilderConstants.UNPUBLISHED;
      break;
    default:
      filterFunction = (menu) => menuById[menu.menuId]?.status;
  }
  let filteredMenu = menus?.filter(filterFunction);
  return filteredMenu;
};
