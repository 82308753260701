import Text from "../components/Text";
import { casaWebConstants } from "../constants/globalConstant";
import { NavigationMenu } from "../constants/globalEnums";
import styles from "./styles.module.css";
import { Tooltip } from "primereact/tooltip";

export const selectedIconTemplate = (option, props) => {
  if (option) {
    return (
      <img
        alt={option.name}
        className="flex w-2rem"
        src={`/images/launcherIcons/${option.imgName}.svg`}
      />
    );
  }
  return (
    <img
      src={`/images/launcherIcons/${props.placeholder}.svg`}
      style={{
        filter:
          "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7394%) hue-rotate(191deg) brightness(117%) contrast(64%)",
      }}
    />
  );
};

export const listOptionTemplateWithCount = (option, entityCountMap, casaWebLimits) => {
  const entityType = option?.id;
  const maxLimit = casaWebLimits?.entitiesLimit?.[entityType];
  const usedCount = entityCountMap?.[entityType];
  const renderCount = entityType && typeof(maxLimit) === casaWebConstants.NUMBER && typeof(usedCount) === casaWebConstants.NUMBER;

  return (
    <div className="flex">
      <Text color="var(--N400)" type="T4B" className="maxchar-1 ellipsis flex w-full">
        <div>{option["name"]}</div>
        {renderCount && <div className="ml-auto" data-testid="max-limit-count">[{usedCount}/{maxLimit}]</div>}
      </Text>
    </div>
  );
};

export const listOptionTemplate = (
  option,
  props = null,
  labelProperty = "name"
) => {
  if (option)
    return (
      <div className="flex">
        <Text color="var(--N400)" type="T4B" className="maxchar-1 ellipsis flex w-full">
          <div>{option[labelProperty]}</div>
        </Text>
      </div>
    );
  return <span>{props?.placeholder}</span>;
};
export const groupedValueTemplate = (option) => {
  if (option)
    return (
      <Text color="#283A5A" type="T4">
        {option.name === NavigationMenu.EXTERNAL ? "External" : option.name}
      </Text>
    );
  else return <Text type="T4">Select Option</Text>;
};
export const groupedItemTemplate = (option) => {
  if (option)
    return (
      <Text color="#283A5A" type="T4">
        {option.name}
      </Text>
    );
};
export const groupedHeaderTemplate = (option) => {
  if (option) return <Text type="T4B">{option.name}</Text>;
};

export const iconOptionTemplate = (option) => {
  return (
    <div className="flex align-items-center">
      <img
        style={{ width: "1rem" }}
        className="mr-3"
        alt={option.name}
        src={`/images/${option.imgName}.svg`}
      />
      <Text color="#283A5A" type="T4">
        {option.name}
      </Text>
    </div>
  );
};

export const valueTemplate = (option, props, labelProperty = "name") => {
  if (option)
    return (
      <Text type="T4B" color="#283A5A">
        {option[labelProperty]}
      </Text>
    );
  return (
    <Text type="T4" color="#283A5A">
      {props?.placeholder}
    </Text>
  );
};

export const treeValueTemplate = (length, placeholder) => {
  if (length === 0) {
    return <Text type="T4">{placeholder || "Select"}</Text>;
  }
  return <Text type="T4">{`${length} selected`}</Text>;
};

export const iconPickerTemplate = (option) => {
  return (
    <div
      className={`flex cursor-pointer justify-content-center ${styles["icon-picker-items"]} ${option.imgName}`}
      data-testid={option.name}
    >
      <Tooltip
        position="bottom"
        content={option.name}
        target={`.${option.imgName}`}
        className="preview-pane-tooltip"
      />
      <img
        alt={option.name}
        src={`/images/launcherIcons/${option.imgName}.svg`}
      />
    </div>
  );
};
